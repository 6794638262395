import React, { useState } from "react";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

import aries from "../assets/zodiacs/aries-01.png";
import aquarius from "../assets/zodiacs/aquarius-01.png";
import cancer from "../assets/zodiacs/cancer-01.png";
import capricorn from "../assets/zodiacs/capricorn-01.png";
import gemini from "../assets/zodiacs/gemini-01.png";
import leo from "../assets/zodiacs/LEO-01.png";
import libra from "../assets/zodiacs/libra-01.png";
import pisces from "../assets/zodiacs/pisces-01.png";
import sagittarius from "../assets/zodiacs/sagittarius-01.png";
import taurus from "../assets/zodiacs/taurus-01.png";
import virgo from "../assets/zodiacs/virgo-01.png";
import scorpio from "../assets/zodiacs/scorpio-01.png";
import Slider from 'react-slick';
const zodiacImages = {
  aries,
  taurus,
  gemini,
  cancer,
  leo,
  virgo,
  libra,
  scorpio,
  sagittarius,
  capricorn,
  aquarius,
  pisces,
};

const zodiacOptions = Object.keys(zodiacImages);

const settings = {
  dots: false,          // Show navigation dots
  infinite: true,      // Loop through images
  speed: 500,          // Transition speed
  slidesToShow: 1,     // Show one slide at a time
  slidesToScroll: 1,   // Scroll one slide at a time
  autoplay: true,      // Enable auto-play
  autoplaySpeed: 3000,
  // Delay between transitions
};

// Array of images to display in the slider
const images = [
  'https://via.placeholder.com/600x300', // Replace with your image URLs
  'https://via.placeholder.com/600x300',
  'https://via.placeholder.com/600x300',
];

const Compatibility = () => {
  const [boyZodiac, setBoyZodiac] = useState(zodiacOptions[0]);
  const [girlZodiac, setGirlZodiac] = useState(zodiacOptions[1]);
  const [compatibility, setCompatibility] = useState(null);
  const [loadingCompatibility, setLoadingCompatibility] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");


  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang); // Store in localStorage

    // Trigger API call when language changes
    callVedicAPI(selectedLang);
  };

  const callVedicAPI = (lang) => {
   
    const url = `https://astrologerdaddy.com/api/astrology-services/dosha/kaalsarp-dosh?dob=23/02/1985&tob=05:40&lat=11&lon=77&tz=5.5&&lang=${lang}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const fetchCompatibility = async () => {
    setLoadingCompatibility(true);
    const boyIndex = zodiacOptions.indexOf(boyZodiac) + 1; // Adjusting for API
    const girlIndex = zodiacOptions.indexOf(girlZodiac) + 1; // Adjusting for API

    try {
      // const response = await axios.get(
      //   `https://astrologerdaddy.com/api/astrology-services/matching/western-match?boy_sign=${boyIndex}&girl_sign=${girlIndex}&lang=en`
      // );

      const response = await axios.get(`https://astrologerdaddy.com/api/astrology-services/matching/western-match`, {
        params: {
          boy_sign: boyIndex,
          girl_sign: girlIndex,
          lang: language
        }
      });
      setCompatibility(response.data.response);
    } catch (err) {
      console.error("Error fetching compatibility:", err);
      setCompatibility(null); // Reset compatibility in case of an error
    } finally {
      setLoadingCompatibility(false);
    }
  };

  return (
    <div className="md:w-5/6 w-11/12 mx-auto py-8">
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="lg:flex md:flex max-w-7xl mx-auto px-4 my-6 items-stretch justify-between w-full ">
        <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]" >

          {/* <h1 className="md:text-4xl text-2xl font-extrabold mb-4 text-red-700 text-center">
            Compatibility Checker
          </h1> */}
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-4 py-4">
  {/* Heading */}
  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-red-700 text-center sm:text-left">
  Compatibility Checker
  </h2>

  {/* Language Selector */}
  <div className="flex flex-col sm:flex-row items-center gap-2">
    <label
      htmlFor="language-select"
      className="text-sm font-medium whitespace-nowrap"
    >
      Select Language:
    </label>
    <select
      id="language-select"
      className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
      value={language}
      onChange={handleLanguageChange}
    >
      <option value="en">English</option>
      <option value="ta">Tamil</option>
      <option value="ka">Kannada</option>
      <option value="te">Telugu</option>
      <option value="hi">Hindi</option>
      <option value="ml">Malayalam</option>
      <option value="mr">Marathi</option>
      
      <option value="sp">Spanish</option>
      <option value="fr">French</option>
    </select>
  </div>
</div>

          <div className="flex justify-between gap-4 flex-col md:flex-row">
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col grow">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Boy's Zodiac Sign
              </h2>
              <select
                value={boyZodiac}
                onChange={(e) => setBoyZodiac(e.target.value)}
                className="p-2 text-lg border border-gray-300 rounded"
              >
                {zodiacOptions.map((zodiac) => (
                  <option key={zodiac} value={zodiac}>
                    {zodiac.charAt(0).toUpperCase() + zodiac.slice(1)}
                  </option>
                ))}
              </select>
              <div className="flex justify-center mt-4">
                <img
                  src={zodiacImages[boyZodiac]}
                  alt={`${boyZodiac} zodiac sign`}
                  className="w-24 h-24 rounded-full"
                />
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col grow">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Girl's Zodiac Sign
              </h2>
              <select
                value={girlZodiac}
                onChange={(e) => setGirlZodiac(e.target.value)}
                className="p-2 text-lg border border-gray-300 rounded"
              >
                {zodiacOptions.map((zodiac) => (
                  <option key={zodiac} value={zodiac}>
                    {zodiac.charAt(0).toUpperCase() + zodiac.slice(1)}
                  </option>
                ))}
              </select>
              <div className="flex justify-center mt-4">
                <img
                  src={zodiacImages[girlZodiac]}
                  alt={`${girlZodiac} zodiac sign`}
                  className="w-24 h-24 rounded-full"
                />
              </div>
            </div>
          </div>

          <div className="text-center mt-8">
            {loadingCompatibility ? (
              <FaSpinner className="h-8 w-8 mx-auto animate-spin" />
            ) : (
              compatibility && (
                <div className="bg-yellow-100 p-4 rounded-lg mt-4 shadow-md">
                  <h3 className="font-semibold  text-lg">Compatibility Results</h3>
                  <p className="text-md">
                    Compatibility: <strong>{compatibility.score}%</strong>
                  </p>
                  <p>{compatibility.bot_response}</p>
                </div>
              )
            )}
            <button
              className="mt-4 bg-yellow-500 text-white px-4 py-2 rounded-lg shadow hover:bg-yellow-600 transition"
              onClick={fetchCompatibility} // Trigger fetch on button click
            >
              Check Compatibility
            </button>
          </div>
        </div>
        <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Compatibility;
