import React, { memo } from 'react';

const MangalDoshSection = memo(({ mangalDoshData }) => {
  if (!mangalDoshData) return null;
  
  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Mangal Dosh Analysis</h2>
      
      <div className="grid grid-cols-1 gap-8">
        <div className="bg-gray-50 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-center mb-6">
            <div className="text-center">
              <div className="text-4xl font-bold text-red-600">{mangalDoshData.score}%</div>
              <div className="text-gray-600 mt-2">Mangal Dosh Score</div>
            </div>
          </div>

          <div className="space-y-6">
            {Object.entries(mangalDoshData.factors).map(([key, value]) => (
              <div key={key} className="bg-white p-4 rounded-lg shadow">
                <h4 className="font-semibold text-gray-700 capitalize mb-2">{key} Factor</h4>
                <p className="text-gray-600">{value}</p>
              </div>
            ))}
          </div>

          <div className="mt-6 p-4 bg-yellow-50 rounded-lg border border-yellow-200">
            <p className="text-gray-700">{mangalDoshData.bot_response}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MangalDoshSection;