import React, { useEffect, useState } from "react";
import BannerSlider from "../components/BannerSlider";
import api from "../api";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';  // Add this import

function BannerSection() {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await api.get("assets");
        const bannersArray = response.data.filter((banner) => banner.type === "banner");
        setBanners(bannersArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching banners", error);
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="mx-auto">
      {loading ? (
        <div className="w-full">
          <Skeleton 
            height={200} 
            borderRadius={8}
            baseColor="#f3f4f6"
            highlightColor="#e5e7eb"
          />
        </div>
      ) : (
        <BannerSlider banners={banners} />
      )}
    </div>
  );
}

export default React.memo(BannerSection);
