import React, { memo, useState, useCallback } from 'react';

const PLANETS = [
  "Ascendant",
  "Sun",
  "Moon",
  "Mars",
  "Mercury",
  "Jupiter",
  "Saturn",
  "Venus"
];

const SELECT_PLANETS = [
  "Jupiter",
  "Saturn",
  "Mercury",
  "Ketu",
  "Venus",
  "Sun",
  "Moon",
  "Mars",
  "Rahu",
  "Ascendant",
];

const HOUSES = Array.from({ length: 12 }, (_, i) => i + 1);

const TableRow = memo(({ house, values }) => (
  <tr>
    <td className="py-2 px-4 border text-center text-gray-600">{house}</td>
    {values.map((value, index) => (
      <td key={index} className="py-2 px-4 border text-center text-gray-600">
        {value}
      </td>
    ))}
  </tr>
));

const BinnashtakvargaSection = memo(({ binnashtakvarga, onPlanetChange, planet, setPlanet }) => {
  const [selectedPlanet, setSelectedPlanet] = useState(planet);

  const handlePlanetChange = useCallback(async (event) => {
    const newPlanet = event.target.value;
    setSelectedPlanet(newPlanet);
    setPlanet(newPlanet);
    if (onPlanetChange) {
      await onPlanetChange(newPlanet);
    }
  }, [onPlanetChange, setPlanet]);

  // Generate default values (all zeros) for when data is missing
  const getTableValues = () => {
    if (!binnashtakvarga) {
      // Return a 12x8 matrix of zeros (12 houses x 8 planets)
      return HOUSES.map(() => 
        PLANETS.map(() => 0)
      );
    }

    return HOUSES.map(house => 
      PLANETS.map(planet => 
        binnashtakvarga[planet] ? binnashtakvarga[planet][house - 1] : 0
      )
    );
  };

  // Calculate total points safely
  const calculateTotalPoints = () => {
    if (!binnashtakvarga || !binnashtakvarga[selectedPlanet]) {
      return 0;
    }
    return binnashtakvarga[selectedPlanet].reduce((a, b) => a + b, 0);
  };

  const tableValues = getTableValues();

  return (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      {/* <div className="flex justify-between items-center mb-6">
        <h2 className="md:text-2xl text-sm font-bold text-gray-900">Binnashtakvarga</h2>
        <div className="flex items-center space-x-2">
          <label htmlFor="planet-select" className="text-gray-700 font-medium">
            Select Planet:
          </label>
          <select
            id="planet-select"
            value={selectedPlanet}
            onChange={handlePlanetChange}
            className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {SELECT_PLANETS.map((planet) => (
              <option key={planet} value={planet}>
                {planet}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
  <h2 className="text-xl md:text-2xl font-bold text-red-700">Binnashtakvarga</h2>
  <div className="flex items-center space-x-2">
    <label htmlFor="planet-select" className="text-gray-700 font-medium">
      Select Planet:
    </label>
    <select
      id="planet-select"
      value={selectedPlanet}
      onChange={handlePlanetChange}
      className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {SELECT_PLANETS.map((planet) => (
        <option key={planet} value={planet}>
          {planet}
        </option>
      ))}
    </select>
  </div>
</div>


      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border text-center text-gray-700">House</th>
              {PLANETS.map((planet) => (
                <th key={planet} className="py-2 px-4 border text-center text-gray-700">
                  {planet}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableValues.map((rowValues, index) => (
              <TableRow
                key={index + 1}
                house={index + 1}
                values={rowValues}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 text-sm text-gray-600">
        <p>Selected Planet: {selectedPlanet}</p>
        <p>Total Points: {calculateTotalPoints()}</p>
      </div>
    </div>
  );
});

export default BinnashtakvargaSection;