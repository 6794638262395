import React, { memo } from "react";
import PropTypes from "prop-types";

// List of planets to display
const PLANETS = [
  "Ascendant",
  "Sun",
  "Moon",
  "Mars",
  "Mercury",
  "Jupiter",
  "Venus",
  "Saturn",
  "Uranus",
  "Neptune",
  "Pluto",
  "Chiron",
  "Sirius",
  "North Node",
  "South Node",
];

// Memoized Western Planets Section
const WesternPlanets = memo(({ westernPlanets }) => {
  if (!westernPlanets) {
    console.warn("No planetary data available!");
    return null;
  }

  console.log("WesternPlanets");
  

  return (
    // <div className="bg-white p-6 rounded-lg shadow-md">
    //   <h2 className="text-2xl font-bold mb-6">Western Astrology Planets</h2>
    //   <table className="min-w-full">
    //     <thead>
    //       <tr>
    //         <th className="py-2 px-4 border-b">Planet</th>
    //         <th className="py-2 px-4 border-b">Global Degree</th>
    //         <th className="py-2 px-4 border-b">Retrograde</th>
    //         <th className="py-2 px-4 border-b">Zodiac</th>
    //         <th className="py-2 px-4 border-b">Local Degree</th>
    //         <th className="py-2 px-4 border-b">House</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {PLANETS.map((planet) => {
    //         const dataKey = planet.toLowerCase().replace(" ", "_");
    //         const data = westernPlanets[dataKey];

    //         if (!data) {
    //           return (
    //             <tr key={planet} className="hover:bg-gray-50">
    //               <td className="py-2 px-4 border-b font-semibold">{planet}</td>
    //               <td className="py-2 px-4 border-b" colSpan="5">
    //                 No data available
    //               </td>
    //             </tr>
    //           );
    //         }

    //         const { global_degree, retro, zodiac, local_degree, house } = data;

    //         return (
    //           <tr key={planet} className="hover:bg-gray-50">
    //             <td className="py-2 px-4 border-b font-semibold">{planet}</td>
    //             <td className="py-2 px-4 border-b text-center">{global_degree}</td>
    //             <td className="py-2 px-4 border-b text-center">{retro === true ? "Yes" : retro === false ? "No" : retro}</td>
    //             <td className="py-2 px-4 border-b text-center">{zodiac}</td>
    //             <td className="py-2 px-4 border-b text-center">{local_degree}</td>
    //             <td className="py-2 px-4 border-b text-center">{house}</td>
    //           </tr>
    //         );
    //       })}
    //     </tbody>
    //   </table>
    // </div>
    <div className="bg-white p-6 rounded-lg shadow-md overflow-x-auto">
  <h2 className="text-2xl font-bold text-red-700 mb-6">Western Astrology Planets</h2>
  <table className="min-w-full divide-y divide-gray-200">
    <thead>
      <tr>
        <th className="py-2 px-4 text-left text-lg font-bold text-gray-700 border-b">Planet</th>
        <th className="py-2 px-4 text-center text-lg font-bold text-gray-700 border-b">Global Degree</th>
        <th className="py-2 px-4 text-center text-lg font-bold text-gray-700 border-b">Retrograde</th>
        <th className="py-2 px-4 text-center text-lg font-bold text-gray-700 border-b">Zodiac</th>
        <th className="py-2 px-4 text-center text-lg font-bold text-gray-700 border-b">Local Degree</th>
        <th className="py-2 px-4 text-center text-lg font-bold text-gray-700 border-b">House</th>
      </tr>
    </thead>
    <tbody>
      {PLANETS.map((planet) => {
        const dataKey = planet.toLowerCase().replace(" ", "_");
        const data = westernPlanets[dataKey];

        if (!data) {
          return (
            <tr key={planet} className="hover:bg-gray-50">
              <td className="py-2 px-4 border-b font-semibold">{planet}</td>
              <td className="py-2 px-4 border-b" colSpan="5">
                No data available
              </td>
            </tr>
          );
        }

        const { global_degree, retro, zodiac, local_degree, house } = data;

        return (
          <tr key={planet} className="hover:bg-gray-50">
            <td className="py-2 px-4 border-b font-semibold">{planet}</td>
            <td className="py-2 px-4 border-b text-center">{global_degree}</td>
            <td className="py-2 px-4 border-b text-center">{retro === true ? "Yes" : retro === false ? "No" : retro}</td>
            <td className="py-2 px-4 border-b text-center">{zodiac}</td>
            <td className="py-2 px-4 border-b text-center">{local_degree}</td>
            <td className="py-2 px-4 border-b text-center">{house}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>

  );
});

WesternPlanets.propTypes = {
  westernPlanets: PropTypes.shape({
    ascendant: PropTypes.object,
    sun: PropTypes.object,
    moon: PropTypes.object,
    mars: PropTypes.object,
    mercury: PropTypes.object,
    jupiter: PropTypes.object,
    venus: PropTypes.object,
    saturn: PropTypes.object,
    uranus: PropTypes.object,
    neptune: PropTypes.object,
    pluto: PropTypes.object,
    chiron: PropTypes.object,
    sirius: PropTypes.object,
    north_node: PropTypes.object,
    south_node: PropTypes.object,
  }).isRequired,
};

export default WesternPlanets;
