import React from "react";
import { memo } from "react";
import PropTypes from "prop-types";

// Memoized Ashtakvarga Section
const AshtakvargaSection = memo(({ ashtakvargaData }) => {

  if (!ashtakvargaData) {
    console.warn("No Ashtakvarga data available!");
    return null;
  }

  const {
    ashtakvarga_order = [],
    ashtakvarga_points = [],
    ashtakvarga_total = [],
  } = ashtakvargaData;

  
  if (ashtakvarga_order.length !== ashtakvarga_points.length) {
    console.error("Data length mismatch: ", {
      ashtakvarga_order,
      ashtakvarga_points,
      ashtakvarga_total,
    });
    return <div>Data error: length mismatch!</div>;
  }

  return (
  
    <div className="bg-white p-6 rounded-lg shadow-md">
  <h2 className="text-2xl font-bold text-red-700 mb-6">Ashtakvarga</h2>
  <div className="overflow-x-auto"> {/* Make the table horizontally scrollable on smaller screens */}
    <table className="min-w-full">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b">Planet</th>
          {[...Array(12)].map((_, i) => (
            <th key={i} className="py-2 px-4 border-b">
              House {i + 1}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {ashtakvarga_order.map((planet, planetIndex) => {
          const points = ashtakvarga_points[planetIndex];

          return (
            <tr key={planet} className="hover:bg-gray-50">
              <td className="py-2 px-4 border-b font-semibold">{planet}</td>
              {points.map((point, i) => (
                <td key={i} className="py-2 px-4 border-b text-center">
                  {point}
                </td>
              ))}
            </tr>
          );
        })}
        <tr>
          <td className="py-2 px-4 border-b font-semibold">Total</td>
          {ashtakvarga_total.map((total, i) => (
            <td key={i} className="py-2 px-4 border-b text-center font-semibold">
              {total}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
</div>

  );
});

AshtakvargaSection.propTypes = {
  ashtakvargaData: PropTypes.shape({
    ashtakvarga_order: PropTypes.arrayOf(PropTypes.string),
    ashtakvarga_points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    ashtakvarga_total: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default AshtakvargaSection;
