import React, { useEffect, useState } from "react";
import {
  FaSun,
  FaCalendarDay,
  FaStar,
  FaCalendar,
  FaMoon,
  FaCloudSun,
  FaArrowCircleLeft,
} from "react-icons/fa";
import PanchangDetails from "../components/PanchangDetails/PanchangDetails";
import ChoghadiyaMuhurta from "../components/PanchangDetails/ChoghadiyaMuhurta";
import HoraMuhurta from "../components/PanchangDetails/HoraMuhurta";
import MoonCalendar from "../components/PanchangDetails/MoonCalendar";
import SunMoonDetails from "../components/PanchangDetails/SunMoonDetails";
import Retrogrades from "../components/PanchangDetails/Retrogrades";
import MonthlyPanchang from "../components/PanchangDetails/MonthlyPanchang";
import Slider from 'react-slick';

function Panchang() {

  const BASE_URL = "http://localhost:5000/api/astrology-services/panchang";
  const planets = [
    'Sun',
    'Moon',
    'Mercury',
    'Venus',
    'Mars',
    'Saturn',
    'Jupiter',
    'Rahu',
    'Ketu'
  ];
  const [selectedPlanet, setSelectedPlanet] = useState(planets[0]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLocationReady, setIsLocationReady] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");


  const handleLanguageChange = (event) => {
  const selectedLang = event.target.value;
  
  setLanguage(selectedLang);
  localStorage.setItem("selectedLanguage", selectedLang);

  // Immediately fetch with new language
  if (formData.date && formData.time && formData.latitude && formData.longitude) {
    if (activeSection === "sunMoonDetails") {
      fetchSunMoonData(selectedLang);
    } else {
      fetchSectionData(activeSection, selectedLang);
    }
  }
};

  
  const getTimezoneOffset = () => {
    const date = new Date();
    const offsetMinutes = -date.getTimezoneOffset();
    return offsetMinutes / 60;
  };

  const [formData, setFormData] = useState({
    date: "",
    time: "",
    latitude: "",
    longitude: "",
  });

  const [apiParams, setApiParams] = useState({
    date: "",
    tz: getTimezoneOffset(),
    lat: "",
    lon: "",
    time: "",
    lang: "en",
  });

  const [panchangData, setPanchangData] = useState({
    panchangDetails: null,
    choghadiyaMuhurta: null,
  });
  const [activeSection, setActiveSection] = useState("panchangDetails");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const buildApiUrl = (endpoint, selectedLang) => {
  const formattedDate = apiParams.date.replace(/%2F/g, '/');
  
  const defaultParams = {
    lang: selectedLang, // Use passed language parameter
    ...(endpoint === "retrogrades" 
      ? { year: selectedYear, planet: selectedPlanet }
      : {
          date: formattedDate,
          tz: getTimezoneOffset(),
          lat: apiParams.lat,
          lon: apiParams.lon,
          time: apiParams.time
        })
  };

  const queryParams = new URLSearchParams(defaultParams);
  return `${BASE_URL}/${endpoint}?${queryParams}`;
};

  const makeApiCall = async (endpoint, selectedLang = language) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10000);

  try {
    // Use the passed language parameter directly
    const url = buildApiUrl(endpoint, selectedLang);
    const response = await fetch(url, {
      signal: controller.signal,
      headers: { 'Accept': 'application/json' }
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `Status ${response.status}`);
    }

    return await response.json();

  } catch (error) {
    if (error.name === 'AbortError') throw new Error('Request timeout');
    if (error.name === 'TypeError') throw new Error('Network error');
    throw error;
  } finally {
    clearTimeout(timeoutId);
  }
};

  const fetchSunMoonData = async (selectedLang = language) => {
  const endpoints = [
    "moon-phase",
    "moonrise",
    "moonset",
    "solarnoon",
    "sunrise",
    "sunset",
  ];

  try {
    const promises = endpoints.map((endpoint) => 
      makeApiCall(endpoint, selectedLang)
    );
    const results = await Promise.all(promises);

    const combinedData = {
      moonPhase: results[0].response,
      moonrise: results[1].response,
      moonset: results[2].response,
      solarNoon: results[3].response,
      sunrise: results[4].response,
      sunset: results[5].response,
    };

    setPanchangData((prev) => ({
      ...prev,
      sunMoonDetails: combinedData,
    }));
  } catch (err) {
    setError(`Failed to fetch sun-moon details: ${err.message}`);
  }
};

  const fetchSectionData = async (section, selectedLang = language) => {
  if (!isLocationReady) return;

  setLoading(true);
  setError("");

  const endpoints = {
    panchangDetails: "panchang",
    monthlyPanchang: "monthly-panchang",
    choghadiyaMuhurta: "choghadiya-muhurta",
    horaMuhurta: "hora-muhurta",
    moonCalendar: "moon-calendar",
    retrogrades: "retrogrades",
  };

  try {
    if (section === "sunMoonDetails") {
      await fetchSunMoonData(selectedLang);
    } else {
      const data = await makeApiCall(endpoints[section], selectedLang);
      if (data.status === 200) {
        setPanchangData((prev) => ({
          ...prev,
          [section]: data.response,
        }));
      } else {
        throw new Error(`API returned status: ${data.status}`);
      }
    }
  } catch (err) {
    setError(`Failed to fetch ${section} data: ${err.message}`);
  } finally {
    setLoading(false);
  }
};

  const getCurrentDateAndTime = () => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
      today.getMonth() + 1
    ).padStart(2, "0")}/${today.getFullYear()}`;
    const formattedTime = today.toTimeString().split(" ")[0].slice(0, 5);

    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
      time: formattedTime,
    }));

    setApiParams((prev) => ({
      ...prev,
      date: formattedDate,
      time: formattedTime,
    }));
  };

  // Initial setup effect
  useEffect(() => {
    getCurrentDateAndTime();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const lat = latitude.toFixed(6);
          const lon = longitude.toFixed(6);

          setFormData((prev) => ({
            ...prev,
            latitude: lat,
            longitude: lon,
          }));

          setApiParams((prev) => ({
            ...prev,
            lat,
            lon,
          }));

          setIsLocationReady(true);
        },
        (error) => {
          console.error("Error getting location:", error);
          setError(
            "Unable to retrieve your location. Please enable location services."
          );
        }
      );
    } else {
      setError("Geolocation is not supported by your browser.");
    }
  }, []);

  // Effect to fetch initial data once location is ready
  useEffect(() => {
    if (isLocationReady) {
      fetchSectionData(activeSection);
    }
  }, [isLocationReady]);

  // Effect to fetch data when tab changes
  useEffect(() => {
    if (isLocationReady && activeSection !== "retrogrades") {
      fetchSectionData(activeSection);
    }
  }, [activeSection]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiParams((prev) => ({
      ...prev,
      date: formData.date,
      time: formData.time,
      lat: formData.latitude,
      lon: formData.longitude,
    }));
    fetchSectionData(activeSection);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    if (section === "retrogrades") {
      setSelectedYear(new Date().getFullYear());
      setSelectedPlanet("");
    }
  };

  useEffect(() => {
    if (activeSection === "retrogrades" && selectedPlanet) {
      fetchSectionData("retrogrades");
    }
  }, [selectedPlanet, activeSection]);

  const handlePlanetChange = (e) => {
    setSelectedPlanet(e.target.value);
  };


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    if (activeSection === "retrogrades" && selectedPlanet) {
      fetchSectionData("retrogrades");
    }
  };

  const NavigationButton = ({ active, onClick, children }) => (
    <button
      className={`px-4 py-2 mx-2 text-nowrap font-semibold rounded-md focus:outline-none ${active ? "bg-yellow-500 text-white" : "bg-gray-200 hover:bg-yellow-100"
        }`}
      onClick={onClick}
    >
      {children}
    </button>
  );

  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
    // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];


  return (
    <div className="container mx-auto p-4">
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      {/* <h1 className="text-4xl font-bold text-center mb-6 text-red-700">
        Today's Panchang
      </h1> */}
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-4 py-4">
        {/* Heading */}
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-red-700 text-center sm:text-left">
          Today's Panchang
        </h2>

        {/* Language Selector */}
        <div className="flex flex-col sm:flex-row items-center gap-2">
          <label
            htmlFor="language-select"
            className="text-sm font-medium whitespace-nowrap"
          >
            Select Language:
          </label>
          <select
            id="language-select"
            className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="ta">Tamil</option>
            <option value="ka">Kannada</option>
            <option value="te">Telugu</option>
            <option value="hi">Hindi</option>
            <option value="ml">Malayalam</option>
            <option value="mr">Marathi</option>
            
            <option value="sp">Spanish</option>
            <option value="fr">French</option>
          </select>
        </div>
      </div>


      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto bg-white shadow-md rounded-lg p-6"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">
          Get Panchang for Specific Date
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2" htmlFor="date">
            Date (DD/MM/YYYY):
          </label>
          <input
            type="text"
            id="date"
            value={formData.date}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2" htmlFor="time">
            Time (HH:MM):
          </label>
          <input
            type="text"
            id="time"
            value={formData.time}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2" htmlFor="latitude">
            Latitude:
          </label>
          <input
            type="text"
            id="latitude"
            value={formData.latitude}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
            disabled
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2" htmlFor="longitude">
            Longitude:
          </label>
          <input
            type="text"
            id="longitude"
            value={formData.longitude}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
            disabled
          />
        </div>
        <button
          type="submit"
          className="w-full bg-yellow-600 text-white font-semibold py-2 rounded-md hover:bg-yellow-500 transition duration-200"
        >
          Get Panchang
        </button>
      </form>

      <h1 className="text-3xl font-bold text-center my-6">Panchang Details</h1>

      <div className="flex flex-wrap justify-center py-1 mb-6 gap-2">
        <NavigationButton
          active={activeSection === "panchangDetails"}
          onClick={() => handleSectionChange("panchangDetails")}
        >
          <span className="flex items-center gap-2">
            <FaCalendarDay /> Panchang Details
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "monthlyPanchang"}
          onClick={() => handleSectionChange("monthlyPanchang")}
        >
          <span className="flex items-center gap-2">
            <FaCalendar /> Monthly Panchang
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "choghadiyaMuhurta"}
          onClick={() => handleSectionChange("choghadiyaMuhurta")}
        >
          <span className="flex items-center gap-2">
            <FaSun /> Choghadiya Muhurta
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "horaMuhurta"}
          onClick={() => handleSectionChange("horaMuhurta")}
        >
          <span className="flex items-center gap-2">
            <FaStar /> Hora Muhurta
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "moonCalendar"}
          onClick={() => handleSectionChange("moonCalendar")}
        >
          <span className="flex items-center gap-2">
            <FaMoon /> Moon Calendar
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "sunMoonDetails"}
          onClick={() => handleSectionChange("sunMoonDetails")}
        >
          <span className="flex items-center gap-2">
            <FaCloudSun /> Sun Moon Details
          </span>
        </NavigationButton>

        <NavigationButton
          active={activeSection === "retrogrades"}
          onClick={() => handleSectionChange("retrogrades")}
        >
          <span className="flex items-center gap-2">
            <FaArrowCircleLeft /> Retrogrades
          </span>
        </NavigationButton>
      </div>

      {error && <div className="mt-4 text-red-500 text-center">{error}</div>}

      <div className="bg-white p-4 rounded-md shadow-md w-full">
        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : (
          <>
            <div className="lg:flex md:flex max-w-7xl mx-auto px-4 my-6 items-stretch justify-between w-full ">
              <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
                {activeSection === "panchangDetails" && (
                  <PanchangDetails data={panchangData.panchangDetails} />
                )}
                {activeSection === "monthlyPanchang" && (
                  <MonthlyPanchang data={panchangData.monthlyPanchang} />
                )}
                {activeSection === "choghadiyaMuhurta" && (
                  <ChoghadiyaMuhurta data={panchangData.choghadiyaMuhurta} />
                )}
                {activeSection === "horaMuhurta" && (
                  <HoraMuhurta data={panchangData.horaMuhurta} />
                )}
                {activeSection === "moonCalendar" && (
                  <MoonCalendar data={panchangData.moonCalendar} />
                )}
                {activeSection === "sunMoonDetails" && (
                  <SunMoonDetails data={panchangData.sunMoonDetails} />
                )}
                {activeSection === "retrogrades" && (
                  <Retrogrades
                    data={panchangData.retrogrades}
                    selectedPlanet={selectedPlanet}
                    onPlanetChange={handlePlanetChange}
                    selectedYear={selectedYear}
                    onYearChange={handleYearChange}
                    planets={planets}
                  />
                )}
              </div>
              <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
                <div className="max-w-full">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="max-w-full">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="max-w-full">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="grow ">

                  <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
                </div>

              </div>

            </div>
          </>

        )}
      </div>
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Panchang;