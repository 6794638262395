import {memo} from 'react'
const PLANETS = [
  "Sun",
  "Moon",
  "Mercury",
  "Venus",
  "Mars",
  "Saturn",
  "Jupiter",
  "Rahu",
  "Ketu",
];

// Memoized Table Row Component
const TableRow = memo(({ label, value }) => (
  <tr className="hover:bg-gray-50">
    <td className="py-2 px-4 border-b font-semibold">{label}</td>
    <td className="py-2 px-4 border-b">{value}</td>
  </tr>
));
// Memoized Planet Report Section
const PlanetReportSection = memo(
  ({ report, selectedPlanet, onPlanetChange }) => {
    if (!report) return null;

    return (
      <div className="mt-4 p-6 bg-white rounded-lg shadow-md">
        <div className="mb-4 flex items-center">
          <label htmlFor="planetSelect" className="mr-2 font-semibold">
            Select Planet:
          </label>
          <select
            id="planetSelect"
            value={selectedPlanet}
            onChange={(e) => onPlanetChange(e.target.value)}
            className="p-2 border border-gray-300 rounded hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {PLANETS.map((planet) => (
              <option key={planet} value={planet}>
                {planet}
              </option>
            ))}
          </select>
        </div>

        <h2 className="text-2xl font-bold text-red-700 mb-4">
          Planet Report for {selectedPlanet}
        </h2>

        <div className="space-y-6">
          <table className="min-w-full bg-white border border-gray-200 mb-6">
            <tbody>
              <TableRow
                label="Planet Location"
                value={report.planet_location}
              />
              <TableRow
                label="Native Location"
                value={report.planet_native_location}
              />
              <TableRow label="Zodiac" value={report.planet_zodiac} />
              <TableRow label="Zodiac Lord" value={report.zodiac_lord} />
              <TableRow
                label="Planet Strength"
                value={report.planet_strength}
              />
              <TableRow label="Affliction" value={report.affliction} />
            </tbody>
          </table>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Predictions</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">General Prediction</h4>
              <p>{report.general_prediction}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Personalized Prediction</h4>
              <p>{report.personalised_prediction}</p>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Character Keywords</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-green-50 p-4 rounded-lg">
                <h4 className="font-semibold text-green-700 mb-2">Positive</h4>
                <p>{report.character_keywords_positive.join(", ")}</p>
              </div>
              <div className="bg-red-50 p-4 rounded-lg">
                <h4 className="font-semibold text-red-700 mb-2">Negative</h4>
                <p>{report.character_keywords_negative.join(", ")}</p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Additional Information</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Planet Definitions</h4>
              <p>{report.planet_definitions}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Zodiac Lord Details</h4>
              <table className="min-w-full">
                <tbody>
                  <TableRow label="Zodiac Lord" value={report.zodiac_lord} />
                  <TableRow
                    label="Zodiac Lord Location"
                    value={report.zodiac_lord_location}
                  />
                  <TableRow
                    label="Zodiac Lord House Location"
                    value={report.zodiac_lord_house_location}
                  />
                  <TableRow
                    label="Zodiac Lord Strength"
                    value={report.zodiac_lord_strength}
                  />
                </tbody>
              </table>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Zodiac Location Prediction</h4>
              <p>{report.planet_zodiac_prediction}</p>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Gayatri Mantra</h4>
            <p className="font-sanskrit">{report.gayatri_mantra}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default PlanetReportSection