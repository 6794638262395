import React, { memo } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const PitraDoshSection = memo(({ pitraDoshData }) => {
  if (!pitraDoshData) return null;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Pitra Dosh Analysis</h2>
      
      <div className="grid grid-cols-1 gap-8">
        <div className="bg-gray-50 rounded-lg p-6 shadow-md">
          <div className="flex items-center mb-6">
            <FaExclamationTriangle className={`text-2xl ${pitraDoshData.is_dosha_present ? 'text-red-500' : 'text-green-500'} mr-2`} />
            <h3 className="text-xl font-semibold">Status: {pitraDoshData.is_dosha_present ? 'Present' : 'Not Present'}</h3>
          </div>

          <div className="space-y-6">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-semibold text-gray-700 mb-2">Analysis</h4>
              <p className="text-gray-600">{pitraDoshData.bot_response}</p>
            </div>

            {
              pitraDoshData.is_dosha_present &&
              <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-semibold text-gray-700 mb-2">Effects</h4>
              <ul className="list-disc pl-5 space-y-2">
                {pitraDoshData?.effects?.map((effect, index) => (
                  <li key={index} className="text-gray-600">{effect}</li>
                ))}
              </ul>
            </div>}

            {
              pitraDoshData.is_dosha_present &&
              <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-semibold text-gray-700 mb-2">Remedies</h4>
              <ul className="list-disc pl-5 space-y-2">
                {pitraDoshData?.remedies?.map((remedy, index) => (
                  <li key={index} className="text-gray-600">{remedy}</li>
                ))}
              </ul>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PitraDoshSection;