

import React, { memo } from 'react';
import { FaUser, FaCalendarAlt, FaListUl } from 'react-icons/fa';

const CharDashaCurrentSection = memo(({ charDashaCurrentData }) => {
  if (!charDashaCurrentData) return null;

  const {
    main_dasha,
    main_dasha_lord,
    sub_dasha_list,
    sub_dasha_start_date,
    sub_dasha_end_dates,
  } = charDashaCurrentData;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-6xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Char Dasha Current Details</h2>

      <div className="grid grid-cols-1 gap-8 mb-8">
        <div className="bg-gray-50 h-fit flex md:flex-row flex-col justify-around rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
          <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-700 flex items-center">
            <FaUser className="mr-2"/> Main Dasha
          </h3>
          <p className="text-lg mb-2 text-gray-600">Dasha: <span className="font-semibold text-gray-800">{main_dasha}</span></p>
          <p className="text-lg mb-2 text-gray-600">Lord: <span className="font-semibold text-gray-800">{main_dasha_lord}</span></p>
          <p className="text-lg mb-2 text-gray-600 flex flex-col md:flex-row md:items-center text-nowrap gap-0 md:gap-2">
            <span className='flex items-center gap-2'><FaCalendarAlt className="" /> Sub Dasha Start Date: </span>
            <span className="font-semibold text-gray-800">
              {new Date(sub_dasha_start_date).toLocaleDateString()}
            </span>
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
          
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-3 px-4 text-left text-gray-600">Sub Dasha</th>
                  <th className="py-3 px-4 text-left text-gray-600">End Date</th>
                </tr>
              </thead>
              <tbody>
                {sub_dasha_list.map((subDasha, index) => (
                  <tr key={subDasha} className="hover:bg-gray-100 transition-colors duration-150">
                    <td className="py-3 px-4 border-b text-gray-800">{subDasha}</td>
                    <td className="py-3 px-4 border-b text-gray-800">
                      {sub_dasha_end_dates[index] 
                        ? new Date(sub_dasha_end_dates[index]).toLocaleDateString() 
                        : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CharDashaCurrentSection;
