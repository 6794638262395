import {
  ParticipantsAudio,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { MyParticipant } from "./MyParticipant";

export const MyParticipantsPanel = () => {
  const { useParticipants } = useCallStateHooks();
  const participants = useParticipants();
  console.log(participants);
  
  return (
    <div className="flex flex-col items-center space-y-2 mb-4">
      <ParticipantsAudio participants={participants} />
      {participants.map((p) => (
        <MyParticipant participant={p} key={p.sessionId} />
      ))}
    </div>
    
  );
};
