import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const ChoghadiyaMuhurta = ({ data }) => {
  const getTypeColor = (type) => {
    switch (type.toLowerCase()) {
      case 'auspicious':
        return 'bg-green-50 text-green-700';
      case 'inauspicious':
        return 'bg-red-50 text-red-700';
      case 'good':
        return 'bg-blue-50 text-blue-700';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  };

  const renderMuhurtaTable = (timings, title, icon) => (
    <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6 mb-8">
      <div className="flex items-center gap-2 mb-6">
        {icon}
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className="bg-slate-100">
              <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Time</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Muhurat</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Type</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-slate-200">
            {timings.map((timing, index) => (
              <tr key={index} className="hover:bg-slate-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  {timing.start} - {timing.end}
                </td>
                <td className="px-6 py-4">{timing.muhurat}</td>
                <td className="px-6 py-4">
                  <span className={`px-2.5 py-1 rounded-full text-sm ${getTypeColor(timing.type)}`}>
                    {timing.type}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-slate-900 mb-2">Choghadiya Muhurta</h2>
        <p className="text-slate-600">Auspicious and Inauspicious Timings</p>
      </div>

      {data && (
        <>
          {renderMuhurtaTable(data.day, 'Day Muhurta', 
            <FaSun className="w-6 h-6 text-yellow-600" />
          )}
          {renderMuhurtaTable(data.night, 'Night Muhurta', 
            <FaMoon className="w-6 h-6 text-slate-600" />
          )}
        </>
      )}
    </div>
  );
};

export default ChoghadiyaMuhurta;