import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaQuora, FaPinterest } from 'react-icons/fa';
import compact from "../assets/compactLogo.png"
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { IoLogoAppleAppstore } from "react-icons/io5";
const Footer = () => {
  const navigate = useNavigate();
  return (
//     <footer className="bg-red-900 text-white py-10">
//       <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-5 gap-6 px-4">
//        <div>
//       <img
//               className=" "
//               src={logo}
//               alt="Logo"
//               onClick={() => navigate("/")}
//             />
//       </div>
      
//      <div>
//       <h3 className="text-lg font-semibold mb-2">Astrology Tools</h3>
//   <ul className="space-y-2"> {/* Adds vertical spacing */}
//     <li>
//       <a href="/astrologers" className="block  hover:underline">
//         Consult Astrologers
//       </a>
//     </li>
//     <li>
//       <a href="/horoscope-page" className="block  hover:underline">
//         Horoscope
//       </a>
//     </li>
//     <li>
//       <a href="/panchang" className="block  hover:underline">
//         Panchang
//       </a>
//     </li>
//     <li>
//       <a href="/kundli-making" className="block  hover:underline">
//         Kundli Making
//       </a>
//     </li>
//     <li>
//       <a href="/kundli-matching" className="block  hover:underline">
//        Kundli Matching
//       </a>
//     </li>
//     <li>
//       <a href="/numerology" className="block  hover:underline">
//         Numerology
//       </a>
//     </li>
//     <li>
//       <a href="/compatibility" className="block  hover:underline">
//         Compatibility
//       </a>
//     </li>
//     <li>
//       <a href="/gemstone" className="block  hover:underline">
//         Gemstone
//       </a>
//     </li>
   
//   </ul>
// </div>


       
//         <div>
//           <h3 className="text-lg font-semibold mb-2">Horoscope</h3>
//           <ul className="space-y-1">
//             <li>Daily Horoscope</li>
//             <li>Monthly Horoscope</li>
//             <li>Yearly Horoscope</li>
//           </ul>
        
//         </div>
     
//         <div>
//           <h3 className="text-lg font-semibold mb-2">Policy</h3>
//           <ul className="space-y-1">
//             <li>
//               <Link to="/privacy-policy">Privacy Policy</Link>
//             </li>
//             <li>
//               <Link to="/pricing-policy">Pricing Policy</Link>
//             </li>
//             <li>
//               <Link to="/terms-and-conditions">Terms Of Use</Link>
//             </li>
//             <li>
//               <Link to="/refund-policy">Refund & Cancellation</Link>
//             </li>
//             <li>
//               <Link to="/disclaimer">Disclaimer</Link>
//             </li>
//           </ul>
//         </div>
//         <div>
//         <h3 className="text-lg font-semibold my-2">Astrologer section</h3>
//           <ul className="space-y-1">
//             <li className="hover:text-gray-200 cursor-pointer">
//               <a href="http://astrologer.astrologerdaddy.com/login">
//                 Astrologer Login
//               </a>
//             </li>
//             <li className="hover:text-gray-200 cursor-pointer">
//               <a href="http://astrologer.astrologerdaddy.com/register">
//                 Registration Astrologer
//               </a>
//             </li>
//           </ul>
//           <div className="flex space-x-1 flex-wrap mt-5">
//       <a href="https://facebook.com/astrologerdaddy" className="text-gray-400 hover:text-white">
//         <FaFacebook size={24} />
//       </a>
//       <a href="https://x.com/astrologerdaddy" className="text-gray-400 hover:text-white">
//         <FaTwitter size={24} />
//       </a>
//       <a href="https://www.linkedin.com/company/astrologer-daddy/" className="text-gray-400 hover:text-white">
//         <FaLinkedin size={24} />
//       </a>
//       <a href="https://www.instagram.com/astrologerdaddy/" className="text-gray-400 hover:text-white">
//         <FaInstagram size={24} />
//       </a>
//       <a href="https://www.quora.com/profile/Astrologer-Daddy" className="text-gray-400 hover:text-white">
//         <FaQuora size={24} />
//       </a>
//       <a href="https://in.pinterest.com/astrologerdaddy/" className="text-gray-400 hover:text-white">
//         <FaPinterest size={24} />
//       </a>
//     </div>
          
//         </div>
//       </div>
//       <div className="container mx-auto mt-10 px-4 ">
//       <div className="flex space-x-4 pb-3 sm:mb-0">
//             <p className="text-white">Download Our Apps</p>
//             <a href="#" className="text-gray-400 hover:text-white">
//               Google Play
//             </a>
//             <a href="#" className="text-gray-400 hover:text-white">
//               App Store
//             </a>
//           </div>
//           <div className="flex justify-center items-center border-t border-gray-700 pt-4">
//   <p className="text-center text-sm ">Website designed by: Hatch Concepts</p>
// </div>

//       </div>
//     </footer>
<footer className="bg-gradient-to-br bg-gray-700  text-white py-16">
  <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-5 gap-8 px-6">
    {/* Logo Section */}

<div className="hidden sm:block col-span-2 sm:col-span-1">
  <img
    className="cursor-pointer transition-opacity duration-300 lg:h-42 h-42 w-full"
    src={compact}
    alt="Logo"
    onClick={() => navigate("/")}
  />
</div>


    {/* Astrology Tools Section */}
    <div>
      <h3 className="text-lg font-semibold mb-4 border-b border-white pb-2">
        Astrology Tools
      </h3>
      <ul className="space-y-2">
        {[
          { name: "Talk to Astrologers", link: "/astrologers" },
          { name: "Today Horoscope", link: "/horoscope-page" },
          { name: "Panchang", link: "/panchang" },
          { name: "Kundli Making", link: "/kundli-making" },
          { name: "Kundli Matching", link: "/kundli-matching" },
          { name: "Numerology", link: "/numerology" },
          { name: "Compatibility", link: "/compatibility" },
          { name: "Gemstone", link: "/gemstone" },
        ].map((tool) => (
          <li key={tool.name} className="w-fit">
            <a
              href={tool.link}
              className="block hover:text-yellow-300 transition-colors duration-300"
            >
              {tool.name}
            </a>
          </li>
        ))}
      </ul>
    </div>

    {/* Horoscope Section */}
    <div>
      <h3 className="text-lg font-semibold mb-4 border-b border-white pb-2">
        Horoscope
      </h3>
      <ul className="space-y-2">
        {["Daily Horoscope", "Monthly Horoscope", "Yearly Horoscope"].map(
          (item, index) => (
            <li key={index} className="hover:text-yellow-300 transition-colors cursor-pointer duration-300 w-fit">
              <a href="/horoscope-page">{item}</a>
            </li>
          )
        )}
      </ul>
    </div>

    {/* Policy Section */}
    <div>
      <h3 className="text-lg font-semibold mb-4 border-b border-white pb-2">
        Policy
      </h3>
      <ul className="space-y-2">
        {[
          { name: "Privacy Policy", link: "/privacy-policy" },
          { name: "Pricing Policy", link: "/pricing-policy" },
          { name: "Terms Of Use", link: "/terms-and-conditions" },
          { name: "Refund & Cancellation", link: "/refund-policy" },
          { name: "Disclaimer", link: "/disclaimer" },
        ].map((policy) => (
          <li key={policy.name}>
            <Link
              to={policy.link}
              className="hover:text-yellow-300 transition-colors duration-300 w-fit"
            >
              {policy.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>

    {/* Astrologer Section */}
    <div>
      <h3 className="text-lg font-semibold mb-4 border-b border-white pb-2">
        Astrologer Section
      </h3>
      <ul className="space-y-2">
        {[
          {
            name: "Astrologer Login",
            link: "http://astrologer.astrologerdaddy.com/login",
          },
          {
            name: "Registration Astrologer",
            link: "http://astrologer.astrologerdaddy.com/register",
          },
        ].map((item) => (
          <li key={item.name} className="hover:text-yellow-300 transition-colors duration-300 w-fit">
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
      </ul>
      {/* Social Media Links */}
      <h3 className="text-lg font-semibold mt-6 mb-2 border-b border-white pb-2">
        Follow us on
      </h3>
      <div className="flex lg:space-x-4 space-x-1 mt-6">
        {[
          { icon: <FaFacebook size={24} />, link: "https://facebook.com/astrologerdaddy" },
          { icon: <FaTwitter size={24} />, link: "https://x.com/astrologerdaddy" },
          { icon: <FaLinkedin size={24} />, link: "https://linkedin.com/company/astrologer-daddy/" },
          { icon: <FaInstagram size={24} />, link: "https://instagram.com/astrologerdaddy/" },
          { icon: <FaQuora size={24} />, link: "https://quora.com/profile/Astrologer-Daddy" },
          { icon: <FaPinterest size={24} />, link: "https://pinterest.com/astrologerdaddy/" },
        ].map((social, index) => (
          <a
            key={index}
            href={social.link}
            className=" hover:text-gray-400 transition-colors duration-300 w-fit"
          >
            {social.icon}
          </a>
        ))}
      </div>
      <div>
      <p className="text-lg font-semibold mt-6  border-b border-white pb-2 mb-2">Download Our Apps:</p>
    
    {/* Google Play */}
    <div className="flex flex-col items-start gap-3 mb-10">
  {/* Google Play */}
  <div className="flex items-center gap-2 ">
    <IoLogoGooglePlaystore className="text-white text-2xl" />
    <a
      href="#"
      className="hover:text-white text-gray-300 transition-colors duration-300 text-base"
    >
      Google Play
    </a>
  </div>

  {/* App Store */}
  <div className="flex items-center gap-2">
    <IoLogoAppleAppstore className="text-white text-2xl" />
    <a
      href="#"
      className="hover:text-white text-gray-300 transition-colors duration-300 text-base"
    >
      App Store
    </a>
  </div>
</div>

      </div>
    </div>
  </div>


  {/* <div className="container mx-auto mt-12 pb-10 px-6 text-center border-t border-white-700 pt-8">
  <div className="flex flex-wrap justify-center items-center space-x-4 mb-4">
    <p className="text-white">Download Our Apps:</p>
    
   
    <div className="flex items-center space-x-2">
      <IoLogoGooglePlaystore className="text-white text-xl" />
      <a
        href="#"
        className="hover:text-white transition-colors duration-300"
      >
        Google Play
      </a>
    </div>
    
   
    <div className="flex items-center space-x-2">
      <IoLogoAppleAppstore className="text-white text-xl" />
      <a
        href="#"
        className="hover:text-white transition-colors duration-300"
      >
        App Store
      </a>
    </div>
  </div>


</div> */}

</footer>

  );
};

export default Footer;
