import React, { memo, useState, useCallback } from 'react';

// const DIVISIONS = [
//   "D1", "D2", "D3", "D3-s", "D4", "D5", "D7", "D8", "D9", "D10", 
//   "D10-R", "D12", "D16", "D20", "D24", "D24-R", "D27", "D40", 
//   "D45", "D60", "D30", "chalit", "sun", "moon", "kp_chalit", 
//   "transit", "varshapal"
// ];

const DIVISIONS = [

  { key: "D1", label: "Lagna" },
  { key: "D2", label: "Hora" },
  { key: "D3", label: "Dreshkana" },
  { key: "D3-s", label: "D3-Somanatha" },
  { key: "D4", label: "Chaturthamsha" },
  { key: "D5", label: "Panchamsha" },
  { key: "D7", label: "Saptamsa" },
  { key: "D8", label: "Ashtamsa" },
  { key: "D9", label: "Navamsa" },
  { key: "D10", label: "Dasamsa" },
  { key: "D10-R", label: "Dasamsa-EvenReverse" },
  { key: "D12", label: "Dwadasamsa" },
  { key: "D16", label: "Shodashamsa" },
  { key: "D20", label: "Vimsamsa" },
  { key: "D24", label: "ChaturVimshamsha" },
  { key: "D24-R", label: "D24-R" },
  { key: "D27", label: "Bhamsha" },
  { key: "D40", label: "KhaVedamsa" },
  { key: "D45", label: "AkshaVedamsa" },
  { key: "D60", label: "Shastiamsha" },
  { key: "D30", label: "Trimshamsha" },
  { key: "chalit", label: "Bhav-chalit" },
  { key: "sun", label: "Sun Chart" },
  { key: "moon", label: "Moon Chart" },
  { key: "kp_chalit", label: "KP Chalit" },
  { key: "transit", label: "Transit Chart" },
  { key: "varshapal", label: "varshapal" },
];

const CHART_STYLES = ["north", "south"];

const PLANETS = [
  { key: "0", label: "As" },
  { key: "1", label: "Su" },
  { key: "2", label: "Mo" },
  { key: "3", label: "Ma" },
  { key: "4", label: "Me" },
  { key: "5", label: "Ju" },
  { key: "6", label: "Ve" },
  { key: "7", label: "Sa" },
  { key: "8", label: "Ra" },
  { key: "9", label: "Ke" }
];

const TableRow = memo(({ planet, data }) => (
  <tr>
    <td className="py-2 px-4 border text-center text-gray-600">{planet.label}</td>
    <td className="py-2 px-4 border text-center text-gray-600">{data?.full_name || '-'}</td>
    <td className="py-2 px-4 border text-center text-gray-600">{data?.zodiac || '-'}</td>
    <td className="py-2 px-4 border text-center text-gray-600">{data?.house || '-'}</td>
    <td className="py-2 px-4 border text-center text-gray-600">{data?.local_degree?.toFixed(2) || '-'}</td>
    <td className="py-2 px-4 border text-center text-gray-600">
      {data?.retro ? 'Yes' : 'No'}
    </td>
  </tr>
));

const ChartVisualization = memo(({
  svgData,
  onDivisionChange,
  onStyleChange,
  onYearChange,
  onTransitDateChange,
  division = "D1",
  style = "north",
  year = new Date().getFullYear(),
  transitDate = new Date().toLocaleDateString('en-GB'),
  setDivision
}) => {
  const [selectedDivision, setSelectedDivision] = useState(division);
  const [selectedStyle, setSelectedStyle] = useState(style);
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedTransitDate, setSelectedTransitDate] = useState(transitDate);

  const handleDivisionChange = useCallback(async (event) => {
    const newDivision = event.target.value;
    setSelectedDivision(newDivision);
    setDivision(newDivision);
    if (onDivisionChange) {
      await onDivisionChange(newDivision);
    }
  }, [onDivisionChange, setDivision]);

  const handleStyleChange = useCallback(async (event) => {
    const newStyle = event.target.value;
    setSelectedStyle(newStyle);
    if (onStyleChange) {
      await onStyleChange(newStyle);
    }
  }, [onStyleChange]);

  const handleYearChange = useCallback(async (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    if (onYearChange) {
      await onYearChange(newYear);
    }
  }, [onYearChange]);

  const handleTransitDateChange = useCallback(async (event) => {
    const newDate = event.target.value;
    setSelectedTransitDate(newDate);
    if (onTransitDateChange) {
      await onTransitDateChange(newDate);
    }
  }, [onTransitDateChange]);

  const showYearInput = selectedDivision === "varshapal";
  const showTransitDate = selectedDivision === "transit";

  return (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      <div className="flex flex-wrap gap-4 items-center mb-6">
        <div className="flex items-center space-x-2 gap-2">
          <label htmlFor="chart-division-select" className="text-gray-700 font-medium">
            Select Division:
          </label>
          <select
            id="chart-division-select"
            value={selectedDivision}
            onChange={handleDivisionChange}
            className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
           {DIVISIONS.map((div) => (
                <option key={div.key} value={div.key}>
                  {div.label}
                </option>
              ))}
          </select>
        </div>

        <div className="flex items-center space-x-2 gap-2">
          <label htmlFor="chart-style-select" className="text-gray-700 font-medium">
            Chart Style:
          </label>
          <select
            id="chart-style-select"
            value={selectedStyle}
            onChange={handleStyleChange}
            className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {CHART_STYLES.map((style) => (
              <option key={style} value={style}>
                {style.charAt(0).toUpperCase() + style.slice(1)}
              </option>
            ))}
          </select>
        </div>

        {showYearInput && (
          <div className="flex items-center space-x-2 gap-2">
            <label htmlFor="year-input" className="text-gray-700 font-medium">
              Year:
            </label>
            <input
              id="year-input"
              type="number"
              value={selectedYear}
              onChange={handleYearChange}
              min="1900"
              max="2100"
              className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 w-24"
            />
          </div>
        )}

        {showTransitDate && (
          <div className="flex items-center space-x-2 gap-2">
            <label htmlFor="transit-date-input" className="text-gray-700 font-medium">
              Transit Date:
            </label>
            <input
              id="transit-date-input"
              type="date"
              value={selectedTransitDate.split('/').reverse().join('-')}
              onChange={(e) => {
                const date = new Date(e.target.value);
                const formattedDate = date.toLocaleDateString('en-GB');
                handleTransitDateChange({ target: { value: formattedDate } });
              }}
              className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}
      </div>





      <div className="flex justify-center items-center min-h-[300px] sm:min-h-[400px] w-full px-4">
        {svgData ? (
          <div
            className="w-full max-w-[90%] sm:max-w-md md:max-w-lg lg:max-w-xl" // Adjusted max-width for better responsiveness on smaller screens
            dangerouslySetInnerHTML={{ __html: svgData }}
          />
        ) : (
          <div className="text-gray-500 text-center text-sm sm:text-base md:text-lg lg:text-xl">
            No chart visualization available
          </div>
        )}
      </div>

      <div className="mt-4 text-sm text-gray-600">
        <p>Selected Division: {selectedDivision}</p>
        <p>Chart Style: {selectedStyle}</p>
        {showYearInput && <p>Year: {selectedYear}</p>}
        {showTransitDate && <p>Transit Date: {selectedTransitDate}</p>}
      </div>
    </div>
  );
});

const DivisionalTable = memo(({
  divisionalData,
  onDivisionChange,
  onStyleChange,
  onYearChange,
  onTransitDateChange,
  division = "D1",
  style = "north",
  year = new Date().getFullYear(),
  transitDate = new Date().toLocaleDateString('en-GB'),
  setDivision,
  svgData
}) => {
  const [selectedDivision, setSelectedDivision] = useState(division);

  const handleDivisionChange = useCallback(async (event) => {
    const newDivision = event.target.value;
    setSelectedDivision(newDivision);
    setDivision(newDivision);
    if (onDivisionChange) {
      await onDivisionChange(newDivision);
    }
  }, [onDivisionChange, setDivision]);


  return (
    <>
      <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <div className="flex gap-4 items-center mb-6">
          <div className="flex items-center space-x-2 gap-2">
            <label htmlFor="division-select" className="text-gray-700 font-medium">
              Select Division:
            </label>
            <select
              id="chart-division-select"
              value={selectedDivision}
              onChange={handleDivisionChange}
              className="py-2 px-4 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select Division</option>
              {DIVISIONS.map((div) => (
                <option key={div.key} value={div.key}>
                  {div.label}
                </option>
              ))}
            </select>
            {/* <h2 className="text-2xl font-bold text-gray-900">
              {divisionalData?.chart_name || ''}
            </h2> */}
            <h2 className="text-2xl font-bold text-red-700">
              {typeof divisionalData?.chart_name === 'string' ? divisionalData.chart_name : ''}
            </h2>
          </div>
        </div>


        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border text-center text-gray-700">Planet</th>
                <th className="py-2 px-4 border text-center text-gray-700">Full Name</th>
                <th className="py-2 px-4 border text-center text-gray-700">Sign</th>
                <th className="py-2 px-4 border text-center text-gray-700">House</th>
                <th className="py-2 px-4 border text-center text-gray-700">Degree</th>
                <th className="py-2 px-4 border text-center text-gray-700">Retro</th>
              </tr>
            </thead>
            <tbody>
              {PLANETS.map((planet) => (
                <TableRow
                  key={planet.key}
                  planet={planet}
                  data={divisionalData?.[planet.key]}
                />
              ))}
            </tbody>
          </table>
        </div>


        <div className="mt-4 text-sm text-gray-600">
          <p>Selected Division: {selectedDivision}</p>
          <p>Chart Type: {divisionalData?.chart_name || '-'}</p>
        </div>
      </div>
      <ChartVisualization
        onDivisionChange={onDivisionChange}
        onStyleChange={onStyleChange}
        onYearChange={onYearChange}
        onTransitDateChange={onTransitDateChange}
        division={division}
        style={style}
        year={year}
        transitDate={transitDate}
        setDivision={setDivision}
        svgData={svgData}
      />
    </>
  );
});

export default DivisionalTable;