// import React from "react";
// import SectionHeading from "../components/SectionHeading";
// // import SectionSubHeading from "../components/SectionSubHeading";
// import Slider from "react-slick";
// import styles from "./Sliders.module.css";
// import freehoroscope from "../assets/slidercard/check_your_free_horoscope.png"
// import compatible from "../assets/slidercard/Compatibilty.png"
// import festival from "../assets//slidercard/Festival.png"
// import kundliMaking from "../assets/slidercard/kundli_making.png"
// import kundliMatching from "../assets/slidercard/kundli_matching.png"
// import luckyGenerator from "../assets/slidercard/Lucky_gemstone.png"
// import panchang from "../assets/slidercard/Panchang.png"

// import { useNavigate } from "react-router-dom";

// const testimonials = [
//   {
//     avatar: freehoroscope,
  
//     name: "Check your Free Horoscope",
//   },
//   {
//     avatar: kundliMaking,
   
//     name: "Kundli Making",
//   },
//   {
//     avatar: kundliMatching,
 
//     name: "Kundli Matching",
//   },
//   {
//     avatar: festival,
 
//     name: "Festival",
//   },
//   {
//     avatar: compatible,

//     name: "Compatibility",
//   },
//   {
//     avatar: panchang,

//     name: "Panchang",
//   },
//   {
//     avatar: luckyGenerator,

//     name: "Lucky Gemstone",
//   },
  
// //   {
// //     avatar: "https://via.placeholder.com/150?text=Avatar+8",

// //     name: "Aryan Nair",
// //   },
// //   {
// //     avatar: "https://via.placeholder.com/150?text=Avatar+9",

// //     name: "Ishaan Reddy",
// //   },
// //   {
// //     avatar: "https://via.placeholder.com/150?text=Avatar+10",
 
// //     name: "Kabir Singh",
// //   },
// ];



// const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
//   const { onClick } = props;
//   return (
//     <div {...props} className={styles.customPrevArrow} onClick={onClick}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//       >
//         <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
//       </svg>
//     </div>
//   );
// };

// const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
//   const { onClick } = props;
//   return (
//     <div {...props} className={styles.customNextArrow} onClick={onClick}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//       >
//         <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
//       </svg>
//     </div>
//   );
// };

// const settings = {
//   className: styles.slickSlider,
//   centerMode: false,
//   infinite: true,
//   centerPadding: "60px",
//   slidesToShow: 3,
//   speed: 500,
//   nextArrow: <GalleryNextArrow />,
//   prevArrow: <GalleryPrevArrow />,
//   responsive: [
//     {
//       breakpoint: 1280,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2,
//       },
//     },
//     {
//       breakpoint: 940,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

// // const TestimonialCard = ({ avatar, text, name }) => {
// //   return (
// //     <div className="bg-white shadow-xl shadow-slate-300 rounded-lg p-6 text-center mx-2  min-h-[150px]">
// //       <img className="w-16 h-16 rounded-full mx-auto" src={avatar} alt={name} />
// //       <p className="mt-2 text-gray-800 font-semibold">{name}</p>
// //       <p className="mt-4 text-gray-600">{text}</p>
// //     </div>
// //   );
// // };
// const TestimonialCard = ({ avatar, text, name }) => {
//     return (
//       <div className="bg-white shadow-lg rounded-lg p-6 text-center mx-2 min-h-[150px] max-w-xs transition-transform transform hover:scale-105 hover:shadow-3xl">
//         <div className="flex flex-col items-center">
//           <img className="w-24 h-24 rounded-full border-4 border-yellow-500" src={avatar} alt={name} />
//           <p className="mt-4 text-xl font-bold text-gray-900">{name}</p>
//           {/* <p className="mt-2 text-sm text-gray-500 italic">"{text}"</p> */}
//         </div>
//       </div>
//     );
//   };
  

// const SliderComponent = () => {
//     const navigate = useNavigate();
// const handleCardClick = (testimonial) => {
  
//     navigate('/horoscopepage');
//   }

//   return (
//     <div className="container mx-auto  px-4 py-8">
//       <Slider {...settings}>
//         {testimonials.map((testimonial, index) => (
//           <div key={index} className="flex items-stretch" 
//           onClick={() => handleCardClick(testimonial)}>
//             <TestimonialCard
//               avatar={testimonial.avatar}
              
//               name={testimonial.name}
             
//             />
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// function SliderCard() {
// ;
//   return (
//     <div className="my-4 bg-gray-100 pt-4">
//       <SectionHeading title={"Astrology Service"} />
//       {/* <SectionSubHeading
//         title={
//           "See why our users love us and how anytime Astrology Daddy helped them find their path to happiness!"
//         }
//       /> */}
//       <SliderComponent />
//     </div>
//   );
// }

// export default SliderCard;
import React from "react";
import SectionHeading from "../components/SectionHeading";
import Slider from "react-slick";
import styles from "./Sliders.module.css";
import freehoroscope from "../assets/slidercard/check_your_free_horoscope.png";
import compatible from "../assets/slidercard/Compatibilty.png";
import festival from "../assets/slidercard/Festival.png";
import kundliMaking from "../assets/slidercard/kundli_making.png";
import kundliMatching from "../assets/slidercard/kundli_matching.png";
import luckyGenerator from "../assets/slidercard/Lucky_gemstone.png";
import panchang from "../assets/slidercard/Panchang.png";

import { useNavigate } from "react-router-dom";

const testimonials = [
  {
    avatar: freehoroscope,
    name: "Check your Free Horoscope",
  },
  {
    avatar: kundliMaking,
    name: "Kundli Making",
  },
  {
    avatar: kundliMatching,
    name: "Kundli Matching",
  },
  {
    avatar: festival,
    name: "Numerology",
  },
  {
    avatar: compatible,
    name: "Compatibility",
  },
  {
    avatar: panchang,
    name: "Panchang",
  },
  {
    avatar: luckyGenerator,
    name: "Gems & Rudraksha",
  },
];

const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;
  return (
    <div {...props} className={styles.customPrevArrow} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </div>
  );
};

const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;
  return (
    <div {...props} className={styles.customNextArrow} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
      </svg>
    </div>
  );
};

const settings = {
  className: styles.slickSlider,
  centerMode: false,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 3,
  speed: 500,
  nextArrow: <GalleryNextArrow />,
  prevArrow: <GalleryPrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialCard = ({ avatar, name }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 text-center mx-2 min-h-[150px] max-w-xs transition-transform transform hover:scale-105 hover:shadow-3xl">
      <div className="flex flex-col items-center">
        <img className="w-24 h-24 rounded-full border-4 border-yellow-500" src={avatar} alt={name} />
        <p className="mt-4 text-xl font-bold text-gray-900">{name}</p>
      </div>
    </div>
  );
};

const SliderComponent = () => {
  const navigate = useNavigate();

  const handleCardClick = (name) => {
    switch (name) {
      case "Check your Free Horoscope":
        navigate("/horoscope-page");
        break;
      case "Gems & Rudraksha":
        navigate('/gemstone');
        break;
      case "Kundli Making":
        navigate('/kundli-making');
        break;
      case "Kundli Matching":
        navigate("/kundli-matching");
        break;
      case "Numerology":
        navigate('/numerology');
        break;
      case "Compatibility":
        navigate('/compatibility');
        break;
      case "Panchang":
        navigate('/panchang');
        break;
      default:
        navigate('/'); 
        break;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="flex items-stretch cursor-pointer" onClick={() => handleCardClick(testimonial.name)}>
            <TestimonialCard avatar={testimonial.avatar} name={testimonial.name} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

function SliderCard() {
  return (
    <div className="my-4 bg-gray-100 pt-4">
      <SectionHeading title={"Astrology Service"} />
      <SliderComponent />
    </div>
  );
}

export default SliderCard;
