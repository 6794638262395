import React, { memo } from 'react';
import { FiCalendar, FiClock, FiSun, FiMoon } from 'react-icons/fi';

const formatFieldLabel = (key) => {
  return key
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getColorClass = (key) => ({
  tithi: "bg-orange-50 border-orange-100",
  nakshatra: "bg-yellow-50 border-yellow-100",
  yoga: "bg-emerald-50 border-emerald-100",
  karana: "bg-purple-50 border-purple-100",
  sun_position: "bg-amber-50 border-amber-100",
  moon_position: "bg-blue-50 border-blue-100",
  advanced_details: "bg-indigo-50 border-indigo-100",
  rahukaal: "bg-red-50 border-red-100",
  gulika: "bg-red-50 border-red-100",
  yamakanta: "bg-red-50 border-red-100"
}[key] || "bg-gray-50 border-gray-100");

const TimeDisplay = memo(({ time, icon: Icon }) => (
  <div className="flex items-center gap-2 text-sm text-gray-600">
    <Icon className="w-4 h-4 shrink-0" />
    <span className="truncate">{time}</span>
  </div>
));

TimeDisplay.displayName = 'TimeDisplay';

const DynamicField = memo(({ label, value, colorClass }) => {
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    return (
      <div className={`p-4 rounded-lg border ${colorClass} hover:shadow-sm transition-all`}>
        <h3 className="text-sm font-medium text-gray-600 mb-2">{label}</h3>
        <div className="space-y-1.5">
          {Object.entries(value).map(([key, val]) => {
            if (typeof val === 'string' && (key === 'start' || key === 'end')) {
              return (
                <TimeDisplay 
                  key={key} 
                  time={`${key}: ${val}`} 
                  icon={key === 'start' ? FiSun : FiMoon} 
                />
              );
            }
            if (key !== 'number' && val) {
              return (
                <div key={key} className="text-sm">
                  {key === 'name' ? (
                    <span className="font-medium">{val}</span>
                  ) : (
                    <span>{`${formatFieldLabel(key)}: ${val}`}</span>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={`p-4 rounded-lg border ${colorClass} hover:shadow-sm transition-all`}>
      <h3 className="text-sm font-medium text-gray-600 mb-2">{label}</h3>
      <div className="font-medium text-gray-800">{value}</div>
    </div>
  );
});

DynamicField.displayName = 'DynamicField';

const InauspiciousTimings = memo(({ timings }) => (
  <div className="mt-4">
    <h3 className="text-base font-medium text-gray-700 mb-3">Inauspicious Timings</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
      {Object.entries(timings).map(([key, value]) => value && (
        <div key={key} className="bg-red-50 border border-red-100 p-4 rounded-lg">
          <h4 className="font-medium text-red-700 mb-2">{formatFieldLabel(key)}</h4>
          <TimeDisplay time={value} icon={FiClock} />
        </div>
      ))}
    </div>
  </div>
));

InauspiciousTimings.displayName = 'InauspiciousTimings';

const DayFields = memo(({ dayData }) => {
  const excludeKeys = ['date', 'day'];
  const inauspiciousTimings = ['rahukaal', 'gulika', 'yamakanta'];
  
  const mainFields = Object.entries(dayData).filter(([key]) => 
    !excludeKeys.includes(key) && !inauspiciousTimings.includes(key)
  );

  const inauspiciousData = Object.fromEntries(
    Object.entries(dayData).filter(([key]) => inauspiciousTimings.includes(key))
  );

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
        {mainFields.map(([key, value]) => (
          <DynamicField 
            key={key}
            label={formatFieldLabel(key)}
            value={value}
            colorClass={getColorClass(key)}
          />
        ))}
      </div>
      <InauspiciousTimings timings={inauspiciousData} />
    </>
  );
});

DayFields.displayName = 'DayFields';

const MonthlyPanchang = memo(({ data }) => {
  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <div className="mb-8 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Monthly Panchang</h1>
        <p className="text-gray-600">Daily Astrological Almanac</p>
      </div>

      <div className="space-y-6">
        {data.map((day, index) => (
          <div key={index} className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 sm:p-6">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-2">
                <FiCalendar className="w-5 h-5 text-gray-600" />
                <h2 className="text-lg font-semibold text-gray-900">{day.date}</h2>
              </div>
              <span className="text-gray-600">{day.day.name}</span>
            </div>

            <DayFields dayData={day} />
          </div>
        ))}
      </div>
    </div>
  );
});

MonthlyPanchang.displayName = 'MonthlyPanchang';

export default MonthlyPanchang;