import React, { memo } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const SunMoonDetails = memo(({ data }) => {
  const renderDetailCard = (title, details, icon) => (
    <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6 mb-6">
      <div className="flex items-center gap-2 mb-4">
        {icon}
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(details).map(([key, value]) => (
          <div key={key} className="p-4 bg-slate-50 rounded-lg">
            <div className="font-medium text-slate-700 mb-1">
              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </div>
            <div className="text-slate-600">
              {value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-slate-900 mb-2">Sun and Moon Details</h2>
        <p className="text-slate-600">Daily Sun and Moon Timings</p>
      </div>

      {data && (
        <>
          {renderDetailCard('Moon Details', 
            { 
              date: data.moonPhase.date,
              luminance: data.moonPhase.luminance,
              phase: data.moonPhase.phase,
              paksha: data.moonPhase.paksha,
              state: data.moonPhase.state
            }, 
            <FaMoon className="w-6 h-6 text-slate-600" />
          )}

          {renderDetailCard('Sun Details', 
            {
              sun_rise: data.sunrise.sun_rise,
              sun_noon: data.solarNoon.sun_noon,
              sun_set: data.sunset.sun_set
            }, 
            <FaSun className="w-6 h-6 text-yellow-600" />
          )}

          {renderDetailCard('Moon Timings', 
            {
              moon_rise: data.moonrise.moon_rise,
              moon_set: data.moonset.moon_set
            }, 
            <FaMoon className="w-6 h-6 text-slate-600" />
          )}
        </>
      )}
    </div>
  );
});

export default SunMoonDetails;