import React, { memo } from "react";
const PapaSamayaSection = memo(({ papaSamayaData }) => {
  if (!papaSamayaData) return null;

  const totalPapa = Object.values(papaSamayaData).reduce(
    (acc, curr) => acc + curr,
    0
  );

  console.log(papaSamayaData);
  

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">
        Papa Samaya Analysis
      </h2>

      <div className="grid grid-cols-1 gap-8">
        <div className="bg-gray-50 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-center mb-6">
            <div className="text-center">
              <div className="text-4xl font-bold text-red-600">
                {totalPapa.toFixed(2)}
              </div>
              <div className="text-gray-600 mt-2">Total Papa Score</div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(papaSamayaData).map(([planet, score]) => (
              <div
                key={planet}
                className="bg-white p-4 rounded-lg shadow text-center"
              >
                <h4 className="font-semibold text-gray-700 capitalize">
                  {planet.replace("_papa", "")}
                </h4>
                <div className="text-2xl font-bold mt-2 text-red-600">
                  {score.toFixed(2)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PapaSamayaSection;
