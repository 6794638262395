

import React, { memo } from 'react';

const CurrentMahadashaFullSection = memo(({ currentMahadashaFullData }) => {
  if (!currentMahadashaFullData) return null;

  const dashas = currentMahadashaFullData.order_names.map((type) => ({
    type,
    data: currentMahadashaFullData[type],
  }));

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-7xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Current Mahadasha</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {dashas.map(({ type, data }) => (
          <div key={type} className="bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-center text-gray-700">
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-3 px-4 text-left text-gray-600">Name</th>
                    <th className="py-3 px-4 text-left text-gray-600">Start Date</th>
                    <th className="py-3 px-4 text-left text-gray-600">End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({ name, start, end }, dIndex) => (
                    <tr key={`${type}-${dIndex}`} className="hover:bg-gray-100 transition-colors duration-150">
                      <td className="py-3 px-4 border-b text-gray-800">{name}</td>
                      <td className="py-3 px-4 border-b text-gray-800">{new Date(start).toLocaleDateString()}</td>
                      <td className="py-3 px-4 border-b text-gray-800">{new Date(end).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default CurrentMahadashaFullSection;
