

// import React from 'react';
// import { FaSearch } from 'react-icons/fa';
// import { useNavigate } from "react-router-dom";

// function Shop() {
//     const cardData = {
//         url: "https://d1gcna0o0ldu5v.cloudfront.net/images/ae81fb49-c6f5-4268-b941-55c61409a013.jpg",
//         name: "Hanuman Chalisa and Aarti",
//         description: "Rose Quartz Bracelet with Buddha",
//         date: { day: "12", month: "Nov" },
//         day: "Wednesday",
//         shop: "shop",
//         price: "600",
//     };
//     const navigate = useNavigate()
//     const handleClick = () => {
//         navigate("/shop-details");
//     }

//     return (
//         <div className="py-8 w-4/5 mx-auto">
//          <div className="text-center py-6 bg-gradient-to-r from-red-400 via-yellow-400 to-orange-400 text-white">
//   <h2 className="text-2xl font-bold tracking-wide">
//     Malas, Japmala, and Beads Products
//   </h2>
//   <p className="text-lg font-medium mt-2">
//     Shop the Best Online Astrology Products and Services
//   </p>
// </div>

// <div className="flex flex-col md:flex-row items-center justify-between gap-4 p-6  rounded-lg shadow-lg">
//     <p className="bg-yellow-500 text-white px-5 py-3 rounded-md font-semibold text-lg shadow-sm transition-transform transform hover:scale-105">
//         Shop
//     </p>
//     <div className="flex items-center bg-white border border-yellow-500 rounded-full overflow-hidden shadow-md w-full md:w-2/3">
//         <span className="bg-yellow-500 p-4 flex items-center justify-center rounded-l-full transition-transform transform hover:scale-110">
//             <FaSearch className="text-white text-lg" />
//         </span>
//         <input
//             type="text"
//             placeholder="Search for anything..."
//             className="p-3 w-full focus:outline-none text-gray-700 text-sm"
//         />
//     </div>
// </div>

//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
//                 {Array.from({ length: 20 }).map((_, index) => (
//                     <div key={index} className="relative bg-white shadow-md hover:shadow-xl cursor-pointer  rounded-lg" onClick={handleClick}>
//                         {/* Date Button in the Top-Left Corner */}

//                         <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
//                             {cardData.shop}
//                         </div>

//                         <img src={cardData.url} alt={cardData.name} className="w-full h-48 object-cover rounded-t-lg" />
//                         <div className="p-4">

//                             <p className="text-gray-600">{cardData.description}</p>
//                             <div className="flex items-center justify-start mt-2 gap-2">
//                                 <p className="text-gray-500">{cardData.price}</p>
//                                 <p className="text-red-500 hover:text-yellow-700 font-semibold line-through">
//                                     650
//                                 </p>

//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Shop;

import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

function Shop() {
    const cardData = {
        url: "https://d1gcna0o0ldu5v.cloudfront.net/images/ae81fb49-c6f5-4268-b941-55c61409a013.jpg",
        name: "Hanuman Chalisa and Aarti",
        description: "Rose Quartz Bracelet with Buddha",
        shop: "shop",
        price: "600",
    };
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/shop-details");
    };

    return (
        <div className="py-8 w-11/12 mx-auto space-y-10">
            {/* Hero Section */}
            <div className="relative bg-gradient-to-r from-red-500 via-yellow-500 to-orange-500 text-white rounded-xl shadow-lg p-10 text-left transform translate-x-5">
                <h2 className="text-4xl font-bold tracking-wide mb-4">
                    Malas, Japmala, and Beads Products
                </h2>
                <p className="text-lg font-medium mb-6">
                    Shop the Best Online Astrology Products and Services
                </p>
            </div>

            {/* Search Bar Section */}
            <div className="flex items-center bg-white shadow-md rounded-full overflow-hidden w-3/4 mx-auto">
                <span className="bg-yellow-500 p-4 flex items-center justify-center rounded-l-full transition-transform transform hover:scale-110">
                    <FaSearch className="text-white text-lg" />
                </span>
                <input
                    type="text"
                    placeholder="Search for anything..."
                    className="p-3 w-full focus:outline-none text-gray-700 text-sm"
                />
            </div>

            {/* Product Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                {Array.from({ length: 20 }).map((_, index) => (
                    <div
                        key={index}
                        className={`relative bg-white shadow-lg hover:shadow-xl cursor-pointer rounded-xl overflow-hidden transition-transform transform hover:scale-105`}
                        onClick={handleClick}
                    >
                        {/* Shop Label */}
                        <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg shadow-sm">
                            {cardData.shop}
                        </div>

                        {/* Image and Content Shifted */}
                        <div className="relative">
                            {/* Image shifted to bottom */}
                            <img
                                src={cardData.url}
                                alt={cardData.name}
                                className="w-full h-48 object-cover transform translate-y-2"
                            />
                            <div className="p-4 absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent p-6 text-white">
                                {/* Description and Price shifted */}
                                <p className="text-lg font-semibold mb-4">{cardData.description}</p>
                                <div className="flex items-center justify-between">
                                    <p className="text-yellow-500 text-xl font-bold">
                                        ₹{cardData.price}
                                    </p>
                                    <p className="text-gray-400 text-sm line-through">
                                        ₹650
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Shop;

// import React from 'react';
// import { FaSearch } from 'react-icons/fa';
// import { useNavigate } from "react-router-dom";

// function Shop() {
//     const cardData = {
//         url: "https://d1gcna0o0ldu5v.cloudfront.net/images/ae81fb49-c6f5-4268-b941-55c61409a013.jpg",
//         name: "Hanuman Chalisa and Aarti",
//         description: "Rose Quartz Bracelet with Buddha",
//         date: { day: "12", month: "Nov" },
//         day: "Wednesday",
//         shop: "shop",
//         price: "600",
//     };
//     const navigate = useNavigate();
//     const handleClick = () => {
//         navigate("/shop-details");
//     };

//     return (
//         <div className="py-8 w-11/12 mx-auto">
//             {/* Hero Section */}
//             <div className="text-center py-10 bg-gradient-to-r from-red-500 via-yellow-500 to-orange-500 text-white rounded-xl shadow-lg">
//                 <h2 className="text-3xl font-extrabold tracking-wide">
//                     Malas, Japmala, and Beads Products
//                 </h2>
//                 <p className="text-lg font-medium mt-3">
//                     Shop the Best Online Astrology Products and Services
//                 </p>
//             </div>

//             {/* Search Bar Section */}
//             <div className="flex flex-col md:flex-row items-center justify-between gap-4 p-6 bg-white shadow-md rounded-xl mt-8">
//                 <p className="bg-gradient-to-r from-yellow-500 to-orange-500 text-white px-6 py-3 rounded-md font-semibold text-lg shadow-sm transition-transform transform hover:scale-105">
//                     Shop
//                 </p>
//                 <div className="flex items-center bg-white border border-yellow-500 rounded-full overflow-hidden shadow-md w-full md:w-2/3">
//                     <span className="bg-yellow-500 p-4 flex items-center justify-center rounded-l-full transition-transform transform hover:scale-110">
//                         <FaSearch className="text-white text-lg" />
//                     </span>
//                     <input
//                         type="text"
//                         placeholder="Search for anything..."
//                         className="p-3 w-full focus:outline-none text-gray-700 text-sm"
//                     />
//                 </div>
//             </div>

//             {/* Product Grid */}
//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-10">
//                 {Array.from({ length: 20 }).map((_, index) => (
//                     <div
//                         key={index}
//                         className="relative bg-gradient-to-t from-white via-gray-50 to-gray-100 shadow-lg hover:shadow-xl cursor-pointer rounded-xl overflow-hidden transition-transform transform hover:scale-105"
//                         onClick={handleClick}
//                     >
//                         {/* Shop Label */}
//                         <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg shadow-sm">
//                             {cardData.shop}
//                         </div>

//                         {/* Product Image */}
//                         <img
//                             src={cardData.url}
//                             alt={cardData.name}
//                             className="w-full h-48 object-cover"
//                         />

//                         {/* Product Info */}
//                         <div className="p-4">
//                             <p className="text-gray-700 font-semibold text-lg">
//                                 {cardData.description}
//                             </p>
//                             <div className="flex items-center justify-start mt-3 gap-2">
//                                 <p className="text-yellow-500 text-xl font-bold">
//                                     ₹{cardData.price}
//                                 </p>
//                                 <p className="text-gray-400 text-sm line-through">
//                                     ₹650
//                                 </p>
//                                 <span className="bg-green-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
//                                     8% Off
//                                 </span>
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Shop;

