import React, { memo } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const KaalsarpDoshSection = memo(({ kaalsarpDoshData }) => {
  if (!kaalsarpDoshData) return null;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Kaalsarp Dosh Analysis</h2>
      
      <div className="grid grid-cols-1 gap-8">
        <div className="bg-gray-50 rounded-lg p-6 shadow-md">
          <div className="mb-6">
            <div className="flex items-center mb-4">
              <FaExclamationTriangle className={`text-2xl ${kaalsarpDoshData.is_dosha_present ? 'text-red-500' : 'text-green-500'} mr-2`} />
              <h3 className="text-xl font-semibold">Status: {kaalsarpDoshData.is_dosha_present ? 'Present' : 'Not Present'}</h3>
            </div>
            <div className="space-y-4">
              { kaalsarpDoshData.is_dosha_present &&
                <div className="bg-white p-4 rounded-lg shadow">
                <h4 className="font-semibold text-gray-700 mb-2">Type & Direction</h4>
                <p className="text-gray-600">Type: {kaalsarpDoshData.dosha_type}</p>
                <p className="text-gray-600">Direction: {kaalsarpDoshData.dosha_direction}</p>
                <p className="text-gray-600">Rahu-Ketu Axis: {kaalsarpDoshData.rahu_ketu_axis}</p>
              </div>}
              
              <div className="bg-white p-4 rounded-lg shadow">
                <h4 className="font-semibold text-gray-700 mb-2">Analysis</h4>
                <p className="text-gray-600">{kaalsarpDoshData.bot_response}</p>
              </div>

              <div className="bg-white p-4 rounded-lg shadow">
                <h4 className="font-semibold text-gray-700 mb-2">Remedies</h4>
                <ul className="list-disc pl-5 space-y-2">
                  {kaalsarpDoshData.remedies.map((remedy, index) => (
                    <li key={index} className="text-gray-600">{remedy}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default KaalsarpDoshSection;