import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import io from "socket.io-client";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children, user }) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatEnded, setChatEnded] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);

  const initializeSocket = useCallback((user) => {
    // Only initialize socket if user exists
    if (!user?._id) {
      return () => { }; // Return empty cleanup function if no user
    }

    const socket = io("https://astrologerdaddy.com/", {
      query: { "user-id": user._id },
      withCredentials: true,
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      setError(error);
      setIsConnected(false);
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
      setError(error);
    });

    socket.on("connect", () => {
      console.log("Socket.io connection established.");
      setIsConnected(true);
      setError(null);
    });

    socket.on("message", (data) => {
      console.log("Received Socket.io message:", data);

      if (data.type === "CHAT_MESSAGE") {
        const messageData = {
          text: data.message.text,
          sender: data.message.sender,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        setMessages((prevMessages) => [...prevMessages, messageData]);
      } else if (
        data.type === "END_CHAT" ||
        (data.type === "ERROR" && data.message === "Chat session has ended.")
      ) {
        console.log("Chat session has ended.");
        setChatEnded(true);
      }
    });

    socket.on("disconnect", () => {
      console.log("Socket.io connection closed.");
      setIsConnected(false);
    });

    socket.on("reconnect", (attemptNumber) => {
      console.log(`Reconnected after ${attemptNumber} attempts`);
      setIsConnected(true);
      setError(null);
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
      setSocket(null);
      setIsConnected(false);
      setMessages([]);
      setChatEnded(false);
      setError(null);
    };
  }, [user?._id]); // Only depend on user._id if it exists

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) return;
    const cleanupSocket = initializeSocket(user);
    return cleanupSocket;
  }, [initializeSocket]);

  const sendMessage = useCallback((message) => {
    if (!socket || !isConnected) {
      console.error("Cannot send message: Socket is not connected");
      return;
    }

    try {
      console.log("Sending message:", message);
      socket.send(JSON.stringify(message));
    } catch (err) {
      console.error("Error sending message:", err);
      setError(err);
    }
  }, [socket, isConnected]);

  const resetChat = useCallback(() => {
    setMessages([]);
    setChatEnded(false);
    setError(null);
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        sendMessage,
        messages,
        setMessages,
        chatEnded,
        setChatEnded,
        isConnected,
        error,
        resetChat
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};