

import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

function PoojaBooking() {
    const cardData = {
        url: "https://aws.astrotalk.com/images/5d766a82-3d8b-4fad-b0b9-598625c77bcb.jpg",
        name: "Hanuman Chalisa and Aarti",
        description: "Get rid of health issues and attract a healthy body and mind",
        date: { day: "12", month: "Nov" },
        day:"Wednesday"
    };
const navigate=useNavigate()
const handleClick = () => {
    navigate("/poojadetails");
}

    return (
        <div className="py-8 w-4/5 mx-auto">
            <div className="flex items-center justify-between p-4 rounded-lg">
                <p className="bg-yellow-500 text-white px-4 py-2 rounded-lg font-semibold">
                    Book a Pooja
                </p>
                <div className="flex items-center bg-white border border-yellow-500 rounded-lg overflow-hidden shadow-md">
                    <span className="bg-yellow-500 p-3 flex items-center justify-center">
                        <FaSearch className="text-white" />
                    </span>
                    <input
                        type="text"
                        placeholder="Search pooja"
                        className="p-2 w-full focus:outline-none text-gray-700"
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
                {Array.from({ length: 20 }).map((_, index) => (
                    <div key={index} className="relative bg-white shadow-md hover:shadow-xl cursor-pointer  rounded-lg" onClick={handleClick}>
                        {/* Date Button in the Top-Left Corner */}
                        
                        <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
                            {cardData.date.day} {cardData.date.month}
                        </div>

                        <img src={cardData.url} alt={cardData.name} className="w-full h-48 object-cover rounded-t-lg" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold">{cardData.name}</h3>
                            <p className="text-gray-600">{cardData.description}</p>
                            <div className="flex items-center justify-between mt-4">
                                <p className="text-gray-500">{cardData.day}</p>
                                <button className="text-yellow-500 hover:text-yellow-700 font-semibold">
                                    Book Now <span className="ml-2">→</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PoojaBooking;
