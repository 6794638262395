

import React, { memo } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const CharDashaMainSection = memo(({ charDashaMainData }) => {
  if (!charDashaMainData) return null;

  const { dasha_list, dasha_end_dates } = charDashaMainData;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Char Dasha Main Section</h2>

      <div className="overflow-x-auto bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-3 px-4 text-left text-gray-600">Dasha</th>
              <th className="py-3 px-4 text-left text-gray-600">End Date</th>
            </tr>
          </thead>
          <tbody>
            {dasha_list.map((dasha, index) => (
              <tr key={dasha} className="hover:bg-gray-100 transition-colors duration-150">
                <td className="py-3 px-4 border-b text-gray-800">{dasha}</td>
                <td className="py-3 px-4 border-b text-gray-800 flex items-center">
                  <FaCalendarAlt className="mr-2 text-gray-500" />
                  {dasha_end_dates[index] 
                    ? new Date(dasha_end_dates[index]).toLocaleDateString() 
                    : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default CharDashaMainSection;
