


import React, { useState, useRef } from "react";
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { X } from 'lucide-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Modal component with thumbnails
const ImageModal = ({ images, isOpen, onClose, startIndex }) => {
  const [currentSlide, setCurrentSlide] = useState(startIndex);
  const sliderRef = useRef(null);
  const thumbnailRef = useRef(null);

  if (!isOpen) return null;

  const mainSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    initialSlide: startIndex,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      thumbnailRef.current.slickGoTo(next);
    }
  };

  const thumbnailSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: images.length > 4 ? 4 : images.length,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    focusOnSelect: true,
    centerMode: false,
    initialSlide: startIndex,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      sliderRef.current.slickGoTo(next);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className="relative w-full max-w-4xl mx-4 bg-black rounded-lg"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 z-10 text-white hover:text-gray-300 transition-colors"
        >
          <X size={24} />
        </button>
        
        {/* Main Image Slider */}
        <div className="p-4">
          <div className="mb-4">
            <Slider ref={sliderRef} {...mainSliderSettings}>
              {images.map((img, index) => (
                <div key={index} className="h-[60vh]">
                  <img
                    src={img}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
            </Slider>
          </div>

          {/* Thumbnail Strip */}
          <div className="h-24 bg-white">
            <Slider ref={thumbnailRef} {...thumbnailSettings}>
              {images.map((img, index) => (
                <div 
                  key={index} 
                  className={`h-24 px-1 cursor-pointer`}
                >
                  <div className={`h-full relative ${currentSlide === index ? 'ring-2 ring-yellow-500' : ''}`}>
                    <img
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className={`absolute inset-0 bg-black ${currentSlide === index ? 'bg-opacity-0' : 'bg-opacity-50'} transition-opacity`} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

function ShopDetailsDescription() {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const [modalOpen, setModalOpen] = useState(false);
  const [startImageIndex, setStartImageIndex] = useState(0);

  const images = [
    "https://d1gcna0o0ldu5v.cloudfront.net/images/03f91606-6de8-4f8b-b68c-19987b12822e.jpg",
    "https://d1gcna0o0ldu5v.cloudfront.net/images/03f91606-6de8-4f8b-b68c-19987b12822e.jpg",
    "https://d1gcna0o0ldu5v.cloudfront.net/images/03f91606-6de8-4f8b-b68c-19987b12822e.jpg"
  ];

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    dots: true
  };

  const handleImageClick = (index) => {
    setStartImageIndex(index);
    setModalOpen(true);
  };

  const sliderStyles = `
    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 40px;
      opacity: 0.8;
    }

    .slick-dots {
      bottom: 20px;
    }

    .slick-dots li button:before {
      font-size: 12px;
      color: white;
      opacity: 0.8;
    }

    .slick-dots li.slick-active button:before {
      color: white;
      opacity: 1;
    }
  `;

  const poojaTitle = [
    {
      title: "Premium Black Tourmaline Bracelet",
      description: "Experience Protection, Wellness, and Positive Energy. Ideal for daily wear and healing.",
      amount: "₹1699 ₹2199 (Save 23%)",
    },
  ];

  return (
    <div className="py-10 w-4/5 mx-auto">
    <style>{sliderStyles}</style>
    <div>
      {/* Image Gallery Section */}
      {isMobile ? (
        <div className="relative mb-8">
          <div className="h-[450px] bg-gradient-to-t from-gray-100 to-white">
            <Slider {...sliderSettings}>
              {images.map((img, index) => (
                <div key={index} className="h-[450px] relative overflow-hidden">
                  <img
                    src={img}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg shadow-lg hover:opacity-95 transition-opacity duration-300"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-6">
          <div className="col-span-1 flex flex-col gap-6">
            <div
              className="h-[280px] relative cursor-pointer transform transition-all duration-300 hover:scale-105"
              onClick={() => handleImageClick(0)}
            >
              <img
                src={images[0]}
                alt="First thumbnail"
                className="w-full h-full object-cover rounded-lg shadow-lg hover:opacity-90 transition-opacity"
              />
            </div>
            <div
              className="h-[280px] relative cursor-pointer transform transition-all duration-300 hover:scale-105"
              onClick={() => handleImageClick(1)}
            >
              <img
                src={images[1]}
                alt="Second thumbnail"
                className="w-full h-full object-cover rounded-lg shadow-lg hover:opacity-90 transition-opacity"
              />
            </div>
          </div>
          <div
            className="col-span-2 lg:col-span-3 h-[550px] relative cursor-pointer transform transition-all duration-300 hover:scale-105"
            onClick={() => handleImageClick(2)}
          >
            <img
              src={images[2]}
              alt="Main image"
              className="w-full h-full object-cover rounded-lg shadow-lg hover:opacity-90 transition-opacity"
            />
          </div>
        </div>
      )}
  
      {/* Image Modal */}
      <ImageModal
        images={images}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        startIndex={startImageIndex}
      />
  
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
  
  <div className="max-w-4xl space-y-6">
    <section className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 border-b-4 border-yellow-500 pb-2">
        Black Tourmaline Bracelet Benefits
      </h2>
      <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li><span className="font-semibold">Protection:</span> Shields from negative energies.</li>
        <li><span className="font-semibold">Emotional Balance:</span> Promotes mental clarity.</li>
        <li><span className="font-semibold">Physical Relief:</span> Alleviates muscle aches.</li>
      </ul>
    </section>
  </div>
 

  <div className="max-w-4xl space-y-6">
    <section className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 border-b-4 border-yellow-500 pb-2">
        Black Tourmaline Bracelet Benefits
      </h2>
      <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li><span className="font-semibold">Protection:</span> Shields from negative energies.</li>
        <li><span className="font-semibold">Emotional Balance:</span> Promotes mental clarity.</li>
        <li><span className="font-semibold">Physical Relief:</span> Alleviates muscle aches.</li>
      </ul>
    </section>
  </div>

  
</div>
<div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
  
  <div className="max-w-4xl space-y-6">
    <section className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 border-b-4 border-yellow-500 pb-2">
        Black Tourmaline Bracelet Benefits
      </h2>
      <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li><span className="font-semibold">Protection:</span> Shields from negative energies.</li>
        <li><span className="font-semibold">Emotional Balance:</span> Promotes mental clarity.</li>
        <li><span className="font-semibold">Physical Relief:</span> Alleviates muscle aches.</li>
      </ul>
    </section>
  </div>
 

  <div className="max-w-4xl space-y-6">
    <section className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 border-b-4 border-yellow-500 pb-2">
        Black Tourmaline Bracelet Benefits
      </h2>
      <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li><span className="font-semibold">Protection:</span> Shields from negative energies.</li>
        <li><span className="font-semibold">Emotional Balance:</span> Promotes mental clarity.</li>
        <li><span className="font-semibold">Physical Relief:</span> Alleviates muscle aches.</li>
      </ul>
    </section>
  </div>

 
</div>
<div className="w-full mt-5">
    <section className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 border-b-4 border-yellow-500 pb-2">
        Black Tourmaline Bracelet Benefits
      </h2>
      <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li><span className="font-semibold">Protection:</span> Shields from negative energies.</li>
        <li><span className="font-semibold">Emotional Balance:</span> Promotes mental clarity.</li>
        <li><span className="font-semibold">Physical Relief:</span> Alleviates muscle aches.</li>
      </ul>
    </section>
  </div>

    </div>
  </div>
  
);
}

export default ShopDetailsDescription;