
// import React from 'react';
// import { FaSearch } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';
// function Blog() {
//     const cardData = {
//         url: "https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg",
//         numbe:"travel",
//         description: "4 Zodiac Sign That Are Destined For Greatness...",
//         date: "13 nov 2024",
//         names: "jyoti"
//     };
    

//     const categories = [
//         "Home", "Tarot", "Vastu", "Vedic", "Kundli", "Sports", "Transits", 
//         "Festivals", "Business", "Gemstone", "Numerology", "Zodiac Signs", 
//         "Compatibility", "Entertainment", "Current Affairs", "Daily Horoscope", 
//         "Mythological Tales", "Web Stories"
//     ];
//     const trending = [
//         "Astrodaddy Birthday: How to get free chat on Astrodaddy",
//         "Meet the Founder: Puneet Gupta’s Inspiring Journey Behind Astrodaddy",
//         "Avoid Doing And Buying These Things On Tuesday",
//         "Astrodaddy Journey: From failed startup to biggest astrology Platform"
//     ];
//      const language = [
//         "All","English","Hindi"
//      ]
//      const navigate = useNavigate()
//      const handleBlog = () =>{
//         navigate("/blog-details")
//      }
//     return (
//         <div className="py-12 w-4/5 mx-auto">
//               <div className="flex items-center justify-between p-4 rounded-lg">
//                 <p className="bg-yellow-500 text-white px-4 py-2 rounded-lg font-semibold">
//                     Blog
//                 </p>
//                 <div className="flex items-center bg-white border border-yellow-500 lg:w-[25%] rounded-lg overflow-hidden shadow-md">
//                     <span className="bg-yellow-500 p-3 flex items-center justify-center">
//                         <FaSearch className="text-white" />
//                     </span>
//                     <input
//                         type="text"
//                         placeholder="Search blog
//                         "
//                         className="p-2 w-full focus:outline-none text-gray-700"
//                     />
//                 </div>
//             </div>
//             <div className="flex flex-wrap md:flex-nowrap items-start justify-between gap-6">
                
//                 {/* Card Container */}
//                 <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 flex-grow">
//                     {Array.from({ length: 9 }).map((_, index) => (
//                         <div key={index} className="relative bg-white shadow-md hover:shadow-xl cursor-pointer transition-shadow duration-300 rounded-lg overflow-hidden" onClick={handleBlog}>
//                             {/* Date Badge */}
//                             <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
//                                 {cardData.numbe}
//                             </div>
//                             {/* Image */}
//                             <img src={cardData.url} alt={cardData.name} className="w-full h-48 object-cover" />
//                             {/* Content */}
//                             <div className="p-5">
//                             <p className="text-gray-500 text-sm">{cardData.date}</p>
//                             <p className="text-gray-500 text-lg font-medium">{cardData.names}</p>
//                                 <p className="text-gray-600 mt-1">{cardData.description}</p>
                                
                                
                                
                                 
                                    
                                
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//                 {/* Categories Sidebar */}
                
//                 <aside className="w-full md:w-1/3  p-5 rounded-lg shadow-md">
//                     <h2 className="text-lg font-semibold text-gray-800 mb-4">Categories</h2>
//                     <div className="grid grid-cols-2 gap-2">
//                         {categories.map((category, index) => (
//                             <button key={index} className="border border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors">
//                                 {category}
//                             </button>
//                         ))}
//                     </div>
//                     <div className="w-full  p-5 rounded-lg shadow-md">
//                     <h2 className="text-lg font-semibold text-gray-800 mb-4">Trending Now</h2>
//                     <div >
//                         {trending.map((trend, index) => (
//                             <button key={index} className="bg-white border my-2 border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors">
//                                 {trend}
//                             </button>
//                         ))}
//                     </div>
                        
//                     </div>
//                     <div className="w-full p-5 rounded-lg shadow-md">
//                     <h2 className="text-lg font-semibold text-gray-800 mb-4">Language</h2>
//                     <div >
//                         {language.map((lang, index) => (
//                             <button key={index} className="bg-white hover:bg-yellow-100 text-gray-700 py-2 px-4 mx-1 border border-yellow-400 rounded-md shadow text-xs font-medium transition-colors">
//                                 {lang}
//                             </button>
//                         ))}
//                     </div>
                        
//                     </div>
//                 </aside>
//             </div>
//         </div>
//     );
// }

// export default Blog;
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import freehoroscope from "../assets/slidercard/check_your_free_horoscope.png";
import compatible from "../assets/slidercard/Compatibilty.png";
import festival from "../assets/slidercard/Festival.png";
import kundliMaking from "../assets/slidercard/kundli_making.png";
import kundliMatching from "../assets/slidercard/kundli_matching.png";
import luckyGenerator from "../assets/slidercard/Lucky_gemstone.png";
import panchang from "../assets/slidercard/Panchang.png";
function Blog() {
    const navigate = useNavigate();
    
    const testimonials = [
        {
            avatar: freehoroscope,
            name: "Check your Free Horoscope",
        },
        {
            avatar: kundliMaking,
            name: "Kundli Making",
        },
        {
            avatar: kundliMatching,
            name: "Kundli Matching",
        },
        {
            avatar: festival,
            name: "Numerology",
        },
        {
            avatar: compatible,
            name: "Compatibility",
        },
        {
            avatar: panchang,
            name: "Panchang",
        },
        {
            avatar: luckyGenerator,
            name: "Gems & Rudraksha",
        },
    ];

    const handleCardClick = (name) => {
        switch (name) {
            case "Check your Free Horoscope":
                navigate("/horoscope-page");
                break;
            case "Gems & Rudraksha":
                navigate('/gemstone');
                break;
            case "Kundli Making":
                navigate('/kundli-making');
                break;
            case "Kundli Matching":
                navigate("/kundli-matching");
                break;
            case "Numerology":
                navigate('/numerology');
                break;
            case "Compatibility":
                navigate('/compatibility');
                break;
            case "Panchang":
                navigate('/panchang');
                break;
            default:
                navigate('/');
                break;
        }
    };

    const cardData = {
        url: "https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg",
        numbe: "travel",
        description: "4 Zodiac Sign That Are Destined For Greatness...",
        date: "13 nov 2024",
        names: "jyoti"
    };

    const categories = [
        "Home", "Tarot", "Vastu", "Vedic", "Kundli", "Sports", "Transits",
        "Festivals", "Business", "Gemstone", "Numerology", "Zodiac Signs",
        "Compatibility", "Entertainment", "Current Affairs", "Daily Horoscope",
        "Mythological Tales", "Web Stories"
    ];

    const trending = [
        "Astrodaddy Birthday: How to get free chat on Astrodaddy",
        "Meet the Founder: Puneet Gupta's Inspiring Journey Behind Astrodaddy",
        "Avoid Doing And Buying These Things On Tuesday",
        "Astrodaddy Journey: From failed startup to biggest astrology Platform"
    ];

    const language = [ "English", "Hindi"];

    const handleBlog = () => {
        navigate("/blog-details");
    };

    return (
        <div className="py-12 w-4/5 mx-auto">
            <div className="flex items-center justify-between p-4 rounded-lg">
                <p className="bg-yellow-500 text-white px-4 py-2 rounded-lg font-semibold">
                    Blog
                </p>
                <div className="flex items-center bg-white border border-yellow-500 lg:w-[25%] rounded-lg overflow-hidden shadow-md">
                    <span className="bg-yellow-500 p-3 flex items-center justify-center">
                        <FaSearch className="text-white" />
                    </span>
                    <input
                        type="text"
                        placeholder="Search blog"
                        className="p-2 w-full focus:outline-none text-gray-700"
                    />
                </div>
            </div>

            <div className="flex flex-wrap md:flex-nowrap items-start justify-between gap-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 flex-grow">
                    {Array.from({ length: 9 }).map((_, index) => (
                        <div key={index} className="relative bg-white shadow-md hover:shadow-xl cursor-pointer transition-shadow duration-300 rounded-lg overflow-hidden" onClick={handleBlog}>
                            <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
                                {cardData.numbe}
                            </div>
                            <img src={cardData.url} alt={cardData.name} className="w-full h-48 object-cover" />
                            <div className="p-5">
                                <p className="text-gray-500 text-sm">{cardData.date}</p>
                                <p className="text-gray-500 text-lg font-medium">{cardData.names}</p>
                                <p className="text-gray-600 mt-1">{cardData.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <aside className="w-full md:w-1/3 space-y-6">
                    {/* New Astrology Services Section */}
                    <div className="bg-white p-5 rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Free Astrology Services</h2>
                        <div className="grid grid-cols-2 gap-3">
                            {testimonials.map((service, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleCardClick(service.name)}
                                    className="flex flex-col items-center cursor-pointer group"
                                >
                                    <div className="w-12 h-12 mb-2 rounded-full overflow-hidden transition-transform group-hover:scale-110">
                                        <img
                                            src={service.avatar}
                                            alt={service.name}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <span className="text-xs text-center text-gray-700 group-hover:text-yellow-600 font-medium">
                                        {service.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="bg-white p-5 rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Categories</h2>
                        <div className="grid grid-cols-2 gap-2">
                            {categories.map((category, index) => (
                                <button key={index} className="border border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors">
                                    {category}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="bg-white p-5 rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Trending Now</h2>
                        <div className="flex flex-col gap-2">
                            {trending.map((trend, index) => (
                                <button key={index} className="bg-white border border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors text-left">
                                    {trend}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="bg-white p-5 rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Language</h2>
                        <div className="flex gap-2">
                            {language.map((lang, index) => (
                                <button key={index} className="bg-white hover:bg-yellow-100 text-gray-700 py-2 px-4 border border-yellow-400 rounded-md shadow text-xs font-medium transition-colors">
                                    {lang}
                                </button>
                            ))}
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    );
}

export default Blog;