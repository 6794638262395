import {memo} from 'react'
// Memoized Table Row Component
const TableRow = memo(({ label, value }) => (
  <tr className="hover:bg-gray-50">
    <td className="py-2 px-4 border-b font-semibold">{label}</td>
    <td className="py-2 px-4 border-b">{value}</td>
  </tr>
));
// Memoized Ascendant Report Section
const AscendantReportSection = memo(({ report }) => {
  if (!report) return null;

  return (
    <div className="mt-4 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-red-700 mb-6">Ascendant Report</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <tbody>
          <TableRow label="Ascendant" value={report.ascendant} />
          <TableRow label="Ascendant Lord" value={report.ascendant_lord} />
          <TableRow
            label="Location of Ascendant Lord"
            value={report.ascendant_lord_location}
          />
          <TableRow
            label="General Prediction"
            value={report.general_prediction}
          />
          <TableRow
            label="Personalized Prediction"
            value={report.personalised_prediction}
          />
          <TableRow label="Verbal Location" value={report.verbal_location} />
          <TableRow
            label="Ascendant Lord Strength"
            value={report.ascendant_lord_strength}
          />
          <TableRow label="Lucky Gem" value={report.lucky_gem} />
          <TableRow label="Day for Fasting" value={report.day_for_fasting} />
          <TableRow label="Gayatri Mantra" value={report.gayatri_mantra} />
          <TableRow label="Good Qualities" value={report.good_qualities} />
          <TableRow label="Bad Qualities" value={report.bad_qualities} />
        </tbody>
      </table>
    </div>
  );
});

export default AscendantReportSection