import React from "react";
import { Avatar } from "@stream-io/video-react-sdk";
import {
  FaUserCircle,
} from "react-icons/fa";

export const MyParticipant = ({ participant }) => {
  const { isSpeaking } = participant;
  console.log(participant);
  return (
    <div className={`flex items-center w-full p-2 rounded-lg `}>
      <Avatar imageSrc={participant.image} className="w-8 h-8 rounded-full" />
      <FaUserCircle
        size="48"
        className={` ${isSpeaking ? "text-green-600" : "text-gray-100"}`}
      />
      <div
        className={`ml-3 text-sm font-medium  ${
          isSpeaking ? "text-green-600" : "text-gray-100"
        }`}
      >
        {participant.roles[0] === "admin" ? "Astrologer: " : "User: "}
        {participant.name}
      </div>
    </div>
  );
};
