import React from 'react';
import { AiOutlineStar, AiOutlineCalendar } from 'react-icons/ai';
import { FaSun, FaMoon } from 'react-icons/fa';
import { BsStars, BsClock } from 'react-icons/bs';

const formatTitle = (text) => {
  return text
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const DetailRow = ({ label, value }) => (
  <div className="flex flex-col sm:flex-row sm:justify-between py-2 border-b border-slate-100 last:border-b-0">
    <span className="text-slate-600 font-medium mb-1 sm:mb-0">{formatTitle(label)}</span>
    <span className="text-slate-800">{value}</span>
  </div>
);

const TimeRow = ({ label, start, end }) => (
  <div className="flex flex-col py-2 border-b border-slate-100 last:border-b-0">
    <span className="text-slate-600 font-medium mb-1">{formatTitle(label)}</span>
    <div className="grid grid-cols-2 gap-2 text-sm">
      <div>
        <span className="text-slate-500">Start:</span>
        <span className="ml-2 text-slate-800">{start}</span>
      </div>
      <div>
        <span className="text-slate-500">End:</span>
        <span className="ml-2 text-slate-800">{end}</span>
      </div>
    </div>
  </div>
);

const PanchangCard = ({ title, icon, children }) => (
  <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-4 mb-4">
    <div className="flex items-center gap-2 mb-3 pb-2 border-b border-slate-100">
      {icon}
      <h3 className="text-lg font-semibold text-slate-800">{title}</h3>
    </div>
    <div className="space-y-2">
      {children}
    </div>
  </div>
);

const PanchangDetails = ({ data }) => {
  const response = data;

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-slate-900 mb-2">Daily Panchang</h2>
        <p className="text-slate-600">{response?.date}</p>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <PanchangCard title="Tithi Details" icon={<AiOutlineCalendar className="w-5 h-5 text-blue-600" />}>
          <DetailRow label="Day" value={response?.day?.name} />
          <DetailRow label="Tithi" value={response?.tithi?.name} />
          <DetailRow label="Type" value={response?.tithi?.type} />
          <DetailRow label="Number" value={response?.tithi?.number} />
          <DetailRow label="Deity" value={response?.tithi?.diety} />
          <DetailRow label="Next Tithi" value={response?.tithi?.next_tithi} />
          <DetailRow label="Meaning" value={response?.tithi?.meaning} />
          <DetailRow label="Special" value={response?.tithi?.special} />
          <TimeRow 
            label="Tithi Time"
            start={response?.tithi?.start}
            end={response?.tithi?.end}
          />
        </PanchangCard>

        <PanchangCard title="Nakshatra Details" icon={<BsStars className="w-5 h-5 text-indigo-600" />}>
          <DetailRow label="Name" value={response?.nakshatra?.name} />
          <DetailRow label="Number" value={response?.nakshatra?.number} />
          <DetailRow label="Lord" value={response?.nakshatra?.lord} />
          <DetailRow label="Deity" value={response?.nakshatra?.diety} />
          <DetailRow label="Next Nakshatra" value={response?.nakshatra?.next_nakshatra} />
          <DetailRow label="Meaning" value={response?.nakshatra?.meaning} />
          <DetailRow label="Special" value={response?.nakshatra?.special} />
          <TimeRow 
            label="Nakshatra Time"
            start={response?.nakshatra?.start}
            end={response?.nakshatra?.end}
          />
        </PanchangCard>

        <PanchangCard title="Karana Details" icon={<AiOutlineStar className="w-5 h-5 text-purple-600" />}>
          <DetailRow label="Name" value={response?.karana?.name} />
          <DetailRow label="Number" value={response?.karana?.number} />
          <DetailRow label="Type" value={response?.karana?.type} />
          <DetailRow label="Lord" value={response?.karana?.lord} />
          <DetailRow label="Deity" value={response?.karana?.diety} />
          <DetailRow label="Next Karana" value={response?.karana?.next_karana} />
          <DetailRow label="Special" value={response?.karana?.special} />
          <TimeRow 
            label="Karana Time"
            start={response?.karana?.start}
            end={response?.karana?.end}
          />
        </PanchangCard>

        <PanchangCard title="Yoga Details" icon={<BsStars className="w-5 h-5 text-yellow-600" />}>
          <DetailRow label="Name" value={response?.yoga?.name} />
          <DetailRow label="Number" value={response?.yoga?.number} />
          <DetailRow label="Next Yoga" value={response?.yoga?.next_yoga} />
          <DetailRow label="Meaning" value={response?.yoga?.meaning} />
          <DetailRow label="Special" value={response?.yoga?.special} />
          <TimeRow 
            label="Yoga Time"
            start={response?.yoga?.start}
            end={response?.yoga?.end}
          />
        </PanchangCard>

        <PanchangCard title="Celestial Positions" icon={<FaSun className="w-5 h-5 text-orange-600" />}>
          <DetailRow label="Rasi" value={response?.rasi?.name} />
          <DetailRow label="Ayanamsa" value={response?.ayanamsa?.name} />
          <DetailRow label="Sun Zodiac" value={response?.sun_position?.zodiac} />
          <DetailRow label="Sun Nakshatra" value={response?.sun_position?.nakshatra} />
          <DetailRow label="Sun Rasi Number" value={response?.sun_position?.rasi_no} />
          <DetailRow label="Sun Nakshatra Number" value={response?.sun_position?.nakshatra_no} />
          <DetailRow label="Sun Degree at Rise" value={response?.sun_position?.sun_degree_at_rise?.toFixed(2)} />
          <DetailRow label="Moon Degree" value={response?.moon_position?.moon_degree?.toFixed(2)} />
        </PanchangCard>

        <PanchangCard title="Daily Timings" icon={<BsClock className="w-5 h-5 text-green-600" />}>
          <DetailRow label="Sunrise" value={response?.advanced_details?.sun_rise} />
          <DetailRow label="Sunset" value={response?.advanced_details?.sun_set} />
          <DetailRow label="Moonrise" value={response?.advanced_details?.moon_rise} />
          <DetailRow label="Moonset" value={response?.advanced_details?.moon_set} />
          <DetailRow label="Rahukaal" value={response?.rahukaal} />
          <DetailRow label="Gulika" value={response?.gulika} />
          <DetailRow label="Yamakanta" value={response?.yamakanta} />
          <TimeRow 
            label="Abhijit Muhurta"
            start={response?.advanced_details?.abhijit_muhurta?.start}
            end={response?.advanced_details?.abhijit_muhurta?.end}
          />
        </PanchangCard>

        <PanchangCard title="Masa Information" icon={<FaMoon className="w-5 h-5 text-slate-600" />}>
          <DetailRow label="Amanta Month" value={`${response?.advanced_details?.masa?.amanta_name} (${response?.advanced_details?.masa?.alternate_amanta_name})`} />
          <DetailRow label="Amanta Date" value={response?.advanced_details?.masa?.amanta_date} />
          <TimeRow 
            label="Amanta Period"
            start={response?.advanced_details?.masa?.amanta_start}
            end={response?.advanced_details?.masa?.amanta_end}
          />
          <DetailRow label="Purnimanta Month" value={`${response?.advanced_details?.masa?.purnimanta_name} (${response?.advanced_details?.masa?.alternate_purnimanta_name})`} />
          <DetailRow label="Purnimanta Date" value={response?.advanced_details?.masa?.purnimanta_date} />
          <TimeRow 
            label="Purnimanta Period"
            start={response?.advanced_details?.masa?.purnimanta_start}
            end={response?.advanced_details?.masa?.purnimanta_end}
          />
          <DetailRow label="Paksha" value={response?.advanced_details?.masa?.paksha} />
          <DetailRow label="Ritu" value={response?.advanced_details?.masa?.ritu} />
          <DetailRow label="Tamil Month" value={response?.advanced_details?.masa?.tamil_month} />
          <DetailRow label="Tamil Day" value={response?.advanced_details?.masa?.tamil_day} />
          <DetailRow label="Moon Phase" value={response?.advanced_details?.masa?.moon_phase} />
          <DetailRow label="Next Full Moon" value={response?.advanced_details?.next_full_moon} />
          <DetailRow label="Next New Moon" value={response?.advanced_details?.next_new_moon} />
        </PanchangCard>

        <PanchangCard title="Year Information" icon={<AiOutlineCalendar className="w-5 h-5 text-red-600" />}>
          <DetailRow label="Kali Year" value={`${response?.advanced_details?.years?.kali} (${response?.advanced_details?.years?.kali_samvaat_name})`} />
          <DetailRow label="Vikram Samvat" value={`${response?.advanced_details?.years?.vikram_samvaat} (${response?.advanced_details?.years?.vikram_samvaat_name})`} />
          <DetailRow label="Saka Year" value={`${response?.advanced_details?.years?.saka} (${response?.advanced_details?.years?.saka_samvaat_name})`} />
        </PanchangCard>

        <PanchangCard title="Additional Information" icon={<AiOutlineStar className="w-5 h-5 text-purple-600" />}>
          <DetailRow label="Vaara" value={response?.advanced_details?.vaara} />
          <DetailRow label="Disha Shool" value={response?.advanced_details?.disha_shool} />
          <DetailRow label="Moon Yogini Nivas" value={response?.advanced_details?.moon_yogini_nivas} />
          <DetailRow label="Ahargana" value={response?.advanced_details?.ahargana?.toFixed(2)} />
        </PanchangCard>
      </div>
    </div>
  );
};

export default PanchangDetails;