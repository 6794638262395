import React, { memo, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';


const NumerologyTable = memo(() => {
  const [numerologyData, setNumerologyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: '', dob: '' });
  const [errors, setErrors] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");
  // Fetch the numerology data from the API
  const fetchNumerologyData = async (name, dob) => {
    setLoading(true);
    setErrors('');

    try {
      const params = new URLSearchParams({
        name: name.trim(),
        date: dob,
        lang: language
      });

      const response = await axios.get(
        `https://astrologerdaddy.com/api/astrology-services/prediction/numerology`,
        {
          params,
          timeout: 10000, // 10 second timeout
          headers: {
            'Accept': 'application/json'
          }
        }
      );

      const data = response.data?.response;

      if (!data) {
        throw new Error('Invalid response format');
      }

      setNumerologyData(data);

    } catch (error) {
      let errorMessage = 'Failed to fetch numerology data. Please try again later.';

      if (axios.isCancel(error)) {
        errorMessage = 'Request was cancelled';
      } else if (error.response) {
        // Server responded with error status
        switch (error.response.status) {
          case 400:
            errorMessage = 'Invalid input parameters';
            break;
          case 401:
            errorMessage = 'Invalid API key';
            break;
          case 429:
            errorMessage = 'Too many requests. Please try again later';
            break;
          case 503:
            errorMessage = 'Service temporarily unavailable';
            break;
          default:
            errorMessage = 'An error occurred';
        }
      } else if (error.code === 'ECONNABORTED') {
        errorMessage = 'Request timed out. Please check your connection';
      }

      console.error('Numerology API Error:', error);
      setErrors(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const handleLanguageChange = async (event) => {
  const selectedLang = event.target.value;
  console.log(selectedLang);
  
  // Update language state and localStorage
  setLanguage(selectedLang);
  localStorage.setItem("selectedLanguage", selectedLang);
  
  // Pass the new language value directly rather than using the state
  if (formData.name && formData.dob) {
    try {
      const params = new URLSearchParams({
        name: formData.name.trim(),
        date: formData.dob,
        lang: selectedLang  // Use selectedLang directly instead of language state
      });

      setLoading(true);
      setErrors('');

      const response = await axios.get(
        `https://astrologerdaddy.com/api/astrology-services/prediction/numerology`,
        {
          params,
          timeout: 10000,
          headers: {
            'Accept': 'application/json'
          }
        }
      );

      const data = response.data?.response;
      if (!data) {
        throw new Error('Invalid response format');
      }

      setNumerologyData(data);
    } catch (error) {
      // ... error handling code ...
    } finally {
      setLoading(false);
    }
  }
};

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, dob } = formData;
    if (validateForm()) {
      fetchNumerologyData(name, dob);
    }
  };

  const validateForm = () => {
    const { dob } = formData;
    const dobPattern = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!dobPattern.test(dob)) {
      setErrors('Date should be in dd/mm/yyyy format.');
      return false;
    }
    return true;
  };

  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
    // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];


  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div >
      <div className="lg:flex md:flex max-w-7xl mx-auto px-4 my-6 items-stretch justify-between w-full ">
        <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
            {/* <div className='flex items-center justify-between'>
          <h2 className="text-2xl md:text-3xl font-bold text-red-700 mb-6">Numerology Prediction</h2>
          <div className="flex flex-wrap gap-2 px-2 mt-2">
      <label htmlFor="language-select">Select Language: </label>
      <select
        id="language-select"
        value={language}
        onChange={handleLanguageChange}
      >
        <option value="en">English</option>
        <option value="ta">Tamil</option>
        <option value="ka">Kannada</option>
        <option value="te">Telugu</option>
        <option value="hi">Hindi</option>
        <option value="ml">Malayalam</option>
        <option value="mr">Marathi</option>
        
        <option value="sp">Spanish</option>
        <option value="fr">French</option>
      </select>
    </div>
          </div> */}
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-4 py-4">
  {/* Heading */}
  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-red-700 text-center sm:text-left">
    Numerology Prediction
  </h2>

  {/* Language Selector */}
  <div className="flex flex-col sm:flex-row items-center gap-2">
    <label
      htmlFor="language-select"
      className="text-sm font-medium whitespace-nowrap"
    >
      Select Language:
    </label>
    <select
      id="language-select"
      className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
      value={language}
      onChange={handleLanguageChange}
    >
      <option value="en">English</option>
      <option value="ta">Tamil</option>
      <option value="ka">Kannada</option>
      <option value="te">Telugu</option>
      <option value="hi">Hindi</option>
      <option value="ml">Malayalam</option>
      <option value="mr">Marathi</option>
      
      <option value="sp">Spanish</option>
      <option value="fr">French</option>
    </select>
  </div>
</div>

          <form onSubmit={handleSubmit} className="mb-8">
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">
                Date of Birth (dd/mm/yyyy) <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter DOB"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
                pattern="\d{2}/\d{2}/\d{4}"
                title="Date should be in dd/mm/yyyy format."
              />
              {errors && <p className="text-red-500 mt-2">{errors}</p>}
            </div>
            <button
              type="submit"
              className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition-colors"
            >
              Get Prediction
            </button>
          </form>

          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div>
            </div>
          ) : (
            <div className="space-y-8">
              {/* Loop through each numerology category and display its data */}
              {Object.keys(numerologyData).map((key) => {
                const data = numerologyData[key];
                return (
                  <div key={key} className="bg-gray-50 p-4 rounded-md shadow-sm border border-gray-300">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">{data.title}</h3>
                    <table className="min-w-full bg-white border border-gray-200 rounded-md">
                      <tbody>
                        <tr>
                          <td className="py-3 px-4 border-t text-left text-gray-700 font-semibold">Number</td>
                          <td className="py-3 px-4 border-t text-gray-700">{data.number || '-'}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 border-t text-left text-gray-700 font-semibold">Master</td>
                          <td className="py-3 px-4 border-t text-gray-700">{data.master ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 border-t text-left text-gray-700 font-semibold">Meaning</td>
                          <td className="py-3 px-4 border-t text-gray-700">{data.meaning || '-'}</td>
                        </tr>
                        <tr>
                          <td className="py-3 px-4 border-t text-left text-gray-700 font-semibold">Description</td>
                          <td className="py-3 px-4 border-t text-gray-700">{data.description || '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          )}

        </div>
        <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
});

export default NumerologyTable;
