import React, { memo } from 'react';
import { FaMoon } from 'react-icons/fa';

const MoonCalendar = memo(({ data }) => {
  const renderDetailCard = (title, details, icon) => (
    <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6 mb-6">
      <div className="flex items-center gap-2 mb-4">
        {icon}
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {details.map(({ date, state, luminance, paksha }) => (
          <div key={date} className="p-4 bg-slate-50 rounded-lg">
            <div className="font-medium text-slate-700 mb-1">{new Date(date).toDateString()}</div>
            <div className="text-slate-600">
              <div className="flex justify-between">
                <span className="text-slate-500">State:</span>
                <span>{state}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-500">Luminance:</span>
                <span>{luminance}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-500">Paksha:</span>
                <span>{paksha}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-slate-900 mb-2">Moon Calendar</h2>
        <p className="text-slate-600">Daily Moon Phases and Timings</p>
      </div>

      {data && renderDetailCard('Moon Phases', data, <FaMoon className="w-6 h-6 text-slate-600" />)}
    </div>
  );
});

export default MoonCalendar;
