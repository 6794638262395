import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { logo } from "../assets/assets";
import { useAuth } from "../contexts/AuthContext";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Add useLocation hook
  const { token, user, logout } = useAuth(); // Get token and user from the auth context

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for the dropdown menu

  // Add useEffect to handle login page redirection
  useEffect(() => {
    // Check if user is logged in and currently on the login page
    if (token && location.pathname === '/login') {
      navigate('/');
    }
  }, [token, location.pathname, navigate]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
 
  return (
    <nav className="bg-gray-100 text-black shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <div className="flex items-center justify-between w-full h-full py-2">
            <img
              className="h-full max-w-[86%]"
              src={logo}
              alt="Logo"
              onClick={() => navigate("/")}
            />

            <div className="hidden md:block">
              <div className="ml-10 flex items-center space-x-4">
                <a
                  href="/astrologers"
                  className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2 rounded-xl text-lg font-medium"
                >
                  Chat with Astrologers
                </a>
                <a
                  href="/astrologers"
                  className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2 rounded-xl text-lg font-medium"
                >
                  Call Astrologers
                </a>

                {/* Conditional Rendering: Show Login button or Avatar */}
                {!token ? (
                  <button
                    onClick={() => navigate("/login")}
                    className="hover:bg-yellow-500 bg-white border min-w-fit px-3 py-2 rounded-xl text-lg font-medium"
                  >
                    Login
                  </button>
                ) : (
                  <div className="relative">
                    <svg
                      fill="#8c8c8c"
                      height="200px"
                      width="200px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      stroke="#4a4a4a"
                      onClick={toggleDropdown}
                      className="h-10 w-10 rounded-full cursor-pointer hover:bg-zinc-200"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <circle
                              cx="256"
                              cy="114.526"
                              r="114.526"
                            ></circle>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M256,256c-111.619,0-202.105,90.487-202.105,202.105c0,29.765,24.13,53.895,53.895,53.895h296.421 c29.765,0,53.895-24.13,53.895-53.895C458.105,346.487,367.619,256,256,256z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                    {dropdownOpen && (
                      <div className="absolute right-[-100%] top-12 z-50 mt-2 w-48 bg-white border rounded-lg shadow-lg">
                        <button
                          onClick={() => {
                            setDropdownOpen(false);
                            navigate("/user");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 w-full text-left"
                        >
                          Profile
                        </button>
                        <button
                          onClick={() => {
                            setDropdownOpen(false);
                            navigate("/user/wallet");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 w-full text-left"
                        >
                          Wallet
                        </button>
                        <button
                          onClick={() => {
                            setDropdownOpen(false);
                            navigate("/user/appointments");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 w-full text-left"
                        >
                          Appointments
                        </button>
                        <button
                          onClick={() => {
                            logout();
                            navigate("/login");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 w-full text-left"
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md bg-yellow-400 text-red-700 hover:text-red-700 hover:bg-[gray-700] focus:outline-none focus:bg-yellow-500 focus:text-red-600"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:flex justify-center w-full bg-[#f4d42a]">
        <div className="ml-10 max-w-7xl w-full flex items-center justify-between px-6">
          <button
            onClick={() => navigate("/")}
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Home
          </button>
          <a
            href="/astrologers"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Consult Astrologers
          </a>
          <a
            href="/horoscope-page"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Horoscope
          </a>
          <a
            href="/panchang"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Panchang
          </a>
          <a
            href="#"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Book a Pooja
          </a>
          <a
            href="#"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            Blog
          </a>
          <a
            href="#"
            className="hover:bg-yellow-500 bg-[#f4d42a] min-w-fit px-3 py-2  text-lg font-medium"
          >
            More
          </a>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => {
                setIsOpen(false);
                navigate("/");
              }}
              className="hover:bg-yellow-500  w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </button>
            <button
               onClick={() => {
                setIsOpen(false);
                navigate("/astrologers");
              }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Consult Astrologers
            </button>
            <button
               onClick={() => {
                setIsOpen(false);
                navigate("#");
              }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Horoscope
            </button>
            <button
                 onClick={() => {
                  setIsOpen(false);
                  navigate("#");
                }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Panchang
            </button>
            <button
                 onClick={() => {
                  setIsOpen(false);
                  navigate("#");
                }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Book a Pooja
            </button>
            <button
                onClick={() => {
                  setIsOpen(false);
                  navigate("#");
                }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              Blog
            </button>
            <button
                onClick={() => {
                  setIsOpen(false);
                  navigate("#");
                }}
              className="hover:bg-yellow-500 w-full text-left block px-3 py-2 rounded-md text-base font-medium"
            >
              More
            </button>

            {/* Add Login/Profile Options for Mobile View */}
            {!token ? (
              <button
                onClick={() => {
                  navigate("/login")
                  setIsOpen(false)
                
                }}
                className="hover:bg-yellow-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Login
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/user");
                  }}
                  className="hover:bg-yellow-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Profile
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/user/wallet");
                  }}
                  className="hover:bg-yellow-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Wallet
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/user/appointments");
                  }}
                  className="hover:bg-yellow-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Appointments
                </button>
                <button
                  onClick={() => {
                    logout();
                    navigate("/login");
                  }}
                  className="hover:bg-yellow-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
