import React, { useState, useEffect } from "react";
import kundali from "../assets/kundali.webp";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
const Kundli = () => {
  const navigate = useNavigate();
  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
  const seconds = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

  const today = new Date();
  const todayYear = today.getFullYear();

  const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    dob: "", // Date of birth in dd/mm/yyyy format
    hour: "",
    minute: "",
    second: "",
    birth_place: "",
  });

  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapboxToken =
    "pk.eyJ1IjoiYXN0cm9sb2dlcmRhZGR5IiwiYSI6ImNtMmE0aDFkZzBjcG0ybXE0ODFlcXF2ZmUifQ.NjJNQIDHyhlFVPm3l7bsCg";

  useEffect(() => {
    if (formData.dob) {
      const [day, month, year] = formData.dob.split("/").map(Number);

      // Validate the date
      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        setErrors("Invalid date format. Please use dd/mm/yyyy.");
        return;
      }

      const selectedDate = new Date(year, month - 1, day); // month is 0-based
      if (selectedDate > today) {
        setErrors("The selected date cannot be greater than today.");
      } else if (
        selectedDate.getDate() !== day ||
        selectedDate.getMonth() + 1 !== month ||
        selectedDate.getFullYear() !== year
      ) {
        setErrors("Invalid date selected.");
      } else {
        setErrors("");
      }
    }
  }, [formData.dob]);

  useEffect(() => {
    if (searchInput.length > 2) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchInput}.json?country=IN&types=place&access_token=${mapboxToken}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data.features);
        });
    } else {
      setSuggestions([]);
    }
  }, [searchInput]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      if (value.length > 50) {
        setErrors("Name should not exceed 50 characters");
        setFormData({
          ...formData,
          [name]: value.substring(0, 50),
        });
      } else {
        setErrors("");
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedLocation(suggestion);
    setFormData({
      ...formData,
      birth_place: suggestion.place_name,
    });
    setSuggestions([]);
    setSearchInput(suggestion.place_name);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const [day, month, year] = formData.dob.split("/").map(Number);
    const selectedDate = new Date(year, month - 1, day);

    if (!selectedLocation) {
      alert("Please select a valid birth place from the suggestions.");
      return;
    }

    // Prepare data for AstroVedic API
    const apiData = {
      name: formData.name,
      day,
      month,
      year,
      hour: formData.hour,
      min: formData.minute,
      lat: selectedLocation.center[1],
      lon: selectedLocation.center[0],
      tzone: 5.5,
    };

    console.log("api data",apiData);
    
    navigate("/kundli/details", { state: { apiData } });

  };
  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
     // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];

  return (
    <div className="mx-4 md:mx-20 my-4 lg:my-12 lg:mx-40">
       <div>
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>
<h1 className="font-bold text-sm md:text-xl lg:text-3xl mt-2 text-center text-red-700">
  Free Kundli Online
</h1>

<hr className="my-3 border-t-2 border-gray-300" />

<h1 className="text-[12px] md:text-xl lg:text-2xl text-[#616161] font-semibold my-2 mx-auto text-center">
  Free Kundli Online - Get Your Detailed Birth Chart with Predictions
</h1>

<p className="mt-6 text-justify text-sm md:text-base lg:text-lg mx-auto leading-relaxed text-gray-900">
  For a personalized astrological experience, visit 
  <a href="https://AstrologerDaddy.com" className="text-black px-2 hover:text-black font-semibold underline transition-all duration-300 ease-in-out">
    AstrologerDaddy.com
  </a> 
  to access your <span className="font-bold text-gray-900">Free Kundli Online</span>. A Kundli, or birth chart, is a detailed astrological 
  map based on the exact time, date, and place of your birth. It provides valuable insights into various aspects of your life, 
  including <span className="font-semibold text-gray-900">personality traits, career paths, relationships, health, and future opportunities</span>. 
  With the free Kundli service at AstrologerDaddy.com, you’ll receive an accurate, computer-generated birth chart that interprets 
  the positioning of celestial bodies and how they influence your life.
</p>

<p className="mt-6 text-justify text-sm md:text-base lg:text-lg mx-auto leading-relaxed text-gray-900">
  By understanding your <span className="font-bold text-gray-900">Kundli</span>, you can uncover the unique planetary influences that shape 
  your strengths, challenges, and life purpose. This can be particularly useful in making important life decisions, choosing the 
  right career path, or even understanding your compatibility with others. 
  <span className="font-semibold text-gray-9600"> Astrologer Daddy’s free Kundli service</span> also highlights significant life events and predicts 
  future trends, helping you prepare for opportunities or obstacles ahead.
</p>

<p className="mt-4 text-justify text-sm md:text-base lg:text-lg mx-auto leading-relaxed text-gray-900">
  The user-friendly platform ensures that anyone can easily generate their Kundli with just a few simple steps. 
  <span className="font-semibold text-black-600"> So, if you're looking to explore your astrological blueprint,</span> head over to 
  <a href="https://AstrologerDaddy.com" className="text-black px-2 hover:text-indigo-700 underline transition-all duration-300 ease-in-out">
    AstrologerDaddy.com
  </a> 
  now to get your <span className="font-bold text-gray-900">Free Kundli Online</span> and start gaining deeper insights into your life's journey.
</p>



      <div className="flex flex-col md:flex-row justify-between my-8">
        <div className="container p-4 max-w-full md:max-w-[45%] rounded-md shadow-xl border ">
          <form onSubmit={handleFormSubmit}>
            <div className="grid grid-cols-1 gap-4">
              {/* Name */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                  pattern="[A-Za-z\s]+"
                  title="Name should only contain letters."
                />
              </div>

              {/* Gender */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Gender<span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full cursor-pointer focus:outline-none p-2 border border-gray-300 rounded focus:ring-2 focus:ring-orange-500"
                  required
                  title="Please select your Gender"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* Date of Birth */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Date of Birth (dd/mm/yyyy)
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter DOB"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                  pattern="\d{2}/\d{2}/\d{4}"
                  title="Date should be in dd/mm/yyyy format."
                />
                {errors && <p className="text-red-500 mt-2">{errors}</p>}
              </div>

              {/* Time of Birth */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Time of Birth (HH:MM:SS)
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex space-x-4">
                  <select
                    name="hour"
                    value={formData.hour}
                    onChange={handleChange}
                    className="w-1/3 cursor-pointer p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Hour</option>
                    {hours.map((h) => (
                      <option key={h} value={h}>
                        {h}
                      </option>
                    ))}
                  </select>
                  <select
                    name="minute"
                    value={formData.minute}
                    onChange={handleChange}
                    className="w-1/3 cursor-pointer p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Minute</option>
                    {minutes.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                  <select
                    name="second"
                    value={formData.second}
                    onChange={handleChange}
                    className="w-1/3 cursor-pointer p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Second</option>
                    {seconds.map((s) => (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Birth Place */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Birth Place<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Birth Place"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <div className="absolute z-10 mt-1 w-full bg-white  rounded shadow-lg">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.place_name}
                    </div>
                  ))}
                </div>
              </div>

              <button
                type="submit"
                className="bg-orange-500 text-white font-bold py-2 rounded hover:bg-orange-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="hidden md:flex justify-center items-center max-w-[45%] mx-auto">
          <img src={kundali} alt="Kundali" className="rounded-lg shadow-lg" />
        </div>
      </div>
<div>
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>
    
    </div>
  );
};

export default Kundli;
