import React from "react";
import {faStar} from "react-icons/fa";
function PoojaDetails() {
    const testimonials = [
        {
            id: 1,
            name: "Nandini",
            date: "Jul 15, 2024",
            rating: 5,
            comment: "Very Nice Service 😊",
            avatar: "/api/placeholder/40/40",
        },
        {
            id: 2,
            name: "Ayushi",
            date: "Jul 8, 2024",
            rating: 5,
            comment: "🙏🙏🙏",
            avatar: "/api/placeholder/40/40",
        },
        {
            id: 3,
            name: "Surbhi",
            date: "Nov 7, 2024",
            rating: 5,
            comment: "gud",
            avatar: "/api/placeholder/40/40",
        },
        {
            id: 4,
            name: "SMIT",
            date: "Aug 23, 2024",
            rating: 5,
            comment: "Good",
            avatar: "/api/placeholder/40/40",
        },
    ];
    const StarRating = ({ rating }) => {
        return (
            <div className="flex">
                {[...Array(5)].map((_, index) => (
                    <faStar
                        key={index}
                        size={16}
                        className={`${index < rating
                                ? "fill-yellow-400 text-yellow-400"
                                : "text-gray-300"
                            }`}
                    />
                ))}
            </div>
        );
    };
    const poojaTiltle = [
        {
            title: "Hanuman",
            description:
                "Get RID of health issues and attract a healthy body and mind",
            time: "04 hours left",
        },
    ];
    const expert = [
        {
            image:
                "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/50x50/images/3621a626-fc46-4a7d-be93-4f3b6bbdbad9.jpg",
            name: "Saraswat",
            description:
                "With 11 years of experience and proficiency in Hindi, Punjabi, and Sanskrit, Vedic astrologer expert in Prashana provides clear guidance to help navigate life's challenges with wisdom and clarity.",
        },
    ];
    const benefit = [
        {
            title: "How will it happen",
            des: " Book the Pooja",
            des1: " Book the Pooja",
            des2: " Book the Pooja",
            des3: " Book the Pooja",
        },
    ];
    const happen = [
        {
            title: "How will it happen",
            des: " Book the Pooja",
            des1: " Book the Pooja",
            des2: " Book the Pooja",
            des3: " Book the Pooja",
        },
    ];
    const about = [
        {
            title: "About Hanuman Ji",
            des: "Known as Sankatmochan the remover of obstacles protecting devotees from challenges and offering strength",
            des1: "He's a master of many things like medicine, languages etc",
            des2: "Immortal, ensuring his continued presence and guidance for his devotees",
            des3: "Known for immense strength, loyalty and faith in Rama.",
        },
    ];
    const afterPooja = [
        {
            title: "What should you do after Pooja to get maximum benefits?",
            des: "Recite the important HanumanMantra: ",
            des1: "Make a heartfelt resolution for positive changes in your life",
            des2: "Do the Pooja every month to get maximum benefits",
        },
    ];
    const book = [
        {
            title: "Why book with AstrologerDaddy?",
            des: "Astrotalk is India's largest Devotion tech platform with over 15000 top astrologers and Pandits at your service.",
            des1: "The Pooja service has the best Pandits of the platform performing the Pooja to ensure maximum benefits for our users",
        },
    ];
    return (
        <div className="py-8 w-4/5 mx-auto">
            <div>
                <div className="flex flex-col sm:flex-row items-center justify-between bg-white lg:p-6 md:p-4  rounded-lg ">
                    {/* Image Section */}
                    <div className="w-full sm:w-1/2 mb-6 sm:mb-0">
                        <img
                            src="https://aws.astrotalk.com/images/5d766a82-3d8b-4fad-b0b9-598625c77bcb.jpg"
                            alt="Pooja"
                            className="w-full h-64 object-cover rounded-lg shadow-md"
                        />
                    </div>

                    {/* Text Section */}
                    <div className="w-full sm:w-1/2 sm:pl-6">
                        <h1 className="text-3xl font-semibold text-gray-800 mb-2">
                            {poojaTiltle[0].title}
                        </h1>
                        <p className="text-gray-600 text-lg mb-4">
                            {poojaTiltle[0].description}
                        </p>
                        <h2 className="text-yellow-600 font-medium text-lg mb-4">
                            {poojaTiltle[0].time}
                        </h2>

                        {/* Book Now Button */}
                        <button className="w-full sm:w-auto py-2 px-6 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300">
                            Book Now
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 py-8 lg:px-6 md:px-4  justify-start">
                    {/* Expert Card */}
                    {expert.map((exp, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <div className="flex items-center justify-between gap-3">
                                <img
                                    src={exp.image}
                                    alt={exp.name}
                                    className="w-16 h-16 rounded-full object-cover mb-4 border-4 border-yellow-500"
                                />
                                <p className="text-xl font-semibold text-gray-800 mb-2">
                                    {exp.name}
                                </p>
                            </div>
                            <p className="text-gray-600 text-sm ">{exp.description}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}

                    {/* Benefit Card */}
                    {benefit.map((ben, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {ben.title}
                            </h1>
                            <p className="text-gray-600 text-sm mb-2">{ben.des1}</p>
                            <p className="text-gray-600 text-sm mb-2">{ben.des2}</p>
                            <p className="text-gray-600 text-sm mb-2">{ben.des3}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}

                    {/* Happen Card */}
                    {happen.map((ben, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {ben.title}
                            </h1>
                            <p className="text-gray-600 text-sm mb-2">{ben.des1}</p>
                            <p className="text-gray-600 text-sm mb-2">{ben.des2}</p>
                            <p className="text-gray-600 text-sm mb-2">{ben.des3}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}

                    {/* About Card */}
                    {about.map((abt, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {abt.title}
                            </h1>
                            <p className="text-gray-600 text-sm mb-2">{abt.des1}</p>
                            <p className="text-gray-600 text-sm mb-2">{abt.des2}</p>
                            <p className="text-gray-600 text-sm mb-2">{abt.des3}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}

                    {/* After Pooja Card */}
                    {afterPooja.map((abt, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {abt.title}
                            </h1>
                            <p className="text-gray-600 text-sm mb-2">{abt.des1}</p>
                            <p className="text-gray-600 text-sm mb-2">{abt.des2}</p>
                            <p className="text-gray-600 text-sm mb-2">{abt.des}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}

                    {/* Book Card */}
                    {book.map((abt, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg flex flex-col items-start justify-start"
                        >
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {abt.title}
                            </h1>
                            <p className="text-gray-600 text-sm mb-2">{abt.des1}</p>
                            <p className="text-gray-600 text-sm mb-2">{abt.des}</p>
                            <hr className="w-full mt-6 border-t border-gray-300" />
                        </div>
                    ))}
                </div>

                {/* <div>
<h2 className="text-2xl font-bold px-3 mb-8 text-left text-gray-800">
                CUSTOMER TESTIMONIALS
            </h2>
            
            <div className="space-y-4">
                {testimonials.map((testimonial) => (
                    <div
                        key={testimonial.id}
                        className="bg-white rounded-lg w-1/2 shadow-sm p-4 border border-gray-100 hover:shadow-md transition-shadow duration-200"
                    >
                        <div className="flex items-start  space-x-4">
                            <img
                                src={testimonial.avatar}
                                alt={`${testimonial.name}'s avatar`}
                                className="w-10 h-10 rounded-full"
                            />
                            
                            <div className="flex-1">
                                <div className="flex items-center justify-between">
                                    <h3 className="font-medium text-gray-800">
                                        {testimonial.name}
                                    </h3>
                                    <span className="text-sm text-gray-500">
                                        {testimonial.date}
                                    </span>
                                </div>
                                
                                <div className="mt-1">
                                    <StarRating rating={testimonial.rating} />
                                </div>
                                
                                <p className="mt-2 text-gray-600">
                                    {testimonial.comment}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
</div> */}
            </div>
        </div>
    );
}

export default PoojaDetails;
