import React from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';

const AggregateMatch = ({data}) => {
  

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-orange-700">Aggregate Match Analysis</h2>
        <p className="text-slate-600">{data.bot_response}</p>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6">
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-slate-800">Scores</h3>
          <div className="flex justify-between">
            <div>
              <strong>Ashtakoot Score:</strong> {data.ashtakoot_score}
            </div>
            <div>
              <strong>Dashkoot Score:</strong> {data.dashkoot_score}
            </div>
          </div>
        </div>

        {/* Dosha Analysis */}
        <h3 className="text-xl font-semibold text-slate-800 mb-2">Dosha Analysis</h3>
        <div className="space-y-4">
          <div className={`flex items-center ${data.rajjudosh ? 'text-red-600' : 'text-green-600'}`}>
            {data.rajjudosh ? <AiOutlineCloseCircle /> : <AiOutlineCheckCircle />}
            <span className="ml-2">{data.rajjudosh ? "Rajju Dosha: Issues present" : "Rajju Dosha: No issues"}</span>
          </div>
          <div className={`flex items-center ${data.vedhadosh ? 'text-red-600' : 'text-green-600'}`}>
            {data.vedhadosh ? <AiOutlineCloseCircle /> : <AiOutlineCheckCircle />}
            <span className="ml-2">{data.vedhadosh ? "Vedha Dosha: Issues present" : "Vedha Dosha: No issues"}</span>
          </div>
          <div>
            <strong>Mangal Dosha:</strong> {data.mangaldosh} (Boy: {data.mangaldosh_points.boy}, Girl: {data.mangaldosh_points.girl})
          </div>
          <div>
            <strong>Pitra Dosha:</strong> {data.pitradosh} (Boy: {data.pitradosh_points.boy ? "Yes" : "No"}, Girl: {data.pitradosh_points.girl ? "Yes" : "No"})
          </div>
          <div>
            <strong>Kaal Sarp Dosha:</strong> {data.kaalsarpdosh} (Boy: {data.kaalsarp_points.boy ? "Yes" : "No"}, Girl: {data.kaalsarp_points.girl ? "Yes" : "No"})
          </div>
          <div>
            <strong>Manglik Dosha by Saturn:</strong> {data.manglikdosh_saturn} (Boy: {data.manglikdosh_saturn_points.boy ? "Yes" : "No"}, Girl: {data.manglikdosh_saturn_points.girl ? "Yes" : "No"})
          </div>
          <div>
            <strong>Manglik Dosha by Rahu and Ketu:</strong> {data.manglikdosh_rahuketu} (Boy: {data.manglikdosh_rahuketu_points.boy ? "Yes" : "No"}, Girl: {data.manglikdosh_rahuketu_points.girl ? "Yes" : "No"})
          </div>
        </div>

        {/* Extended Response */}
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-slate-800">Extended Response</h3>
          <p className="text-slate-600">{data.extended_response}</p>
        </div>
      </div>
    </div>
  );
}

export default AggregateMatch;
