import React from 'react';

function RajjuVedha({data}) {
  
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-red-700">Rajju and Vedha Dosha Analysis</h2>
        <p className="text-slate-600">Analysis of Rajju and Vedha dosha based on compatibility</p>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6">
        <h3 className="text-xl font-semibold text-slate-800 mb-4">Dosha Summary</h3>
        
        <div className="mb-4">
          <h4 className="text-lg font-semibold">Rajju Dosha</h4>
          <p className={`text-base ${data.is_rajju_dosha_present ? 'text-red-600' : 'text-green-600'}`}>
            {data.is_rajju_dosha_present ? "Rajju dosha is present." : "Rajju dosha is not present."}
          </p>
          <p className="text-gray-700">Affected Part: <strong>{data.rajju_part}</strong></p>
          <p className="mt-2">{data.rajju_description}</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold">Vedha Dosha</h4>
          <p className={`text-base ${data.is_vedha_dosha_present ? 'text-red-600' : 'text-green-600'}`}>
            {data.is_vedha_dosha_present ? "Vedha dosha is present." : "Vedha dosha is not present."}
          </p>
          <p className="text-gray-700">Note: Please consult an astrologer for detailed insights.</p>
        </div>
      </div>
    </div>
  );
}

export default RajjuVedha;
