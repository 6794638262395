import {memo} from 'react'

const MahaDashaPredictionsSection = memo(({ predictions }) => {
  if (!predictions?.dashas) return null;

  return (
    <div className="bg-white py-6 md:px-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-red-700 mb-6">Maha Dasha Predictions</h2>
      <div className="space-y-8">
        {predictions.dashas.map((dasha, index) => (
          <div key={index} className="p-4 border rounded-lg hover:bg-gray-50">
            <h3 className="text-xl font-semibold mb-2">
              {dasha.dasha} Period ({dasha.dasha_start_year} to{" "}
              {dasha.dasha_end_year})
            </h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-semibold text-gray-700 mb-1">Prediction</h4>
                <p className="text-gray-600">{dasha.prediction}</p>
              </div>
              <div>
                <h4 className="font-semibold text-gray-700 mb-1">
                  Planet in Zodiac
                </h4>
                <p className="text-gray-600">{dasha.planet_in_zodiac}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});


export default MahaDashaPredictionsSection