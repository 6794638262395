import React from 'react';
import { AiOutlineStar } from 'react-icons/ai';

function PapasamayaMatch({ data }) {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold  text-red-700 mb-2">Papasamaya Match Analysis</h2>
        <p className="text-slate-600 max-w-2xl mx-auto">{data.bot_response}</p>
      </div>

      <div className="p-6 shadow">
  <h3 className="text-2xl font-semibold text-gray-800 mb-4">Compatibility Scores</h3>

  <div className="overflow-x-auto">
    <table className="min-w-full table-auto bg-white rounded-lg shadow">
      <thead>
        <tr className="bg-gray-200">
          <th className="px-6 py-4 text-left text-lg font-bold text-gray-800">Criteria</th>
          <th className="px-6 py-4 text-center text-lg font-bold text-gray-800">Boy's Scores</th>
          <th className="px-6 py-4 text-center text-lg font-bold text-gray-800">Girl's Scores</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        <tr>
          <td className="px-6 py-4 text-gray-700 font-medium">Rahu</td>
          <td className="px-6 py-4 text-center">{data.boy_papa.rahu_papa}</td>
          <td className="px-6 py-4 text-center">{data.girl_papa.rahu_papa}</td>
        </tr>
        <tr>
          <td className="px-6 py-4 text-gray-700 font-medium">Sun</td>
          <td className="px-6 py-4 text-center">{data.boy_papa.sun_papa}</td>
          <td className="px-6 py-4 text-center">{data.girl_papa.sun_papa}</td>
        </tr>
        <tr>
          <td className="px-6 py-4 text-gray-700 font-medium">Saturn</td>
          <td className="px-6 py-4 text-center">{data.boy_papa.saturn_papa}</td>
          <td className="px-6 py-4 text-center">{data.girl_papa.saturn_papa}</td>
        </tr>
        <tr>
          <td className="px-6 py-4 text-gray-700 font-medium">Mars</td>
          <td className="px-6 py-4 text-center">{data.boy_papa.mars_papa}</td>
          <td className="px-6 py-4 text-center">{data.girl_papa.mars_papa}</td>
        </tr>
        <tr className="bg-gray-100">
          <td className="px-6 py-4 text-gray-900 font-bold">Total</td>
          <td className="px-6 py-4 text-center font-bold">{data.boy_total}</td>
          <td className="px-6 py-4 text-center font-bold">{data.girl_total}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div className="mt-8 text-center">
  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-50 text-blue-600 mb-4">
              <AiOutlineStar size={32} />
            </div>
  <h3 className="text-2xl font-semibold text-slate-900 mb-2">
    Overall Score: {data.score}
  </h3>
  <p className="text-slate-600 max-w-2xl mx-auto">
    {data.bot_response}
  </p>
</div>


</div>

    </div>
  );
}

export default PapasamayaMatch;
