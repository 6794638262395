import React from 'react';
import { AiOutlineStar, AiOutlineUser } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';

const Dashakoot = ({ data }) => {
  const renderTableRow = (criteria, boyData, girlData, score, total, description) => (
    <tr key={criteria} className="hover:bg-slate-50 transition-colors">
      <td className="border border-slate-200 px-4 py-3 ">{criteria}</td>
      <td className="border border-slate-200 px-4 py-3">{boyData}</td>
      <td className="border border-slate-200 px-4 py-3">{girlData}</td>
      <td className="border border-slate-200 px-4 py-3 text-center font-semibold">
        <span className="inline-flex items-center justify-center bg-blue-50 text-blue-700 rounded-full w-8 h-8">
          {score}
        </span>
      </td>
      <td className="border border-slate-200 px-4 py-3 text-center font-semibold">
        <span className="inline-flex items-center justify-center bg-blue-50 text-blue-700 rounded-full w-8 h-8">
          {total}
        </span>
      </td>
      <td className="border border-slate-200 px-4 py-3 text-slate-600">{description}</td>
    </tr>
  );

  const renderAstroDetails = (title, details, icon, colorScheme) => (
    <div className="mt-8 bg-white rounded-lg shadow-sm border border-slate-200 overflow-hidden">
      <div className={`flex items-center gap-3 p-4 ${colorScheme === 'blue' ? 'bg-blue-50' : 'bg-pink-50'}`}>
        <div className={`p-2 rounded-full ${colorScheme === 'blue' ? 'bg-blue-100' : 'bg-pink-100'}`}>
          {icon}
        </div>
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>

      <div className="divide-y divide-slate-200">
        {Object.entries(details).map(([key, value], index) => (
          <div
            key={key}
            className={`grid grid-cols-2  ${index % 2 === 0 ? 'bg-slate-50' : 'bg-white'}`}
          >
            <div className="w-full sm:w-1/3 p-4 font-medium text-slate-700 bg-slate-100 sm:bg-transparent">
              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </div>
            <div className="w-full sm:w-2/3 p-4 text-slate-600">
              {Array.isArray(value) ? (
                <div className="flex flex-wrap gap-2">
                  {value.map((item, i) => (
                    <span
                      key={i}
                      className={`px-2.5 py-1 rounded-md text-sm
                        ${colorScheme === 'blue'
                          ? 'bg-blue-50 text-blue-700'
                          : 'bg-pink-50 text-pink-700'
                        }`}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              ) : (
                <span
                  className={`px-2.5 py-1 rounded-md text-sm
                    ${colorScheme === 'blue'
                      ? 'bg-blue-50 text-blue-700'
                      : 'bg-pink-50 text-pink-700'
                    }`}
                >
                  {value}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPlanetaryDetails = (title, details, icon) => (
    <div className="mt-8 p-6 bg-white rounded-lg shadow-sm border border-slate-200 overflow-x-auto">
      <div className="flex items-center gap-2 mb-4">
        {icon}
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Name</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Full Name</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Zodiac</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">House</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Nakshatra</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Nakshatra Lord</th>
            <th className="border-b border-slate-300 px-6 py-4 text-left text-lg font-bold text-slate-900">Nakshatra Pada</th>
          </tr>
        </thead>

        <tbody className="divide-y divide-slate-200">
          {Object.values(details).map((planet, index) => (
            <tr key={index} className="hover:bg-slate-50 transition-colors">
              <td className="px-4 py-3">{planet.name}</td>
              <td className="px-4 py-3">{planet.full_name}</td>
              <td className="px-4 py-3">{planet.zodiac}</td>
              <td className="px-4 py-3">{planet.house}</td>
              <td className="px-4 py-3">{planet.nakshatra}</td>
              <td className="px-4 py-3">{planet.nakshatra_lord}</td>
              <td className="px-4 py-3">{planet.nakshatra_pada}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {data && (
        <div className="space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-red-700 mb-2">Dashakoot Analysis</h2>
            <p className="text-slate-600 max-w-2xl mx-auto">
              A comprehensive analysis of compatibility based on ten different aspects of Vedic astrology
            </p>
          </div>

          {Object.keys(data).length > 0 ? (
            <div className="bg-white rounded-lg shadow-sm border border-slate-200 overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border-b border-gray-300 px-6 py-4 text-left text-lg font-bold text-gray-900">Criteria</th>
                    <th className="border-b border-gray-300 px-6 py-4 text-left text-lg font-bold text-gray-900">Boy</th>
                    <th className="border-b border-gray-300 px-6 py-4 text-left text-lg font-bold text-gray-900">Girl</th>
                    <th className="border-b border-gray-300 px-6 py-4 text-center text-lg font-bold text-gray-900">Score</th>
                    <th className="border-b border-gray-300 px-6 py-4 text-center text-lg font-bold text-gray-900">Total</th>
                    <th className="border-b border-gray-300 px-6 py-4 text-left text-lg font-bold text-gray-900">Description</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-slate-200">
                  {renderTableRow(
                    'Dina',
                    data.dina.boy_star,
                    data.dina.girl_star,
                    data.dina.dina,
                    data.dina.full_score,
                    data.dina.description
                  )}
                  {renderTableRow(
                    'Gana',
                    data.gana.boy_gana,
                    data.gana.girl_gana,
                    data.gana.gana,
                    data.gana.full_score,
                    data.gana.description
                  )}
                  {renderTableRow(
                    'Mahendra',
                    data.mahendra.boy_star,
                    data.mahendra.girl_star,
                    data.mahendra.mahendra,
                    data.mahendra.full_score,
                    data.mahendra.description
                  )}
                  {renderTableRow(
                    'Sthree',
                    data.sthree.boy_star,
                    data.sthree.girl_star,
                    data.sthree.sthree,
                    data.sthree.full_score,
                    data.sthree.description
                  )}
                  {renderTableRow(
                    'Yoni',
                    data.yoni.boy_yoni,
                    data.yoni.girl_yoni,
                    data.yoni.yoni,
                    data.yoni.full_score,
                    data.yoni.description
                  )}
                  {renderTableRow(
                    'Rasi',
                    data.rasi.boy_rasi,
                    data.rasi.girl_rasi,
                    data.rasi.rasi,
                    data.rasi.full_score,
                    data.rasi.description
                  )}
                  {renderTableRow(
                    'Rasiathi',
                    data.rasiathi.boy_lord,
                    data.rasiathi.girl_lord,
                    data.rasiathi.rasiathi,
                    data.rasiathi.full_score,
                    data.rasiathi.description
                  )}
                  {renderTableRow(
                    'Vasya',
                    data.vasya.boy_rasi,
                    data.vasya.girl_rasi,
                    data.vasya.vasya,
                    data.vasya.full_score,
                    data.vasya.description
                  )}
                  {renderTableRow(
                    'Rajju',
                    data.rajju.boy_rajju,
                    data.rajju.girl_rajju,
                    data.rajju.rajju,
                    data.rajju.full_score,
                    data.rajju.description
                  )}
                  {renderTableRow(
                    'Vedha',
                    data.vedha.boy_star,
                    data.vedha.girl_star,
                    data.vedha.vedha,
                    data.vedha.full_score,
                    data.vedha.description
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-8 text-slate-600">No data available for Dashakoot.</div>
          )}

          <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6 text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-50 text-blue-600 mb-4">
              <AiOutlineStar size={32} />
            </div>
            <h3 className="text-2xl font-semibold text-slate-900 mb-2">Overall Score: {data.score}</h3>
            <p className="text-slate-600 max-w-2xl mx-auto">{data.bot_response}</p>
          </div>

          {renderPlanetaryDetails('Boy Planetary Details', data.boy_planetary_details,
            <AiOutlineUser className="w-6 h-6 text-blue-600" />
          )}
          {renderPlanetaryDetails('Girl Planetary Details', data.girl_planetary_details,
            <AiOutlineUser className="w-6 h-6 text-pink-600" />
          )}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {renderAstroDetails(
              'Boy Astro Details',
              data.boy_astro_details,
              <FaHeart className="w-5 h-5 text-blue-600" />,
              'blue'
            )}
            {renderAstroDetails(
              'Girl Astro Details',
              data.girl_astro_details,
              <FaHeart className="w-5 h-5 text-pink-600" />,
              'pink'
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashakoot;