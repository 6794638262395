


import React, { memo } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const YoginiDashaMain = memo(({ yoginiDashaMainData }) => {
  if (!yoginiDashaMainData) return null;

  const { dasha_list, dasha_end_dates, dasha_lord_list } = yoginiDashaMainData;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg min-w-fit mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Yogini Dasha Details</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-gray-50 rounded-lg shadow-md">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left text-gray-600">Dasha Name</th>
              <th className="py-3 px-4 text-left text-gray-600">End Date</th>
              <th className="py-3 px-4 text-left text-gray-600">Dasha Lord</th>
            </tr>
          </thead>
          <tbody>
            {dasha_list.map((dasha, index) => (
              <tr key={index} className="hover:bg-gray-100 transition-colors duration-150">
                <td className="py-3 px-4 border-b text-gray-800">{dasha}</td>
                <td className="py-3 px-4 border-b text-gray-800 flex items-center text-nowrap">
                  <FaCalendarAlt className="mr-2 text-gray-500" />
                  {formatDate(dasha_end_dates[index])}
                </td>
                <td className="py-3 px-4 border-b text-gray-800">{dasha_lord_list[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default YoginiDashaMain;
