import { memo } from "react";

const TableHeader = ({ title }) => (
  <tr className="bg-gray-100">
    <th className="py-2 px-4 border-b text-gray-700 text-left" colSpan="2">
      {title}
    </th>
  </tr>
);

// Memoized Table Row Component
const TableRow = memo(({ label, value }) => (
  <tr className="hover:bg-gray-50">
    <td className="py-2 px-4 border-b font-semibold text-center">{label}</td>
    <td className="py-2 px-4 border-b text-center">{value}</td>
  </tr>
));

const ExtendedKundliDetails = memo(({extendedKundliDetailsData}) => {
  if (!extendedKundliDetailsData || Object.keys(extendedKundliDetailsData).length === 0) {
    return null;
  }

  const entries = Object.entries(extendedKundliDetailsData);

 
  return (
    <div className="bg-white p-4 sm:p-6 mt-4 rounded-lg shadow-md border max-w-6xl mx-auto border-gray-200">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-gray-900">
        Extended Kundli Details
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[0, 1].map((columnIndex) => (
          <table
            key={columnIndex}
            className="min-w-full border-collapse bg-white shadow-md rounded-lg overflow-hidden"
          >
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b text-sm sm:text-base text-gray-700">
                  Attribute
                </th>
                <th className="py-2 px-4 border-b text-sm sm:text-base text-gray-700">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {entries
                .filter((_, index) => index % 2 === columnIndex)
                .map(([key, value]) => (
                  <TableRow
                    key={key}
                    label={key
                      .replace("_", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                    value={value}
                  />
                ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
  });

const BasicPlanetDetailsTables = ({basicPlanetDetailsData}) => {
  if (!basicPlanetDetailsData ||basicPlanetDetailsData==="out of api calls - renew subscription"|| Object.keys(basicPlanetDetailsData).length === 0) {
    return null;
  }
  console.log("basicPlanetDetailsData", basicPlanetDetailsData);
  
  const {  ghatka_chakra, panchang } = basicPlanetDetailsData;
  const {lucky_gem,lucky_num,lucky_colors,lucky_letters,lucky_name_start} = basicPlanetDetailsData;
  const ghatkaData = ghatka_chakra;
  const panchangData = panchang;
  const renderLuckTable = () => (
    <table className="bg-white p-6 mt-4 rounded-lg shadow-md border max-w-6xl mx-auto border-gray-200">
      <thead>
        <TableHeader title="Luck Details" />
      </thead>
      <tbody>
        <TableRow label="Lucky Gem" value={lucky_gem} />
        <TableRow label="Lucky Number" value={lucky_num} />
        <TableRow label="Lucky Colors" value={lucky_colors} />
        <TableRow label="Lucky Letters" value={lucky_letters} />
        <TableRow label="Lucky Name Start" value={lucky_name_start} />
      </tbody>
    </table>
  );

  const renderGhatkaTable = () => (
    <table className="bg-white p-6 mt-4 rounded-lg shadow-md border max-w-6xl mx-auto border-gray-200">
      <thead>
        <TableHeader title="Ghatka Details" />
      </thead>
      <tbody>
        <TableRow label="Rasi" value={ghatkaData.rasi} />
        <TableRow label="Tithi" value={ghatkaData.tithi} />
        <TableRow label="Day" value={ghatkaData.day} />
        <TableRow label="Nakshatra" value={ghatkaData.nakshatra} />
        <TableRow label="Tatva" value={ghatkaData.tatva} />
        <TableRow label="Lord" value={ghatkaData.lord} />
        <TableRow label="Same Sex Lagna" value={ghatkaData.same_sex_lagna} />
        <TableRow label="Opposite Sex Lagna" value={ghatkaData.opposite_sex_lagna} />
      </tbody>
    </table>
  );

  const renderPanchangTable = () => (
    <table className="bg-white p-6 mt-4 rounded-lg shadow-md border max-w-6xl mx-auto border-gray-200">
      <thead>
        <TableHeader title="Panchang Details" />
      </thead>
      <tbody>
        <TableRow label="Ayanamsa" value={panchangData.ayanamsa.toFixed(2)} />
        <TableRow label="Ayanamsa Name" value={panchangData.ayanamsa_name} />
        <TableRow label="Karana" value={panchangData.karana} />
        <TableRow label="Yoga" value={panchangData.yoga} />
        <TableRow label="Day of Birth" value={panchangData.day_of_birth} />
        <TableRow label="Day Lord" value={panchangData.day_lord} />
        <TableRow label="Hora Lord" value={panchangData.hora_lord} />
        <TableRow label="Sunrise at Birth" value={panchangData.sunrise_at_birth} />
        <TableRow label="Sunset at Birth" value={panchangData.sunset_at_birth} />
        <TableRow label="Tithi" value={panchangData.tithi} />
      </tbody>
    </table>
  );

  return (
    <div className="bg-white p-6 mt-4 rounded-lg shadow-md border max-w-6xl mx-auto border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="shadow-sm rounded-lg overflow-hidden">
          {renderLuckTable()}
        </div>
        <div className="shadow-sm rounded-lg overflow-hidden">
          {renderGhatkaTable()}
        </div>
        <div className="shadow-sm rounded-lg overflow-hidden">
          {renderPanchangTable()}
        </div>
      </div>
    </div>
  );
};

// Memoized Basic Details Section
const BasicDetailsSection = memo(({ apiData, extendedKundliDetailsData,basicPlanetDetailsData }) => {
  if(!apiData || Object.keys(apiData).length === 0 || !extendedKundliDetailsData || Object.keys(extendedKundliDetailsData).length === 0 || !basicPlanetDetailsData || Object.keys(basicPlanetDetailsData).length === 0) {
    return null;
  }
  return (<>
    <div className="bg-white p-6 rounded-lg shadow-md  max-w-6xl mx-auto">
      <h2 className="text-2xl text-red-700 font-bold mb-6">Basic Details</h2>
      <table className="min-w-full">
        <tbody>
          <TableRow label="Name" value={apiData.name} />
          <TableRow
            label="Date of Birth"
            value={`${apiData.day}/${apiData.month}/${apiData.year}`}
          />
          <TableRow
            label="Time of Birth"
            value={`${apiData.hour}:${apiData.min}`}
          />
          <TableRow label="Latitude" value={apiData.lat} />
          <TableRow label="Longitude" value={apiData.lon} />
          <TableRow label="Timezone" value={apiData.tzone} />
        </tbody>
      </table>
    </div>

    {
      extendedKundliDetailsData &&
      <ExtendedKundliDetails
      extendedKundliDetailsData={extendedKundliDetailsData}
    />}

    {
      basicPlanetDetailsData &&
      <BasicPlanetDetailsTables
      basicPlanetDetailsData={basicPlanetDetailsData}
    />}
  </>)
});

export default BasicDetailsSection;
