import React, { memo } from 'react';
import { FaRedo } from 'react-icons/fa';

const Retrogrades = memo(({ data, selectedPlanet, onPlanetChange, planets }) => {


  console.log(selectedPlanet);


  const renderDetailCard = (title, details, icon) => (
    <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6 mb-6">
      <div className="flex items-center gap-2 mb-4">
        {icon}
        <h3 className="text-xl font-semibold text-slate-800">{title}</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(details).map(([key, value]) => (
          value && (
            <div key={key} className="p-4 bg-slate-50 rounded-lg">
              <div className="font-medium text-slate-700 mb-1">
                {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </div>
              <div className="text-slate-600">
                {Array.isArray(value) ? (
                  value.map((date, index) => (
                    <div key={index} className="space-y-2">
                      {Array.isArray(date) ? (
                        date.map((subDate, subIndex) => (
                          <div key={subIndex} className="flex justify-between">
                            <span>{subDate}</span>
                          </div>
                        ))
                      ) : (
                        date && <div>{date}</div>
                      )}
                    </div>
                  ))
                ) : (
                  value
                )}
              </div>
            </div>
          )
        ))}
      </div>

    </div>
  );

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-slate-900 mb-2">Retrogrades</h2>
        <p className="text-slate-600">Astrological Retrograde Periods</p>
      </div>

      <div className="max-w-md mx-auto mb-8">
        <select
          value={selectedPlanet}
          onChange={onPlanetChange}
          className="w-full px-4 py-2 border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Select a Planet</option>
          {planets.map(planet => (
            <option key={planet} value={planet} selected={selectedPlanet === planet}>
              {planet}
            </option>
          ))}
        </select>
      </div>

      {data && (
        <>
          {renderDetailCard('Retrograde Details',
            {
              bot_response: data.bot_response,
              dates: data?.dates
            },
            <FaRedo className="w-6 h-6 text-red-600" />
          )}
        </>
      )}
    </div>
  );
});

export default Retrogrades;