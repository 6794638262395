
import React, { memo } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const YoginiDashaSub = memo(({ yoginiDashaSubData }) => {
  if (!yoginiDashaSubData || yoginiDashaSubData.length === 0) return null;

  // Function to format a date string into 'MM/dd/yyyy' format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="bg-white py-8 px-4 md:px-8 rounded-lg shadow-lg min-w-fit mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Yogini Dasha Details</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {yoginiDashaSubData.map((dasha, index) => (
          <div key={index} className="bg-gray-50 rounded-lg py-6 shadow-md transition-all duration-300 hover:shadow-lg">
            <h3 className="text-xl text-center font-semibold mb-4 text-gray-700">
              {dasha.main_dasha} (Lord: {dasha.main_dasha_lord} (Start date: {formatDate(dasha.sub_dasha_start_dates)}))
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-3 px-4 text-left text-gray-600">Sub Dasha</th>
                    <th className="py-3 px-4 text-left text-gray-600">End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {dasha.sub_dasha_list.map((subDasha, subIndex) => (
                    <tr key={subIndex} className="hover:bg-gray-100 transition-colors duration-150">
                      <td className="py-3 px-4 border-b text-gray-800">{subDasha}</td>
                      <td className="py-3 px-4 border-b text-gray-800 flex items-center text-nowrap">
                        <FaCalendarAlt className="mr-2 text-gray-500" />
                        {dasha.sub_dasha_end_dates[subIndex]
                          ? formatDate(dasha.sub_dasha_end_dates[subIndex])
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default YoginiDashaSub;
