

import React, { memo } from 'react';

const CharDashaSubSection = memo(({ charDashaSubData }) => {
  if (!charDashaSubData) return null;
  const dataArray = Array.isArray(charDashaSubData) ? charDashaSubData : [];

  if (dataArray.length === 0) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-center text-red-700">Char Dasha Sub Section</h2>
        <p className="text-center text-gray-600">No data available</p>
      </div>
    );
  }

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-fit max-w-7xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Char Dasha Sub Section</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {dataArray.map(({ main_dasha, main_dasha_lord, sub_dasha_list, sub_dasha_end_dates }, index) => (
          <div key={`${main_dasha}-${index}`} className="bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
            <h3 className="text-xl text-center font-semibold mb-4 text-gray-700">
              {main_dasha} ({main_dasha_lord})
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-3 px-4 text-left text-gray-600">Sub Dasha</th>
                    <th className="py-3 px-4 text-left text-gray-600">End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {sub_dasha_list?.map((subDasha, subIndex) => (
                    <tr key={`${subDasha}-${subIndex}`} className="hover:bg-gray-100 transition-colors duration-150">
                      <td className="py-3 px-4 border-b text-gray-800">{subDasha}</td>
                      <td className="py-3 px-4 border-b text-gray-800">
                        {sub_dasha_end_dates?.[subIndex] 
                          ? new Date(sub_dasha_end_dates[subIndex]).toLocaleDateString() 
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default CharDashaSubSection;
