import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaTransgender,
  FaEdit,
  FaSave,
  FaMapMarkerAlt,
  FaClock,
  FaTrash
} from "react-icons/fa";
import api from "../api";
import dayjs from "dayjs";
import { useAuth } from "../contexts/AuthContext";
import useValidation from "../hooks/useValidation";

function Profile() {
  const { token } = useAuth();

  const [profile, setProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [initialProfile, setInitialProfile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const { validateProfile } = useValidation(profile);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get(`/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfile(response.data);
        setInitialProfile(response.data); // Save initial profile state
      } catch (error) {
        setError("Failed to fetch profile. Please try again later.");
        console.error("Error fetching profile:", error);
      }
    };
    fetchProfile();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      // Ensure date is formatted correctly before setting state
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: dayjs(value).format("YYYY-MM-DD"),
      }));
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  };

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleSaveChanges = async () => {
    const formData = new FormData();
    let hasChanges = false;

    // Append only changed fields
    const fieldsToCheck = [
      "firstName",
      "lastName",
      "email",
      "mobileNumber",
      "dob",
      "gender",
      "birthPlace",
      "birthTime",
    ];

    fieldsToCheck.forEach((field) => {
      if (profile[field] !== initialProfile[field]) {
        formData.append(field, profile[field]);
        hasChanges = true;
      }
    });

    if (profileImage) {
      formData.append("profileImage", profileImage);
      hasChanges = true;
    }

    if (!hasChanges) {
      setError("No changes to update.");
      setIsLoading(false);
      return;
    }

    try {
      setError("");
      setSuccess("");
      setIsLoading(true);
      await api.patch(`/users/profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess("Profile updated successfully.");
      setInitialProfile(profile); // Update initialProfile state
      setIsEditing(false);
      setIsLoading(false);
    } catch (error) {
      setError("Failed to update profile. Please try again later.");
      console.error("Error updating profile:", error);
      setIsLoading(false);
    }
  };

  const handleDeleteProfile = async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        setIsLoading(true);
        setError("");
        await api.delete(`/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion - you might want to redirect or clear auth context
        setSuccess("Profile deleted successfully");
        // Add any necessary cleanup or redirect logic here
      } catch (error) {
        setError("Failed to delete profile. Please try again later.");
        console.error("Error deleting profile:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!profile)
    return <div className="text-center text-gray-600 min-h-36">Loading...</div>;

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 max-w-4xl">
      <div className="bg-white shadow-lg rounded-lg p-4 sm:p-6 lg:p-8 border border-gray-200">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 border-b border-gray-300 pb-2 sm:pb-3">
          User Profile
        </h1>
        <div className="flex flex-col gap-4">
          <div className="flex-1 flex flex-col items-center">
            <img
              src={
                profile.profileImage
                  ? `https://astrologerdaddy.com/api/${profile.profileImage}`
                  : "/default-profile.png"
              }
              alt={profile.firstName}
              className="w-40 h-40 sm:w-48 sm:h-48 md:w-64 md:h-64 object-cover mx-auto rounded-full border border-gray-300"
            />
            {isEditing && (
              <input
                type="file"
                accept="image/*"
                onChange={handleProfileImageChange}
                className="mt-4 bg-white border border-gray-300 rounded p-2 cursor-pointer"
              />
            )}
          </div>
          <div className="flex-1">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Personal Information
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                {
                  icon: <FaUser className="text-gray-600" />,
                  label: "First Name",
                  name: "firstName",
                  type: "text",
                  value: profile.firstName,
                },
                {
                  icon: <FaUser className="text-gray-600" />,
                  label: "Last Name",
                  name: "lastName",
                  type: "text",
                  value: profile.lastName,
                },
                {
                  icon: <FaEnvelope className="text-gray-600" />,
                  label: "Email",
                  name: "email",
                  type: "email",
                  value: profile.email,
                },
                {
                  icon: <FaPhone className="text-gray-600" />,
                  label: "Mobile Number",
                  name: "mobileNumber",
                  type: "text",
                  value: profile.mobileNumber,
                  disabled: true,
                },
                {
                  icon: <FaCalendarAlt className="text-gray-600" />,
                  label: "Date of Birth",
                  name: "dob",
                  type: "date",
                  value: dayjs(profile.dob).format("YYYY-MM-DD"),
                },
                {
                  icon: <FaTransgender className="text-gray-600" />,
                  label: "Gender",
                  name: "gender",
                  type: "select",
                  value: profile.gender,
                  options: ["Male", "Female", "Other"],
                },
                {
                  icon: <FaMapMarkerAlt className="text-gray-600" />,
                  label: "Birth Place",
                  name: "birthPlace",
                  type: "text",
                  value: profile.birthPlace,
                },
                {
                  icon: <FaClock className="text-gray-600" />,
                  label: "Birth Time",
                  name: "birthTime",
                  type: "time",
                  value: profile.birthTime,
                },
              ].map(({ icon, label, name, type, value, disabled, options }) => (
                <div key={name} className="mb-4">
                  <label className="flex items-center mb-2 text-lg font-medium">
                    {icon && <span className="mr-3">{icon}</span>}
                    {label}:
                  </label>
                  {isEditing ? (
                    type === "select" ? (
                      <select
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        className="w-full border rounded p-3"
                      >
                        {options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={type}
                        name={name}
                        value={
                          name === "dob"
                            ? dayjs(value).format("YYYY-MM-DD")
                            : value
                        }
                        onChange={handleInputChange}
                        className="w-full border rounded p-3"
                        disabled={disabled}
                      />
                    )
                  ) : (
                    <p className="text-gray-700">
                      {name === "dob"
                        ? dayjs(value).format("DD/MM/YYYY")
                        : value}
                    </p>
                  )}
                  {validationErrors[name] && (
                    <div className="text-red-500">{validationErrors[name]}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => {
                setIsEditing(!isEditing);
                setError("");
                setSuccess("");
              }}
              className={`px-4 py-2 text-sm sm:text-base rounded text-white ${isEditing ? "bg-red-600" : "bg-orange-600"
                } hover:bg-opacity-80`}
            >
              <FaEdit className="inline mr-2" /> {isEditing ? "Cancel" : "Edit"}
            </button>

            {isEditing && (
              <button
                onClick={handleSaveChanges}
                className="px-4 py-2 text-sm sm:text-base bg-orange-600 text-white rounded hover:bg-opacity-80"
              >
                <FaSave className="inline mr-2" />{" "}
                {isLoading ? "Saving..." : "Save Changes"}
              </button>
            )}

            <button
              onClick={handleDeleteProfile}
              className="px-4 py-2 text-sm sm:text-base bg-red-600 text-white rounded hover:bg-opacity-80"
              disabled={isLoading}
            >
              <FaTrash className="inline mr-2" /> Delete Profile
            </button>
          </div>

          {error && (
            <div className="text-red-600 mt-4 text-center">{error}</div>
          )}
          {success && (
            <div className="text-green-600 mt-4 text-center">{success}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
