
import { memo } from "react";

const PLANETS = [
  "Ascendant",
  "Sun",
  "Moon",
  "Mars",
  "Mercury",
  "Jupiter",
  "Venus",
  "Saturn",
  "Rahu",
  "Ketu",
];

const TableRow = memo(({ planet, details }) => (
  <tr className="hover:bg-gray-50">
    <td className="py-2 px-4 border-b font-semibold text-gray-800">{planet}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.zodiac}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.house}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.nakshatra}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.nakshatra_lord}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.local_degree.toFixed(2)}°</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.is_combust ? "Yes" : "No"}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.basic_avastha}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.lord_status}</td>
    <td className="py-2 px-4 border-b text-gray-600">{details.zodiac_lord}</td>
  </tr>
));

const PlanetDetailsSection = memo(({ planetDetails }) => {
  if (!planetDetails || Object.keys(planetDetails).length === 0) return null;

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-gray-200">
      <h2 className="text-2xl font-bold mb-4 sm:mb-6 text-red-700">Planet Details</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="py-2 px-4 border-b text-gray-700">Planet</th>
              <th className="py-2 px-4 border-b text-gray-700">Zodiac</th>
              <th className="py-2 px-4 border-b text-gray-700">House</th>
              <th className="py-2 px-4 border-b text-gray-700">Nakshatra</th>
              <th className="py-2 px-4 border-b text-gray-700">Lord</th>
              <th className="py-2 px-4 border-b text-gray-700">Degree</th>
              <th className="py-2 px-4 border-b text-gray-700">Combust</th>
              <th className="py-2 px-4 border-b text-gray-700">Avastha</th>
              <th className="py-2 px-4 border-b text-gray-700">Lord Status</th>
              <th className="py-2 px-4 border-b text-gray-700">Zodiac Lord</th>
            </tr>
          </thead>
          <tbody>
            {PLANETS.map((planet, index) => {
              const details = planetDetails[index];
              return (
                <TableRow
                  key={planet}
                  planet={planet}
                  details={details}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default PlanetDetailsSection;
