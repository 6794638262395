import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
function Gemstone() {
  const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    hour: "",
    minute: "",
    second: "",
    birth_place: "",
  });

  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const today = new Date();
  const hours = Array.from({ length: 24 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const days = Array.from({ length: 31 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const years = Array.from({ length: today.getFullYear() - 1900 + 1 }, (_, i) =>
    String(1900 + i)
  );

  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [gemstone, setgemstonedata] = useState({});
  const [rudraksh, setruraksh] = useState({});

  const mapboxToken =
    "pk.eyJ1IjoiYXN0cm9sb2dlcmRhZGR5IiwiYSI6ImNtMmE0aDFkZzBjcG0ybXE0ODFlcXF2ZmUifQ.NjJNQIDHyhlFVPm3l7bsCg";

  useEffect(() => {
    if (searchInput.length > 2) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchInput}.json?country=IN&types=place&access_token=${mapboxToken}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data.features);
        });
    } else {
      setSuggestions([]);
    }
  }, [searchInput]);

  const validateDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() == year &&
      date.getMonth() == month - 1 &&
      date.getDate() == day
    );
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };

    // Clear previous errors
    setErrors("");

    // Validate the date if all fields are filled
    if (newFormData.day && newFormData.month && newFormData.year) {
      const isValid = validateDate(
        parseInt(newFormData.day),
        parseInt(newFormData.month),
        parseInt(newFormData.year)
      );

      if (!isValid) {
        setErrors("Please enter a valid date");
      }
    }

    setFormData(newFormData);
  };
  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Loop through images
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable auto-play
    autoplaySpeed: 3000,
    // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    "https://via.placeholder.com/600x300", // Replace with your image URLs
    "https://via.placeholder.com/600x300",
    "https://via.placeholder.com/600x300",
  ];

  const handleSuggestionClick = (suggestion) => {
    setSelectedLocation(suggestion);
    setFormData({
      ...formData,
      birth_place: suggestion.place_name,
    });
    setSuggestions([]);
    setSearchInput(suggestion.place_name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      if (value.length > 50) {
        setErrors("Name should not exceed 50 characters");
        setFormData({
          ...formData,
          [name]: value.substring(0, 50),
        });
      } else {
        setErrors("");
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang);

    // If we have existing data, re-fetch with new language
    if (Object.keys(gemstone).length > 0 && selectedLocation) {
      const formattedDate = `${formData.day}/${formData.month}/${formData.year}`;
      const apiData = {
        dob: formattedDate,
        tob: `${formData.hour}:${formData.minute}`,
        lat: selectedLocation.center[1],
        lon: selectedLocation.center[0],
        tz: 5.5,
      };

      // Use selectedLang instead of language state
      fetchGemstoneAndRudrakshData(apiData, selectedLang);
    }
  };

  // Modify the fetch function to accept language parameter
  const fetchGemstoneAndRudrakshData = async (apiData, languageParam) => {
    // Use the passed language parameter or fall back to language state
    const currentLang = languageParam || language;

    const apiUrl = `https://astrologerdaddy.com/api/astrology-services/extended-horoscope/gem-suggestion?dob=${apiData.dob}&tob=${apiData.tob}&lat=${apiData.lat}&lon=${apiData.lon}&tz=${apiData.tz}&lang=${currentLang}`;
    const rudraksh = `https://astrologerdaddy.com/api/astrology-services/extended-horoscope/rudraksh-suggestion?dob=${apiData.dob}&tob=${apiData.tob}&lat=${apiData.lat}&lon=${apiData.lon}&tz=${apiData.tz}&lang=${currentLang}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setgemstonedata(data.response);

      const resprudraksh = await fetch(rudraksh);
      const data_rudraksh = await resprudraksh.json();
      setruraksh(data_rudraksh.response);

      console.log("API Response:", data);
      console.log("API Response Rudraksh:", data_rudraksh);
    } catch (error) {
      console.error("Error fetching gem suggestion data:", error);
    }
  };

  // Update handleFormSubmit to pass language
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.day || !formData.month || !formData.year) {
      setErrors("Please fill in all date fields");
      return;
    }

    const isValidDate = validateDate(
      parseInt(formData.day),
      parseInt(formData.month),
      parseInt(formData.year)
    );

    if (!isValidDate) {
      setErrors("Please enter a valid date");
      return;
    }

    if (!selectedLocation) {
      alert("Please select a valid birth place from the suggestions.");
      return;
    }

    const formattedDate = `${formData.day}/${formData.month}/${formData.year}`;
    const apiData = {
      dob: formattedDate,
      tob: `${formData.hour}:${formData.minute}`,
      lat: selectedLocation.center[1],
      lon: selectedLocation.center[0],
      tz: 5.5,
    };

    await fetchGemstoneAndRudrakshData(apiData, language);
  };

  const resultsRef = useRef(null);

  // Add effect to scroll to results when data is received
  useEffect(() => {
    if (
      Object.keys(gemstone).length > 0 &&
      Object.keys(rudraksh).length > 0 &&
      resultsRef.current
    ) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [gemstone, rudraksh]);

  return (
    <div className="py-8 w-11/12 lg:w-5/6 mx-auto ">
      <div className="max-w-7xl mx-auto px-4 my-6">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      <h1 className="text-left text-2xl font-bold text-red-700 mt-4 mb-4 border-b-2 border-yellow-500 pb-2">
        Gemstones and Rudrakshs
      </h1>
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-stretch h-full">
        <div className="flex-1 h-full">
          <div className="bg-white shadow-md p-6 rounded-lg h-full flex flex-col">
            <h1 className="text-xl font-bold text-gray-800 mb-4">
              Free Gemstones and Rudraksha Suggestion by AstrologerDaddy.com
            </h1>

            <p className="text-sm md:text-base lg:text-lg text-gray-700 leading-relaxed mb-4">
              For personalized astrological remedies, visit
              <a
                href="https://astrologerdaddy.com"
                className="text-blue-500 underline font-semibold"
              >
                {" "}
                AstrologerDaddy.com{" "}
              </a>
              for Free Gemstones and Rudraksha Suggestions. Gemstones and
              Rudrakshas are powerful tools in Vedic astrology, believed to
              harness specific planetary energies to bring balance and positive
              influences into your life. Each gemstone is associated with a
              planet, and wearing the correct one can enhance your strengths,
              reduce obstacles, and improve various aspects of life, such as
              health, wealth, relationships, and success. Rudrakshas, sacred
              beads used for spiritual and healing purposes, are similarly
              chosen based on your unique astrological needs.
            </p>

            <p className="text-sm md:text-base lg:text-lg text-gray-700 leading-relaxed mb-4">
              At
              <a
                href="https://astrologerdaddy.com"
                className="text-blue-500 underline font-semibold"
              >
                {" "}
                AstrologerDaddy.com{" "}
              </a>
              , the free gemstone and Rudraksha suggestion service provides
              expert recommendations tailored to your birth chart. By analyzing
              the placement of planets and identifying any Doshas (planetary
              imbalances) in your Kundli, the system suggests the most suitable
              gemstones and Rudrakshas for you. These suggestions aim to help
              you overcome challenges, attract positive energy, and improve your
              overall well-being.
            </p>

            <p className="text-sm md:text-base lg:text-lg text-gray-700 leading-relaxed">
              Whether you're dealing with career setbacks, relationship issues,
              or health concerns, wearing the right gemstone or Rudraksha can
              make a significant difference. Simply input your birth details on
              <a
                href="https://astrologerdaddy.com"
                className="text-blue-500 underline font-semibold"
              >
                {" "}
                AstrologerDaddy.com{" "}
              </a>
              and receive free, accurate suggestions that align with your
              astrological profile. Start your journey toward a more balanced
              and harmonious life today!
            </p>
          </div>
        </div>

        <div className="flex-1 py-6 bg-gray-50 w-full">
          <form onSubmit={handleFormSubmit} className="h-full">
            <div className="grid grid-cols-1 gap-1 px-4 pt-2">
              <div className="mb-2">
                <label className="block text-gray-700 font-semibold text-sm">
                  Name<span className="text-red-500 text-sm">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                  pattern="[A-Za-z\s]+"
                  title="Name should only contain letters."
                />
              </div>

              <div className="mb-2">
                <label className="block text-gray-700 font-semibold text-sm">
                  Gender<span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="mb-2">
                <label className="block text-gray-700 font-semibold text-sm">
                  Date of Birth<span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-3 gap-2">
                  <select
                    name="day"
                    value={formData.day}
                    onChange={handleDateChange}
                    className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Day</option>
                    {days.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleDateChange}
                    className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Month</option>
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    name="year"
                    value={formData.year}
                    onChange={handleDateChange}
                    className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {errors && (
                  <p className="text-red-500 text-sm mt-1">{errors}</p>
                )}
              </div>

              <div className="mb-2">
                <label className="block text-gray-700 font-semibold text-sm">
                  Time of Birth (HH:MM) <span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <select
                    name="hour"
                    value={formData.hour}
                    onChange={handleChange}
                    className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Hour</option>
                    {hours.map((h) => (
                      <option key={h} value={h}>
                        {h}
                      </option>
                    ))}
                  </select>
                  <select
                    name="minute"
                    value={formData.minute}
                    onChange={handleChange}
                    className="w-full cursor-pointer p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                  >
                    <option value="">Minute</option>
                    {minutes.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="relative mb-6">
                <label className="block text-gray-700 font-semibold">
                  Birth Place<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Birth Place"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <div className="absolute z-10 mt-1 w-full bg-white rounded shadow-lg">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.place_name}
                    </div>
                  ))}
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-orange-500 text-white font-bold py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="lg:flex md:flex max-w-7xl mx-auto px-4 my-6 items-stretch justify-between w-full ">
        {Object.keys(gemstone).length > 0 &&
          Object.keys(rudraksh).length > 0 && (
            <div className="bg-white p-6 rounded-lg shadow-md lg:w-[70%]">
              <>
                {/* Language Selector */}
                <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
                  <label
                    htmlFor="language-select"
                    className="text-sm font-medium whitespace-nowrap"
                  >
                    Select Language:
                  </label>
                  <select
                    id="language-select"
                    className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="en">English</option>
                    <option value="ta">Tamil</option>
                    <option value="ka">Kannada</option>
                    <option value="te">Telugu</option>
                    <option value="hi">Hindi</option>
                    <option value="ml">Malayalam</option>
                    <option value="mr">Marathi</option>
                    <option value="sp">Spanish</option>
                    <option value="fr">French</option>
                  </select>
                </div>
                <div
                  className="bg-gray-50 p-6 rounded-lg shadow-md mt-8 mb-8"
                  ref={resultsRef}
                >
                  {Object.keys(gemstone).length > 0 && (
                    <div className="mt-8">
                      <h2 className="text-left text-lg font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2">
                        Gemstone Details
                      </h2>
                      <div className="space-y-4">
                        {/* Name */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Name
                          </div>
                          <div className="px-4 py-3">{gemstone.name}</div>
                        </div>

                        {/* Planet */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Planet
                          </div>
                          <div className="px-4 py-3">{gemstone.planet}</div>
                        </div>

                        {/* Description */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Description
                          </div>
                          <div className="px-4 py-3">
                            {gemstone.description}
                          </div>
                        </div>

                        {/* Good Results */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Good Results
                          </div>
                          <div className="px-4 py-3">
                            <ul className="list-disc pl-4">
                              {gemstone.good_results?.map((result, index) => (
                                <li key={index}>{result}</li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        {/* Diseases Cure */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Diseases Cure
                          </div>
                          <div className="px-4 py-3">
                            <ul className="list-disc pl-4">
                              {gemstone.diseases_cure?.map((cure, index) => (
                                <li key={index}>{cure}</li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        {/* Flaws */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Flaws
                          </div>
                          <div className="px-4 py-3">
                            <ul className="list-disc pl-4">
                              {gemstone.flaw_results?.map((flaw, index) => (
                                <li key={index}>
                                  <strong>{flaw.flaw_type}:</strong>{" "}
                                  {flaw.flaw_effects}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="bg-gray-50 p-6 rounded-lg shadow-md mt-8 mb-8">
                  {Object.keys(rudraksh).length > 0 && (
                    <div className="mt-8">
                      <h2 className="text-left text-lg font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2">
                        Rudraksh Details
                      </h2>
                      <div className="space-y-4">
                        {/* Mukhi for Disease Cure */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Mukhi for Disease Cure
                          </div>
                          <div className="px-4 py-3">
                            {rudraksh.mukhi_for_disease_cure?.join(", ")}
                          </div>
                        </div>

                        {/* Mukhi for Money */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Mukhi for Money
                          </div>
                          <div className="px-4 py-3">
                            {rudraksh.mukhi_for_money?.join(", ")}
                          </div>
                        </div>

                        {/* Mukhi Description */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Mukhi Description
                          </div>
                          <div className="px-4 py-3">
                            {Array.isArray(rudraksh.mukhi_description) ? (
                              rudraksh.mukhi_description.map((desc, index) => (
                                <p key={index}>{desc}</p>
                              ))
                            ) : (
                              <p>{rudraksh.mukhi_description}</p>
                            )}
                          </div>
                        </div>

                        {/* Bot Response */}
                        <div className="border rounded-lg overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-semibold">
                            Recommendation
                          </div>
                          <div className="px-4 py-3">
                            {rudraksh.bot_response}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          )}

        {Object.keys(gemstone).length > 0 &&
          Object.keys(rudraksh).length > 0 && (
            <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
              <div className="max-w-full">
                <Slider {...settings}>
                  {images.map((image, index) => (
                    <div key={index} className="px-2">
                      <img
                        src={image}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-auto object-cover rounded-lg shadow-lg"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="max-w-full">
                <Slider {...settings}>
                  {images.map((image, index) => (
                    <div key={index} className="px-2">
                      <img
                        src={image}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-auto object-cover rounded-lg shadow-lg"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="max-w-full">
                <Slider {...settings}>
                  {images.map((image, index) => (
                    <div key={index} className="px-2">
                      <img
                        src={image}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-auto object-cover rounded-lg shadow-lg"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="grow ">
                <img
                  src="https://via.placeholder.com/600x300"
                  className="h-full"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          )}
      </div>

      {/* <div className="bg-gray-50 p-6 rounded-lg shadow-md">
  <h1 className="text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2">
    Astrology and Gemstones
  </h1>
  <p className="text-gray-700 leading-relaxed mb-4">
    The science of Gemstone astrology is based on the fact that cosmic forces of planets govern the human body and life. 
    The cosmic structure of our birth chart defines how much help we will receive from destiny throughout our lives. 
    Our birth chart and the position of planets shape our physical, intellectual, emotional, spiritual, and karmic self, and are key factors in determining which gemstone one should wear.
  </p>
  <p className="text-gray-700 leading-relaxed mb-4">
    Our birth chart is composed of powerful and weak planets. An astrologer serves as the link between you and your lucky gemstone. 
    They critically analyze an individual's birth chart and the effect of each planet to assign a gemstone that enhances a particular planet's influence. 
    Enhancing a planet with a gemstone can sometimes remove the malefic effects of planets.
  </p>
  <p className="text-gray-700 leading-relaxed">
    Gemstones are known to affect an individual's emotional and mental state, altering the course of destiny in their life.
  </p>
</div> */}

      {/* <div className='bg-gray-50 p-6 rounded-lg shadow-md'>
        <h1 className='text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2' >Use of Gemstone</h1>
        <ul  className="list-disc list-inside text-gray-700 leading-relaxed">
            <li>Remove obstacles in fields like finance, marriage, carrier, fame, luck, income and wealth etc..</li>
            <li>The electromagnetic vibrations of the gems interact with and influence the aura of our body while touching our skin.</li>
            <li>They have dual effects: spectrum effects and other is by radioactive effects.</li>
            <li>Gem power is absorbed in the individual aura this helps resist any negative vibrations from external sources.</li>
        </ul>
    </div> */}
      {/* <div className='bg-gray-50 p-6 rounded-lg shadow-md'>
        <h1 className='text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2'>What should a gemstone recommendation include:</h1>
        <ul  className="list-disc list-inside text-gray-700 leading-relaxed">
            <li>What gemstones suit you</li>
            <li>Weight of gemstone</li>
            <li>What is the right metal for you</li>
            <li>Instructions to wear the gemstone - day, time and date</li>
        </ul>
    </div> */}
      {/* <div className='bg-gray-50 p-6 rounded-lg shadow-md'>
        <h1 className='text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2'>Gemstones for different planets</h1>
        <p>Scholars of Astrology believe that each gemstone represents a particular planet .Depending on the position of planets in your birth chart (natal chart) one can choose from the list below. However it's best to consult a learned astrologer before wearing a gemstone. Just like medicines wearing a wrong gemstone can adversely affect the individual in concern... .Have a look at the list below to know which gemstone represents which planet.</p>
        <ul  className="list-disc list-inside text-gray-700 leading-relaxed">
            <li>Sun-Ruby</li>
            <li>Moon-pearl</li>
            <li>Mars-coral</li>
            <li>Mercur-Emerald</li>
            <li>Jupiter-Yellow sapphire</li>
            <li>Venus-Diamond</li>
            <li>Saturn-Blue Sapphire</li>
            <li>Rahu-Hessonite</li>
        </ul>
    </div> */}
      {/* <div className='bg-gray-50 p-6 rounded-lg shadow-md'>
        <h1 className='text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2'>Gemstone Recommendation on AstrologerDaddy.com</h1>
        <p className='text-gray-700 leading-relaxed mb-4'>If you're looking for a gemstone recommendation based on ascendant rashi lord ( lagna lord) , this is where you get it all. AstrologerDaddy.com gemstone recommendations are based on expert analysis done by our team of astrologers by considering every aspect of your birth chart. Get destiny to work in favour by ordering a gemstone at the most reasonable price in market and be assured of superior quality. We value our clients trust and wish to build a strong bond based on genuine service. Your just Two steps away from getting the planets work in your favour. All you need to do is: fill is click on Gemstone recommendation, get details about your life stone, Bhagya stone and Lucky stone. Step two click on Astro shop and order a gemstone that is genuine and reasonably priced.</p>
    </div> */}
      {/* <div className='bg-gray-50 p-6 rounded-lg shadow-md'>
    <h1 className='text-left text-2xl font-bold text-gray-800 mt-4 mb-4 border-b-2 border-yellow-500 pb-2'>Choose Sign For Your Gemstone</h1>
    <div className='grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-12 mt-8 mx-auto'>
        {gemimage.map((val, index) => (
          <img 
            key={index} 
            src={val.src} 
            alt={`Gemstone ${val.zodiac}`} 
            className="w-24 mx-auto h-20 mb-4 rounded-md shadow-md cursor-pointer" 
            onClick={() => handleImageClick(val.zodiac)} 
          />
        ))}
      </div>
</div> */}
      <div className="max-w-7xl mx-auto px-4 my-6">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Gemstone;
