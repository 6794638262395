

import React, { memo } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const AntarDashaSection = memo(({ antarDashaData }) => {
  if (!antarDashaData || !antarDashaData.antardashas) return null;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg min-w-fit mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Antar Dasha Details</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {antarDashaData.antardashas.map((dashaGroup, groupIndex) => (
          <div key={groupIndex} className="bg-gray-50 rounded-lg py-6 shadow-md transition-all duration-300 hover:shadow-lg">
            <h3 className="text-xl text-center font-semibold mb-4 text-gray-700">
              Group {groupIndex + 1}
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-3 px-4 text-left text-gray-600">Dasha Combination</th>
                    <th className="py-3 px-4 text-left text-gray-600">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {dashaGroup.map((dasha, index) => (
                    <tr key={`${groupIndex}-${index}`} className="hover:bg-gray-100 transition-colors duration-150">
                      <td className="py-3 px-4 border-b text-gray-800">{dasha}</td>
                      <td className="py-3 px-4 border-b text-gray-800 flex items-center text-nowrap">
                        <FaCalendarAlt className="mr-2 text-gray-500" />
                        {antarDashaData.antardasha_order[groupIndex][index]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default AntarDashaSection;
