import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

// Import zodiac images
import aries from "../assets/zodiacs/aries-01.png";
import aquarius from "../assets/zodiacs/aquarius-01.png";
import cancer from "../assets/zodiacs/cancer-01.png";
import capricorn from "../assets/zodiacs/capricorn-01.png";
import gemini from "../assets/zodiacs/gemini-01.png";
import leo from "../assets/zodiacs/LEO-01.png";
import libra from "../assets/zodiacs/libra-01.png";
import pisces from "../assets/zodiacs/pisces-01.png";
import sagittarius from "../assets/zodiacs/sagittarius-01.png";
import taurus from "../assets/zodiacs/taurus-01.png";
import virgo from "../assets/zodiacs/virgo-01.png";
import scorpio from "../assets/zodiacs/scorpio-01.png";
import Slider from "react-slick";


const zodiacImages = {
  aries,
  taurus,
  gemini,
  cancer,
  leo,
  virgo,
  libra,
  scorpio,
  sagittarius,
  capricorn,
  aquarius,
  pisces,
};



const Horoscope = () => {
  const { zodiac } = useParams();
  const navigate = useNavigate();
 
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const handleKmaking = () =>{
    navigate("/kundli-making")
  }
  const handleKmatching = () =>{
    navigate("/kundli-matching")
  }
  const zodiacKeys = Object.keys(zodiacImages);
  const [zodiacIndex, setZodiacIndex] = useState(1);
  const [dailySunData, setDailySunData] = useState(null);
  const [dailyMoonData, setDailyMoonData] = useState(null);
  const [weeklyMoonData, setWeeklyMoonData] = useState(null);
  const [weeklySunData, setWeeklySunData] = useState(null);
  const [dailyNakshatraData, setDailyNakshatraData] = useState(null);
  const [yearlyData, setYearlyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("dailySun");
  const [compatibility, setCompatibility] = useState(null);
  const [loadingCompatibility, setLoadingCompatibility] = useState(false);
  const [selectedCompatibilitySign, setSelectedCompatibilitySign] =
    useState(null);

  const [selectedZodiac, setSelectedZodiac] = useState({
    name: "aries",
    image: zodiacImages["aries"],
  });

  const handleZodiacClick = (zodiacSign) => {
    navigate(`/horoscope/${zodiacSign}`);
  };

  useEffect(() => {
    const index = zodiacKeys.indexOf(zodiac.toLowerCase()) + 1;
    setZodiacIndex(index);

    const fetchAllHoroscopes = async () => {
      setLoading(true);
      setError(null);
      const currentDate = new Date().toLocaleDateString("en-GB");
      const currentYear = new Date().getFullYear();

      try {
        const [
          dailySunRes,
          dailyMoonRes,
          weeklySunRes,
          weeklyMoonRes,
          dailyNakshatraRes,
          yearlyRes,
        ] = await Promise.all([
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/daily-sun?zodiac=${index}&date=${currentDate}&show_same=true&lang=${language}&split=true&type=big`
          ),
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/daily-moon?zodiac=${index}&date=${currentDate}&show_same=true&lang=${language}&split=true&type=big`
          ),
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/weekly-sun?zodiac=${index}&week=thisweek&show_same=true&lang=${language}&split=true&type=big`
          ),
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/weekly-moon?zodiac=${index}&week=thisweek&show_same=true&lang=${language}&split=true&type=big`
          ),
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/daily-nakshatra?nakshatra=${index}&date=${currentDate}&show_same=true&lang=${language}&split=true&type=big`
          ),
          axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/yearly?year=${currentYear}&zodiac=${index}&lang=${language}`
          ),
        ]);

        setDailySunData(dailySunRes.data.response);
        setDailyMoonData(dailyMoonRes.data.response);
        setWeeklySunData(weeklySunRes.data.response);
        setWeeklyMoonData(weeklyMoonRes.data.response);
        setDailyNakshatraData(dailyNakshatraRes.data.response);
        setYearlyData(yearlyRes.data.response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllHoroscopes();
  }, [zodiac,language]);

  const fetchCompatibility = async (otherSign) => {
    setLoadingCompatibility(true);
    try {
      const response = await axios.get(
        `https://astrologerdaddy.com/api/astrology-services/matching/western-match?boy_sign=${zodiacIndex}&girl_sign=${
          zodiacKeys.indexOf(otherSign) + 1
        }&lang=${language}`
      );
      setCompatibility(response.data.response);
    } catch (err) {
      console.error("Error fetching compatibility:", err);
    } finally {
      setLoadingCompatibility(false);
    }
  };

  const handleCompatibilityClick = (sign) => {
    setSelectedCompatibilitySign(sign);
    fetchCompatibility(sign);
  };
  useEffect(() => {
    // Set default language from localStorage or fallback to English
    const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
    setLanguage(storedLanguage);
  }, []);

  const renderDailySun = () => {
    const {
      lucky_color,
      lucky_color_code,
      lucky_number,
      bot_response,
      sun_sign,
    } = dailySunData;

    // Extracting scores and split responses from bot_response
    const {
      physique,
      status,
      finances,
      relationship,
      career,
      travel,
      family,
      friends,
      health,
      total_score,
    } = bot_response;

    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
  
    
    return (


      <div className="lg:flex md:flex items-stretch justify-between w-full ">
      <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
       
        <h3 className="text-xl font-bold mb-4">Daily Sun Prediction</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Overall Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Overall Score</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
                style={{ width: `${total_score.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{total_score.score}</p>
            <p className="text-sm mt-1">{total_score.split_response}</p>
          </div>

          {/* Lucky Color */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Color</h4>
            <div
              className="w-full h-10 rounded-lg"
              style={{ backgroundColor: lucky_color_code }}
            ></div>
            <p className="mt-2 text-sm">{lucky_color}</p>
          </div>

          {/* Lucky Numbers */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Numbers</h4>
            <ul className="list-disc pl-5">
              {lucky_number.map((num, index) => (
                <li key={index}>{num}</li>
              ))}
            </ul>
          </div>

          {/* Physique Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Physique</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-green-600 rounded-full"
                style={{ width: `${physique.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{physique.score}</p>
            <p className="text-sm mt-1">{physique.split_response}</p>
          </div>

          {/* Status Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Status</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-orange-600 rounded-full"
                style={{ width: `${status.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{status.score}</p>
            <p className="text-sm mt-1">{status.split_response}</p>
          </div>

          {/* Finances Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Finances</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-yellow-600 rounded-full"
                style={{ width: `${finances.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{finances.score}</p>
            <p className="text-sm mt-1">{finances.split_response}</p>
          </div>

          {/* Relationship Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Relationship</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-pink-600 rounded-full"
                style={{ width: `${relationship.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{relationship.score}</p>
            <p className="text-sm mt-1">{relationship.split_response}</p>
          </div>

          {/* Career Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Career</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-600 rounded-full"
                style={{ width: `${career.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{career.score}</p>
            <p className="text-sm mt-1">{career.split_response}</p>
          </div>

          {/* Travel Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Travel</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-teal-600 rounded-full"
                style={{ width: `${travel.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{travel.score}</p>
            <p className="text-sm mt-1">{travel.split_response}</p>
          </div>

          {/* Family Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Family</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-600 rounded-full"
                style={{ width: `${family.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{family.score}</p>
            <p className="text-sm mt-1">{family.split_response}</p>
          </div>

          {/* Friends Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Friends</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-700 rounded-full"
                style={{ width: `${friends.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{friends.score}</p>
            <p className="text-sm mt-1">{friends.split_response}</p>
          </div>

          {/* Health Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Health</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-red-600 rounded-full"
                style={{ width: `${health.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{health.score}</p>
            <p className="text-sm mt-1">{health.split_response}</p>
          </div>
        </div>
      </div>
      <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
    );
  };

  const renderDailyMoon = () => {
    const { lucky_color, lucky_color_code, lucky_number, bot_response } =
      dailyMoonData;

    const {
      total_score,
      physique,
      status,
      finances,
      relationship,
      career,
      travel,
      family,
      friends,
      health,
    } = bot_response;

    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
  

    return (
      <div className="lg:flex md:flex items-stretch justify-between w-full ">
      <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
        <h3 className="text-xl font-bold mb-4">Daily Moon Prediction</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Overall Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Overall Score</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
                style={{ width: `${total_score.score}%` }} // Dynamic total score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{total_score.score}</p>
            <p className="text-sm">{total_score.split_response}</p>
          </div>

          {/* Lucky Color */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Color</h4>
            <div
              className="w-full h-10 rounded-lg"
              style={{ backgroundColor: lucky_color_code }} // Dynamic lucky color
            ></div>
            <p className="mt-2 text-sm">{lucky_color}</p>
          </div>

          {/* Lucky Numbers */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Numbers</h4>
            <ul className="list-disc pl-5">
              {lucky_number.map((num, index) => (
                <li key={index}>{num}</li>
              ))}
            </ul>
          </div>

          {/* Physique Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Physique</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-green-600 rounded-full"
                style={{ width: `${physique.score}%` }} // Dynamic physique score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{physique.score}</p>
            <p className="text-sm">{physique.split_response}</p>
          </div>

          {/* Status Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Status</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-orange-600 rounded-full"
                style={{ width: `${status.score}%` }} // Dynamic status score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{status.score}</p>
            <p className="text-sm">{status.split_response}</p>
          </div>

          {/* Finances Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Finances</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-yellow-600 rounded-full"
                style={{ width: `${finances.score}%` }} // Dynamic finances score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{finances.score}</p>
            <p className="text-sm">{finances.split_response}</p>
          </div>

          {/* Relationship Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Relationship</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-pink-600 rounded-full"
                style={{ width: `${relationship.score}%` }} // Dynamic relationship score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{relationship.score}</p>
            <p className="text-sm">{relationship.split_response}</p>
          </div>

          {/* Career Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Career</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-600 rounded-full"
                style={{ width: `${career.score}%` }} // Dynamic career score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{career.score}</p>
            <p className="text-sm">{career.split_response}</p>
          </div>

          {/* Travel Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Travel</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-teal-600 rounded-full"
                style={{ width: `${travel.score}%` }} // Dynamic travel score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{travel.score}</p>
            <p className="text-sm">{travel.split_response}</p>
          </div>

          {/* Family Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Family</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-600 rounded-full"
                style={{ width: `${family.score}%` }} // Dynamic family score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{family.score}</p>
            <p className="text-sm">{family.split_response}</p>
          </div>

          {/* Friends Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Friends</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-700 rounded-full"
                style={{ width: `${friends.score}%` }} // Dynamic friends score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{friends.score}</p>
            <p className="text-sm">{friends.split_response}</p>
          </div>

          {/* Health Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Health</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-red-600 rounded-full"
                style={{ width: `${health.score}%` }} // Dynamic health score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{health.score}</p>
            <p className="text-sm">{health.split_response}</p>
          </div>
        </div>
      </div>
      <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
    );
  };

  const renderWeeklyMoon = () => {
    const { lucky_color, lucky_color_code, lucky_number, bot_response } =
      weeklyMoonData;

    const {
      total_score,
      status,
      finances,
      relationship,
      career,
      travel,
      family,
      friends,
      health,
    } = bot_response;
    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
  

    
    return (
      <div className="lg:flex md:flex items-stretch justify-between w-full ">
      <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
        <h3 className="text-xl font-bold mb-4">Weekly Moon Prediction</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Overall Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Overall Score</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
                style={{ width: `${total_score.score}%` }} // Dynamic score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{total_score.score}</p>
            <p className="text-sm">{total_score.split_response}</p>
          </div>

          {/* Lucky Color */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Color</h4>
            <div
              className="w-full h-10 rounded-lg"
              style={{ backgroundColor: lucky_color_code }} // Dynamic lucky color
            ></div>
            <p className="mt-2 text-sm">{lucky_color}</p>
          </div>

          {/* Lucky Numbers */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Numbers</h4>
            <ul className="list-disc pl-5">
              {lucky_number.map((num, index) => (
                <li key={index}>{num}</li>
              ))}
            </ul>
          </div>

          {/* Status Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Status</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-orange-600 rounded-full"
                style={{ width: `${status.score}%` }} // Dynamic status score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{status.score}</p>
            <p className="text-sm">{status.split_response}</p>
          </div>

          {/* Finances Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Finances</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-yellow-600 rounded-full"
                style={{ width: `${finances.score}%` }} // Dynamic finances score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{finances.score}</p>
            <p className="text-sm">{finances.split_response}</p>
          </div>

          {/* Relationship Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Relationship</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-pink-600 rounded-full"
                style={{ width: `${relationship.score}%` }} // Dynamic relationship score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{relationship.score}</p>
            <p className="text-sm">{relationship.split_response}</p>
          </div>

          {/* Career Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Career</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-green-600 rounded-full"
                style={{ width: `${career.score}%` }} // Dynamic career score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{career.score}</p>
            <p className="text-sm">{career.split_response}</p>
          </div>

          {/* Travel Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Travel</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-teal-600 rounded-full"
                style={{ width: `${travel.score}%` }} // Dynamic travel score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{travel.score}</p>
            <p className="text-sm">{travel.split_response}</p>
          </div>

          {/* Family Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Family</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-600 rounded-full"
                style={{ width: `${family.score}%` }} // Dynamic family score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{family.score}</p>
            <p className="text-sm">{family.split_response}</p>
          </div>

          {/* Friends Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Friends</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-600 rounded-full"
                style={{ width: `${friends.score}%` }} // Dynamic friends score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{friends.score}</p>
            <p className="text-sm">{friends.split_response}</p>
          </div>

          {/* Health Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Health</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-red-600 rounded-full"
                style={{ width: `${health.score}%` }} // Dynamic health score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{health.score}</p>
            <p className="text-sm">{health.split_response}</p>
          </div>

          {/* Weekly Insight */}
          <div className="border p-4 rounded-lg shadow col-span-2">
            <h4 className="text-lg font-semibold">Weekly Insight</h4>
            <p className="mt-2">{total_score.split_response}</p>
          </div>
        </div>
      </div>
      <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
    );
  };

  const renderWeeklySun = () => {
    const { lucky_color, lucky_color_code, lucky_number, bot_response } =
      weeklySunData;

    const {
      total_score,
      status,
      finances,
      relationship,
      career,
      travel,
      family,
      friends,
      health,
    } = bot_response;

    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
  

    return (
      <div className="lg:flex md:flex items-stretch justify-between w-full ">
      <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
        <h3 className="text-xl font-bold mb-4">Weekly Sun Prediction</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Overall Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Overall Score</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
                style={{ width: `${total_score.score}%` }} // Dynamic score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{total_score.score}</p>
            <p className="text-sm">{total_score.split_response}</p>
          </div>

          {/* Lucky Color */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Color</h4>
            <div
              className="w-full h-10 rounded-lg"
              style={{ backgroundColor: lucky_color_code }} // Dynamic lucky color
            ></div>
            <p className="mt-2 text-sm">{lucky_color}</p>
          </div>

          {/* Lucky Numbers */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Numbers</h4>
            <ul className="list-disc pl-5">
              {lucky_number.map((num, index) => (
                <li key={index}>{num}</li>
              ))}
            </ul>
          </div>

          {/* Status Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Status</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-orange-600 rounded-full"
                style={{ width: `${status.score}%` }} // Dynamic status score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{status.score}</p>
            <p className="text-sm">{status.split_response}</p>
          </div>

          {/* Finances Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Finances</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-yellow-600 rounded-full"
                style={{ width: `${finances.score}%` }} // Dynamic finances score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{finances.score}</p>
            <p className="text-sm">{finances.split_response}</p>
          </div>

          {/* Relationship Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Relationship</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-pink-600 rounded-full"
                style={{ width: `${relationship.score}%` }} // Dynamic relationship score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{relationship.score}</p>
            <p className="text-sm">{relationship.split_response}</p>
          </div>

          {/* Career Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Career</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-green-600 rounded-full"
                style={{ width: `${career.score}%` }} // Dynamic career score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{career.score}</p>
            <p className="text-sm">{career.split_response}</p>
          </div>

          {/* Travel Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Travel</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-teal-600 rounded-full"
                style={{ width: `${travel.score}%` }} // Dynamic travel score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{travel.score}</p>
            <p className="text-sm">{travel.split_response}</p>
          </div>

          {/* Family Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Family</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-600 rounded-full"
                style={{ width: `${family.score}%` }} // Dynamic family score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{family.score}</p>
            <p className="text-sm">{family.split_response}</p>
          </div>

          {/* Friends Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Friends</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-600 rounded-full"
                style={{ width: `${friends.score}%` }} // Dynamic friends score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{friends.score}</p>
            <p className="text-sm">{friends.split_response}</p>
          </div>

          {/* Health Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Health</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-red-600 rounded-full"
                style={{ width: `${health.score}%` }} // Dynamic health score
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{health.score}</p>
            <p className="text-sm">{health.split_response}</p>
          </div>

          {/* Weekly Insight */}
          <div className="border p-4 rounded-lg shadow col-span-2">
            <h4 className="text-lg font-semibold">Weekly Insight</h4>
            <p className="mt-2">{total_score.split_response}</p>
          </div>
        </div>
      </div>
      <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
    );
  };

  const renderDailyNakshatra = () => {
    const {
      lucky_color,
      lucky_color_code,
      lucky_number,
      bot_response,
      nakshatra,
    } = dailyNakshatraData;

    // Extracting scores and split responses from bot_response
    const {
      physique,
      status,
      finances,
      relationship,
      career,
      travel,
      family,
      friends,
      health,
      total_score,
    } = bot_response;

    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
  

    return (
      <div className="lg:flex md:flex items-stretch justify-between w-full ">
      <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
        <h3 className="text-xl font-bold mb-4">Daily Nakshatra Prediction</h3>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Overall Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Overall Score</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
                style={{ width: `${total_score.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{total_score.score}</p>
            <p className="text-sm mt-1">{total_score.split_response}</p>
          </div>

          {/* Lucky Color */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Color</h4>
            <div
              className="w-full h-10 rounded-lg"
              style={{ backgroundColor: lucky_color_code }}
            ></div>
            <p className="mt-2 text-sm">{lucky_color}</p>
          </div>

          {/* Lucky Numbers */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Lucky Numbers</h4>
            <ul className="list-disc pl-5">
              {lucky_number.map((num, index) => (
                <li key={index}>{num}</li>
              ))}
            </ul>
          </div>

          {/* Physique Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Physique</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-green-600 rounded-full"
                style={{ width: `${physique.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{physique.score}</p>
            <p className="text-sm mt-1">{physique.split_response}</p>
          </div>

          {/* Status Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Status</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-orange-600 rounded-full"
                style={{ width: `${status.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{status.score}</p>
            <p className="text-sm mt-1">{status.split_response}</p>
          </div>

          {/* Finances Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Finances</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-yellow-600 rounded-full"
                style={{ width: `${finances.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{finances.score}</p>
            <p className="text-sm mt-1">{finances.split_response}</p>
          </div>

          {/* Relationship Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Relationship</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-pink-600 rounded-full"
                style={{ width: `${relationship.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{relationship.score}</p>
            <p className="text-sm mt-1">{relationship.split_response}</p>
          </div>

          {/* Career Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Career</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-600 rounded-full"
                style={{ width: `${career.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{career.score}</p>
            <p className="text-sm mt-1">{career.split_response}</p>
          </div>

          {/* Travel Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Travel</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-teal-600 rounded-full"
                style={{ width: `${travel.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{travel.score}</p>
            <p className="text-sm mt-1">{travel.split_response}</p>
          </div>

          {/* Family Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Family</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-600 rounded-full"
                style={{ width: `${family.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{family.score}</p>
            <p className="text-sm mt-1">{family.split_response}</p>
          </div>

          {/* Friends Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Friends</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-purple-700 rounded-full"
                style={{ width: `${friends.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{friends.score}</p>
            <p className="text-sm mt-1">{friends.split_response}</p>
          </div>

          {/* Health Score */}
          <div className="border p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold">Health</h4>
            <div className="relative w-full h-4 bg-gray-200 rounded-full mt-2">
              <div
                className="absolute top-0 left-0 h-full bg-red-600 rounded-full"
                style={{ width: `${health.score}%` }}
              ></div>
            </div>
            <p className="text-right text-sm mt-1">{health.score}</p>
            <p className="text-sm mt-1">{health.split_response}</p>
          </div>

          {/* Nakshatra */}
          <div className="border p-4 rounded-lg shadow col-span-2">
            <h4 className="text-lg font-semibold">Nakshatra</h4>
            <p className="mt-2">{nakshatra}</p>
          </div>
        </div>
      </div>
      <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
      </div>
    );
  };

  const renderYearly = () => {
    const settings = {
      dots: false,          // Show navigation dots
      infinite: true,      // Loop through images
      speed: 500,          // Transition speed
      slidesToShow: 1,     // Show one slide at a time
      slidesToScroll: 1,   // Scroll one slide at a time
      autoplay: true,      // Enable auto-play
      autoplaySpeed: 3000,
      // Delay between transitions
    };
  
    // Array of images to display in the slider
    const images = [
      'https://via.placeholder.com/600x300', // Replace with your image URLs
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/600x300',
    ];
    return(
      <div className="lg:flex md:flex items-stretch justify-between w-full ">
    <div className="bg-white p-6 rounded-lg shadow-md  lg:w-[70%]">
      {yearlyData &&
        Object.entries(yearlyData).map(([phase, data]) => {
          if (phase.startsWith("phase_")) {
            return (
              <div key={phase} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">{data.period}</h3>
                <p className="mb-4">{data.prediction}</p>
                <div className="grid md:grid-cols-2 gap-4">
                  {Object.entries(data).map(([key, value]) => {
                    if (typeof value === "object" && value.prediction) {
                      return (
                        <div key={key} className="mb-4">
                          <h4 className="font-medium capitalize mb-2">{key}</h4>
                          <p className="text-sm text-gray-600">
                            {value.prediction}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            );
          }
          return null;
        })}
    </div>
    <div className="lg:w-[25%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="max-w-full">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="grow ">

            <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
          </div>

        </div>
    </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <FaSpinner className="animate-spin h-8 w-8 text-yellow-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4 text-center">
        <p>Error: {error}</p>
      </div>
    );
  }
  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
     // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];






  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang); // Store in localStorage

    // Trigger API call when language changes
    callVedicAPI(selectedLang);
  };

  const callVedicAPI = (lang) => {
   
    const url = `https://astrologerdaddy.com/api/astrology-services/dosha/kaalsarp-dosh?dob=23/02/1985&tob=05:40&lat=11&lon=77&tz=5.5&&lang=${lang}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };






  
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      
      <div className="max-w-7xl mx-auto px-4">
        <div>
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>
     
        <div className="space-y-8 mt-5">
          {/* Header Section */}
          <div className="bg-white px-6 py-2 rounded-lg shadow-md text-center">
            <div className="flex items-center justify-center mb-2">
              <img
                src={zodiacImages[zodiac.toLowerCase()]}
                alt={zodiac}
                className="w-24 h-24"
              />
              <h1 className="text-3xl font-bold ml-4">
                {zodiac.toUpperCase()}
              </h1>
            </div>
            <p className="text-gray-600">
              {new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </p>
          </div>

          <div className="p-6">
            {/* Grid of Zodiac Cards */}
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-12 gap-1">
              {Object.keys(zodiacImages).map((zodiac) => (
                <div
                  key={zodiac}
                  className="bg-white lg:p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition"
                  onClick={() => handleZodiacClick(zodiac)}
                >
                  <img
                    src={zodiacImages[zodiac]}
                    alt={zodiac}
                    className="w-full h-24 object-contain lg:mb-2"
                  />
                  <h2 className="text-center font-bold capitalize lg:text-sm text-[10px]">{zodiac}</h2>
                </div>
              ))}
            </div>
          </div>

          {/* Tab Navigation */}
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
          <div className="flex flex-wrap gap-2">
            {[
              { id: "dailySun", label: "Daily Sun" },
              { id: "dailyMoon", label: "Daily Moon" },
              { id: "weeklySun", label: "Weekly Sun" },
              { id: "weeklyMoon", label: "Weekly Moon" },
              { id: "dailyNakshatra", label: "Daily Nakshatra" },
              { id: "yearly", label: "Yearly" },
            ].map((tab) => (
              <button
                key={tab.id}
                className={`px-4 py-2 rounded-lg ${
                  activeTab === tab.id
                    ? "bg-yellow-500 text-white"
                    : "bg-white text-gray-700"
                }`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          
          <div className="flex flex-wrap gap-2 px-2 mt-2">
      <label htmlFor="language-select">Select Language: </label>
      <select
        id="language-select"
        value={language}
        onChange={handleLanguageChange}
      >
        <option value="en">English</option>
        <option value="ta">Tamil</option>
        <option value="ka">Kannada</option>
        <option value="te">Telugu</option>
        <option value="hi">Hindi</option>
        <option value="ml">Malayalam</option>
        <option value="mr">Marathi</option>
        
        <option value="sp">Spanish</option>
        <option value="fr">French</option>
      </select>
    </div>
          
</div>
          {/* Horoscope Content */}
          {activeTab === "dailySun" && dailySunData && renderDailySun()}
          {activeTab === "dailyMoon" && dailyMoonData && renderDailyMoon()}
          {activeTab === "weeklySun" && weeklySunData && renderWeeklySun()}
          {activeTab === "weeklyMoon" && weeklyMoonData && renderWeeklyMoon()}
          {activeTab === "dailyNakshatra" &&
            dailyNakshatraData &&
            renderDailyNakshatra()}
          {activeTab === "yearly" && yearlyData && renderYearly()}
          
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 my-6">
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>


      <div className="w-full   flex flex-col items-center min-h-[100px] bg-gradient-to-r from-indigo-100 via-blue-50 to-yellow-100">
  
  {/* Title with Animation */}
  <h1 className="font-extrabold pt-4 text-xl lg:text-2xl text-gray-800 relative transition-transform duration-300 ease-in-out hover:scale-105 mb-4">
    Astrology Services
   
  </h1>
  
  {/* Buttons with Gradient and Hover */}
  <div className="flex space-x-4 pb-4">
    <button 
      onClick={handleKmaking}
      className="bg-orange-500 text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105"
    >
      Kundli Making
    </button>
    <button 
      onClick={handleKmatching}
      className="bg-orange-500 text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105"
    >
      Kundli Matching
    </button>
  </div>
</div>


      {/* Compatibility Section */}
      <div className="bg-yellow-500 mt-8">
        <div className="py-8 w-11/12 lg:w-5/6 mx-auto">
          <div className="text-center">
            <h2 className="md:text-3xl text-xl text-center mx-auto font-extrabold mb-4 text-gray-800">
              {zodiac.charAt(0).toUpperCase() + zodiac.slice(1)} compatibility
              with other signs
            </h2>
            <h3 className="md:text-2xl text-xl text-center mx-auto lg:mb-4 mb-2 text-gray-800">
              Check your relationship compatibility
            </h3>
          </div>
          <div className="grid lg:py-4 py-2 items-center justify-center grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
            {Object.keys(zodiacImages)
              .filter((sign) => sign !== zodiac.toLowerCase())
              .map((sign, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center lg:pt-10 pt-5 pb-10"
                  onClick={() => handleCompatibilityClick(sign)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex items-center hover:scale-105 transition-transform cursor-pointer">
                    <img
                      src={zodiacImages[zodiac.toLowerCase()]}
                      alt={`${zodiac} image`}
                      className="w-16 h-12 sm:w-18 sm:h-14 lg:w-28 lg:h-24 rounded-full"
                    />
                    <img
                      src={zodiacImages[sign]}
                      alt={`${sign} image`}
                      className="w-16 h-12 sm:w-18 sm:h-14 lg:w-28 lg:h-24 rounded-full ml-2"
                    />
                  </div>
                  <p className="mt-2 text-center text-xs font-semibold text-gray-800 bg-yellow-200 px-3 py-1 rounded-2xl shadow-md">
                    {`${zodiac.charAt(0).toUpperCase() + zodiac.slice(1)} & ${
                      sign.charAt(0).toUpperCase() + sign.slice(1)
                    }`}
                  </p>
                </div>
              ))}
          </div>

          {/* Compatibility Results Modal */}
          {loadingCompatibility && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg">
                <FaSpinner className="animate-spin h-8 w-8 text-yellow-500" />
              </div>
            </div>
          )}

          {compatibility && selectedCompatibilitySign && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold">
                    Compatibility Results:{" "}
                    {zodiac.charAt(0).toUpperCase() + zodiac.slice(1)} &{" "}
                    {selectedCompatibilitySign.charAt(0).toUpperCase() +
                      selectedCompatibilitySign.slice(1)}
                  </h3>
                  <button
                    onClick={() => {
                      setCompatibility(null);
                      setSelectedCompatibilitySign(null);
                    }}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    ✕
                  </button>
                </div>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium mb-2">Overall Compatibility</h4>
                    <div className="w-full bg-gray-200 h-2 rounded-full">
                      <div
                        className="bg-yellow-500 h-2 rounded-full"
                        style={{ width: `${compatibility.score}%` }}
                      ></div>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">
                      Score: {compatibility.score}%
                    </p>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Detailed Analysis</h4>
                    <p className="text-gray-600">
                      {compatibility.bot_response}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div  className="max-w-7xl mx-auto px-4 my-6">
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>
    </div>
  );
};

export default Horoscope;
