import React from 'react';
import { useParams } from 'react-router-dom';

function GemstoneImage() {
    const { zodiac } = useParams();  // Extract zodiac name from the URL

    const life = [{
        "Recommend Gemstone": "Ruby (Manikya)",
        "Qualities of the Gemstone": "2 Carats",
        "Wearing instructions": "Gold, in ring finger",
        "Mantra": "Om hram hrim hraum sah suryaya namah"
    }];

    const lucky = [{
        "Recommend Gemstone": "Ruby (Manikya)",
        "Qualities of the Gemstone": "2 Carats",
        "Wearing instructions": "Gold, in ring finger",
        "Mantra": "Om hram hrim hraum sah suryaya namah"
    }];

    const bhagya = [{
        "Recommend Gemstone": "Ruby (Manikya)",
        "Qualities of the Gemstone": "2 Carats",
        "Wearing instructions": "Gold, in ring finger",
        "Mantra": "Om hram hrim hraum sah suryaya namah"
    }];

    const renderTable = (data) => {
        return (
            <table className="min-w-full table-auto border-collapse border border-gray-300 bg-white shadow-md mt-4">
                <tbody>
                    {Object.entries(data).map(([key, value], index) => (
                        <tr key={index} className="border-b border-gray-300">
                            <td className="px-4 py-2 font-semibold text-gray-700">{key}</td>
                            <td className="px-4 py-2 text-gray-600">{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="container mx-auto p-6">
            {/* Dynamic Zodiac Name in Heading */}
            <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Gemstone Recommendations for {zodiac}</h1>

            <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-700">Life Stone</h2>
                <p className="text-gray-600 mb-4">
                    A life stone is a gem for the Lagna lord. One can wear it throughout their life to experience its mystic powers. Wearing a life stone can remove obstacles and bless an individual with happiness, success, and prosperity. It is generally worn to bring about the overall well-being of an individual. Its cosmic rays influence the person's entire existence.
                </p>
                <h2 className="text-lg font-semibold text-gray-700">Recommendation</h2>
                {life.map((val, index) => (
                    <div key={index}>
                        {renderTable(val)}
                    </div>
                ))}
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-700">Lucky Stone</h2>
                <p className="text-gray-600 mb-4">
                    Wearing a lucky stone enhances fortune and offers protective energies.
                </p>
                <h2 className="text-lg font-semibold text-gray-700">Recommendation</h2>
                {lucky.map((val, index) => (
                    <div key={index}>
                        {renderTable(val)}
                    </div>
                ))}
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-700">Bhagya Stone</h2>
                <p className="text-gray-600 mb-4">
                    Bhagya stones are specifically chosen to bring luck and success in life, especially regarding career and financial prospects.
                </p>
                <h2 className="text-lg font-semibold text-gray-700">Recommendation</h2>
                {bhagya.map((val, index) => (
                    <div key={index}>
                        {renderTable(val)}
                    </div>
                ))}
            </div>

            <div>
                <h2 className="text-xl font-semibold text-gray-700">Important Information</h2>
                <p className="text-gray-600">
                    While wearing a gem, please keep certain things in mind. Purchase only original gemstones, as wearing those that are not original will have no effect. You also need to wear the prescribed weight, commonly known as 'Ratti'. Nowadays, markets are flooded with fake gems. To assist our readers, AstroCAMP/AstrologerDaddy offers a genuine collection of gemstones. To know more about it, click here.
                </p>
            </div>
        </div>
    );
}

export default GemstoneImage;

// import React from 'react';

// function GemstoneImage() {
//     const life = [{
//         "Recommend Gemstone": "Ruby (Manikya)",
//         "Qualities of the Gemstone": "2 Carats",
//         "Wearing instructions": "Gold, in ring finger",
//         "Mantra": "Om hram hrim hraum sah suryaya namah"
//     }];

//     const lucky = [{
//         "Recommend Gemstone": "Ruby (Manikya)",
//         "Qualities of the Gemstone": "2 Carats",
//         "Wearing instructions": "Gold, in ring finger",
//         "Mantra": "Om hram hrim hraum sah suryaya namah"
//     }];

//     const bhagya = [{
//         "Recommend Gemstone": "Ruby (Manikya)",
//         "Qualities of the Gemstone": "2 Carats",
//         "Wearing instructions": "Gold, in ring finger",
//         "Mantra": "Om hram hrim hraum sah suryaya namah"
//     }];

//     return (
//         <div className="p-6">
//             {/* Life Stone Section */}
//             <div className="mb-6">
//                 <h1 className="text-3xl font-bold mb-2 text-yellow-600">Life Stone for Leo (Simha Rasi)</h1>
//                 <p className="text-gray-700 mb-4">A life stone is a gem for the Lagna lord. One can wear it all through their life to experience its mystic powers. Wearing a life stone can remove obstacles and bless an individual with happiness, success, and prosperity. It is generally worn to bring about overall well-being. Its cosmic rays influence an individual’s entire existence.</p>
//                 <h2 className="text-2xl font-semibold mb-2 text-yellow-500">Recommendation</h2>
//                 <table className="table-auto w-full text-left bg-gray-50 rounded-lg shadow-md">
//                     <tbody>
//                         {life.map((item, index) => (
//                             <React.Fragment key={index}>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Recommend Gemstone</td>
//                                     <td className="px-4 py-2">{item["Recommend Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Qualities of the Gemstone</td>
//                                     <td className="px-4 py-2">{item["Qualities of the Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Wearing instructions</td>
//                                     <td className="px-4 py-2">{item["Wearing instructions"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Mantra</td>
//                                     <td className="px-4 py-2">{item["Mantra"]}</td>
//                                 </tr>
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>

//             {/* Lucky Stone Section */}
//             <div className="mb-6">
//                 <h2 className="text-2xl font-semibold mb-2 text-yellow-500">Lucky Stone</h2>
//                 <table className="table-auto w-full text-left bg-gray-50 rounded-lg shadow-md">
//                     <tbody>
//                         {lucky.map((item, index) => (
//                             <React.Fragment key={index}>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Recommend Gemstone</td>
//                                     <td className="px-4 py-2">{item["Recommend Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Qualities of the Gemstone</td>
//                                     <td className="px-4 py-2">{item["Qualities of the Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Wearing instructions</td>
//                                     <td className="px-4 py-2">{item["Wearing instructions"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Mantra</td>
//                                     <td className="px-4 py-2">{item["Mantra"]}</td>
//                                 </tr>
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>

//             {/* Bhagya Stone Section */}
//             <div className="mb-6">
//                 <h2 className="text-2xl font-semibold mb-2 text-yellow-500">Bhagya Stone</h2>
//                 <table className="table-auto w-full text-left bg-gray-50 rounded-lg shadow-md">
//                     <tbody>
//                         {bhagya.map((item, index) => (
//                             <React.Fragment key={index}>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Recommend Gemstone</td>
//                                     <td className="px-4 py-2">{item["Recommend Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Qualities of the Gemstone</td>
//                                     <td className="px-4 py-2">{item["Qualities of the Gemstone"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Wearing instructions</td>
//                                     <td className="px-4 py-2">{item["Wearing instructions"]}</td>
//                                 </tr>
//                                 <tr className="border-b">
//                                     <td className="px-4 py-2 font-semibold">Mantra</td>
//                                     <td className="px-4 py-2">{item["Mantra"]}</td>
//                                 </tr>
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>

//             {/* Important Information Section */}
//             <div className="mb-6">
//                 <h2 className="text-2xl font-semibold mb-2 text-yellow-500">Important Information</h2>
//                 <p className="text-gray-700">While wearing a gem, please keep certain things in mind. Purchase only original gemstones, as wearing fake gems will have no effect. You also need to wear the prescribed weight, commonly known as 'Ratti'. Nowadays, markets are flooded with fake gems. To give our readers genuine assistance, AstroCAMP/AstrologerDaddy brings you our unique collection of genuine gemstones.</p>
//             </div>
//         </div>
//     );
// }

// export default GemstoneImage;
