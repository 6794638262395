
import React from 'react';
import { FaPhoneAlt, FaComments } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

function CallChat() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        navigate("/astrologers");
    };


    const shouldRender = location.pathname !== '/' && !(location.pathname.includes("astrologer")) && !(location.pathname.includes("appointment"))

        && location.pathname !== "/login" && location.pathname !== "/user" && !(location.pathname.includes("payment-response"));

    return (
        shouldRender && (
           
        <div className='fixed bottom-0 left-0 right-0 bg-yellow-500 text-xl py-3 hidden lg:flex items-center justify-center'>
        <div className='flex flex-col items-center text-center'>
          <div className='flex flex-row items-center space-x-3'> {/* Space between items */}
            <h1 className='text-gray-700 flex-grow text-center'> {/* Flex-grow to take available space */}
              For more personalized detailed predictions.
            </h1>
            <button className='bg-white rounded-2xl px-4 py-2' onClick={handleClick}>
              <FaPhoneAlt className='inline-block mr-1 text-yellow-600' /> Talk to Astrologer
            </button>
            <button className='bg-white rounded-2xl px-4 py-2' onClick={handleClick}>
              <FaComments className='inline-block mr-1 text-yellow-600' /> Chat with Astrologer
            </button>
          </div>
        </div>
      </div>
      

        )
    );
}

export default CallChat;
