import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCalendarCheck, FaPhoneVolume, FaComments, FaStar, FaRupeeSign, FaUserClock } from 'react-icons/fa';
import { IoTimeOutline } from 'react-icons/io5';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

const AstrologerDashboard = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  
  const [profile, setProfile] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(false);

  // Calculate stats from the appointments data
  const calculateStats = (appointments) => {
    const today = new Date().toISOString().split('T')[0];
    const todayAppointments = appointments.filter(apt => 
      apt.date.split('T')[0] === today
    ).length;

    const totalCalls = appointments.filter(apt => apt.type === 'call').length;
    const totalChats = appointments.filter(apt => apt.type === 'chat').length;

    return [
      { title: "Today's Appointments", value: todayAppointments.toString(), icon: <FaCalendarCheck className="text-blue-500" size={24} /> },
      { title: "Total Calls", value: totalCalls.toString(), icon: <FaPhoneVolume className="text-green-500" size={24} /> },
      { title: "Total Chats", value: totalChats.toString(), icon: <FaComments className="text-purple-500" size={24} /> },
      { title: "Rating", value: profile?.averageRating?.toFixed(1) || "0.0", icon: <FaStar className="text-yellow-500" size={24} /> },
    ];
  };

  // Get upcoming appointments
  const getUpcomingAppointments = (appointments) => {    
    return appointments
      .map(apt => ({
        id: apt._id,
        userName: `${apt.user.firstName} ${apt.user.lastName}`,
        time: apt.time,
        date: new Date(apt.date).toLocaleDateString(),
        type: apt.type.charAt(0).toUpperCase() + apt.type.slice(1),
        duration: `${apt.duration} mins`,
      }))
      .slice(0, 5); // Show only next 5 appointments
  };

  const fetchProfile = async () => {
    try {
      const response = await api.get("/astrologer/profile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data);
    } catch (error) {
      setError("Failed to fetch profile. Please try again later.");
      console.error("Error fetching profile:", error);
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await api.get("/appointment/get-appointments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAppointments(response.data.appointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      if (error.response?.status === 401) {
        navigate("/login");
      }
    }
  };
  
  const toggleAvailability = async () => {
    try {
      // TODO: Implement availability toggle API call
      setIsOnline(!isOnline);
    } catch (error) {
      console.error("Error toggling availability:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchProfile();
      await fetchAppointments();
    }
    fetchData();
  }, [token]);

  const statsData = profile ? calculateStats(appointments) : [];
  const upcomingAppointments = appointments.length > 0 ? getUpcomingAppointments(appointments) : [];

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  if (!profile) {
    return <div className="text-center p-4">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Profile Section */}
        <div className="bg-gradient-to-r from-orange-500 to-yellow-500 rounded-xl p-6 mb-8 text-white">
          <div className="flex flex-col md:flex-row items-center gap-6">
            <img
              src={`https://astrologerdaddy.com/api/${profile.profileImage}`}
              alt={profile.name}
              className="w-20 h-20 rounded-full object-cover border-4 border-white shadow-lg"
            />
            <div className="text-center md:text-left">
              <h1 className="text-2xl md:text-3xl font-bold">{profile.name}</h1>
              <p className="text-purple-100 mt-1">
                {profile.specializations.map(spec => spec.name).join(" • ")}
              </p>
              <div className="flex flex-wrap justify-center md:justify-start gap-4 mt-3">
                <div className="flex items-center gap-1">
                  <FaStar className="text-yellow-300" />
                  <span>{profile.averageRating.toFixed(1)} ({profile.reviews.length} reviews)</span>
                </div>
                <div className="flex items-center gap-1">
                  <IoTimeOutline />
                  <span>{profile.experience}+ years exp.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-8">
          {statsData.map((stat, index) => (
            <div key={index} className="bg-white p-4 md:p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                <div className="order-2 md:order-1">
                  <p className="text-gray-600 text-sm">{stat.title}</p>
                  <p className="text-xl md:text-2xl font-bold text-gray-800 mt-1">{stat.value}</p>
                </div>
                <div className="order-1 md:order-2">{stat.icon}</div>
              </div>
            </div>
          ))}
        </div>

        {/* Current Status */}
        <div className="bg-white p-6 rounded-xl shadow-sm mb-8">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Your Current Status</h2>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              <div className="flex items-center">
                <div className={`w-3 h-3 rounded-full ${isOnline ? 'bg-green-500' : 'bg-red-500'} mr-2`}></div>
                <span className="text-sm text-gray-600">{isOnline ? 'Online' : 'Offline'}</span>
              </div>
              <button 
                onClick={toggleAvailability}
                className={`w-full sm:w-auto px-4 py-2 ${
                  isOnline ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                } text-white rounded-lg transition-colors`}
              >
                {isOnline ? 'Go Offline' : 'Go Online'}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center gap-2">
                <FaRupeeSign className="text-gray-500" />
                <span className="text-gray-600 font-medium">₹{profile.minutePrice}/minute</span>
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center gap-2">
                <FaUserClock className="text-gray-500" />
                <span className="text-gray-600 font-medium">Min. {profile.minimumCallDuration} mins</span>
              </div>
            </div>
          </div>
        </div>

        {/* Upcoming Appointments */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold text-gray-800">Upcoming Appointments</h2>
            <button className="text-purple-500 hover:text-purple-600 font-medium" onClick={() => navigate('/astrologer/appointments')}>View All</button>
          </div>
          <div className="space-y-4">
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map((appointment) => (
                <div key={appointment.id} className="p-4 bg-gray-100 rounded-lg shadow-sm flex justify-between">
                  <div className="flex flex-col">
                    <span className="text-gray-700 font-semibold">{appointment.userName}</span>
                    <span className="text-gray-500">{`${appointment.date} at ${appointment.time}`}</span>
                    <span className="text-gray-500">{`${appointment.type} - ${appointment.duration}`}</span>
                  </div>
                  <button className="text-blue-500 hover:text-blue-600 font-medium">Join</button>
                </div>
              ))
            ) : (
              <div className="text-gray-600 text-center">No upcoming appointments.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstrologerDashboard;




