import React, { memo } from 'react';
import { FaInfoCircle, FaListOl } from 'react-icons/fa';

const TableRow = memo(({ label, value }) => (
  <tr className="hover:bg-gray-50 transition-colors duration-150">
    <td className="py-3 px-4 border-b font-semibold text-gray-700">{label}</td>
    <td className="py-3 px-4 border-b text-gray-800">{value}</td>
  </tr>
));

const MahaDashaSection = memo(({ mahaDashaData }) => {
  if (!mahaDashaData) return null;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-8 text-center text-red-700">Maha Dasha Details</h2>

      <div className="grid grid-cols-1 gap-8">
        <div className="bg-gray-50 rounded-lg py-6 px-2 shadow-md transition-all duration-300 hover:shadow-lg">
          <h3 className="text-lg md:text-xl md:text-center font-semibold mb-4 flex items-center justify-start text-gray-700">
            <FaInfoCircle className="mx-2" />
            Basic Information
          </h3>
          <table className="w-full">
            <tbody>
              <TableRow label="Start Year" value={mahaDashaData.start_year} />
              <TableRow label="Dasha Start Date" value={mahaDashaData.dasha_start_date} />
              <TableRow label="Remaining at Birth" value={mahaDashaData.dasha_remaining_at_birth} />
            </tbody>
          </table>
        </div>

        <div className="bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg">
          <h3 className="text-xl text-center font-semibold mb-4 flex items-center justify-start text-gray-700">
            <FaListOl className="mr-2" />
            Dasha Sequence
          </h3>
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-3 px-4 border-b text-left text-gray-600">Mahadasha</th>
                <th className="py-3 px-4 border-b text-left text-gray-600">Start Date</th>
              </tr>
            </thead>
            <tbody>
              {mahaDashaData.mahadasha?.map((dasha, index) => (
                <tr key={dasha} className="hover:bg-gray-100 transition-colors duration-150">
                  <td className="py-3 px-4 border-b text-gray-800">{dasha}</td>
                  <td className="py-3 px-4 border-b text-gray-800">
                    {mahaDashaData.mahadasha_order[index]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default MahaDashaSection;