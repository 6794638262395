import React, { memo } from 'react';
import { 
  FaExclamationTriangle, 
  FaCheckCircle, 
  FaTimesCircle, 
  FaSatellite, 
  FaMars, 
  FaDragon, 
  FaArrowCircleRight 
} from 'react-icons/fa';

const ScoreIndicator = ({ score }) => {
  const getScoreColor = (score) => {
    if (score <= 25) return 'text-green-500';
    if (score <= 50) return 'text-yellow-500';
    if (score <= 75) return 'text-orange-500';
    return 'text-red-500';
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-md">
      <div className="text-2xl font-bold  mb-2">Manglik Score</div>
      <div className={`text-5xl font-bold ${getScoreColor(score)}`}>
        {score}%
      </div>
      <div className="mt-4 text-gray-600 text-center">
        {score <= 25 && 'Low Impact'}
        {score > 25 && score <= 50 && 'Moderate Impact'}
        {score > 50 && score <= 75 && 'Significant Impact'}
        {score > 75 && 'High Impact'}
      </div>
    </div>
  );
};

const StatusCard = ({ title, isPresent, icon: Icon }) => (
  <div className="bg-white p-4 rounded-lg shadow-md">
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Icon className="text-gray-600 text-xl mr-2" />
        <h4 className="font-semibold text-gray-700">{title}</h4>
      </div>
      {isPresent ? (
        <FaCheckCircle className="text-red-500 text-xl" />
      ) : (
        <FaTimesCircle className="text-green-500 text-xl" />
      )}
    </div>
  </div>
);

const ManglikDoshSection = memo(({ manglikDoshData }) => {
  if (!manglikDoshData) return null;

  return (
    <div className="bg-white py-8 md:px-8 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
      <div className="flex items-center justify-center mb-8">
        <FaExclamationTriangle className={`text-2xl ${manglikDoshData.is_manglik_present ? 'text-red-500' : 'text-green-500'} mr-3`} />
        <h2 className="text-xl md:text-3xl font-bold text-red-700">Manglik Dosh Analysis</h2>
      </div>

      <div className="grid grid-cols-1 gap-8">
        <ScoreIndicator score={manglikDoshData.score} />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <StatusCard 
            title="Mars Effect" 
            isPresent={manglikDoshData.manglik_by_mars} 
            icon={FaMars}
          />
          <StatusCard 
            title="Saturn Effect" 
            isPresent={manglikDoshData.manglik_by_saturn} 
            icon={FaSatellite}
          />
          <StatusCard 
            title="Rahu/Ketu Effect" 
            isPresent={manglikDoshData.manglik_by_rahuketu} 
            icon={FaDragon}
          />
        </div>

        {manglikDoshData.factors.length > 0 && (
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Contributing Factors</h3>
            <ul className="space-y-2">
              {manglikDoshData.factors.map((factor, index) => (
                <li key={index} className="flex items-start">
                  <FaExclamationTriangle className="text-yellow-500 mt-1 mr-2 flex-shrink-0" />
                  <span className="text-gray-600">{factor}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {manglikDoshData.aspects.length > 0 && (
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Planetary Aspects</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {manglikDoshData.aspects.map((aspect, index) => (
                <div key={index} className="flex items-center bg-white p-3 rounded-lg">
                  <FaArrowCircleRight className="text-blue-500 mr-2 flex-shrink-0" />
                  <span className="text-gray-600">{aspect}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="bg-yellow-50 p-6 rounded-lg border border-yellow-200">
          <div className="flex items-center">
            <FaExclamationTriangle className="text-yellow-500 mr-2 flex-shrink-0" />
            <p className="text-gray-700">{manglikDoshData.bot_response}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ManglikDoshSection;