import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
function KundliMatching() {
  const navigate = useNavigate();
  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
  const seconds = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

  // Generate days array with padded zeros (01-31)
  const days = [...Array(31).keys()].map((i) => String(i + 1).padStart(2, '0'));
  const months = [...Array(12).keys()].map((i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );
  const today = new Date();
  const todayYear = today.getFullYear();

  const [boyDateData, setBoyDateData] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [girlDateData, setGirlDateData] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [years, setYears] = useState(
    [...Array(100).keys()].map((i) => todayYear - i)
  );
  const [boyErrors, setBoyErrors] = useState("");
  const [girlErrors, setGirlErrors] = useState("");

  const [formData, setFormData] = useState({
    boyName: "",
    girlName: "",
    boyHour: "",
    boyMinute: "",
    boySecond: "",
    girlHour: "",
    girlMinute: "",
    girlSecond: "",
    boyCity: "",
    girlCity: "",
  });

  const [error, setError] = useState("");
  const [errorG, setErrorG] = useState("");

  const [boySearchInput, setBoySearchInput] = useState("");
  const [girlSearchInput, setGirlSearchInput] = useState("");
  const [boyCitySuggestions, setBoyCitySuggestions] = useState([]);
  const [girlCitySuggestions, setGirlCitySuggestions] = useState([]);
  const [selectedBoyCity, setSelectedBoyCity] = useState(null);
  const [selectedGirlCity, setSelectedGirlCity] = useState(null);

  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const mapboxToken =
    "pk.eyJ1IjoiYXN0cm9sb2dlcmRhZGR5IiwiYSI6ImNtMmE0aDFkZzBjcG0ybXE0ODFlcXF2ZmUifQ.NjJNQIDHyhlFVPm3l7bsCg";

  useEffect(() => {
    const updateYears = (dateData, setErrors) => {
      const { day, month, year } = dateData;

      const getMaxYear = () => {
        const selectedMonthIndex = months.indexOf(month) + 1;
        const selectedDay = parseInt(day, 10);

        if (month && day) {
          const selectedDate = new Date(
            todayYear,
            selectedMonthIndex - 1,
            selectedDay
          );
          if (selectedDate > today) {
            return todayYear - 1;
          }
        }
        return todayYear;
      };

      const maxYear = getMaxYear();
      setYears(
        [...Array(todayYear - (todayYear - 100) + 1).keys()]
          .map((i) => todayYear - i)
          .filter((year) => year <= maxYear)
      );

      if (day && month && year) {
        const selectedDate = new Date(
          `${year}-${String(months.indexOf(month) + 1).padStart(
            2,
            "0"
          )}-${String(day).padStart(2, "0")}`
        );
        if (selectedDate > today) {
          setErrors("The selected date cannot be greater than today.");
        } else {
          setErrors("");
        }
      }
    };

    updateYears(boyDateData, setBoyErrors);
    updateYears(girlDateData, setGirlErrors);
  }, [boyDateData, girlDateData]);

  const handleDateUpdate = (e, setter) => {
    const { name, value } = e.target;
    setter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeB = (e) => {
    const { name, value } = e.target;

    if (name === "girlName") {
      if (value.length > 50) {
        setErrorG("Name should not exceed 50 characters");
        setFormData({
          ...formData,
          [name]: value.substring(0, 50),
        });
      } else {
        setErrorG("");
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (boySearchInput.length > 2) {
      fetchCitySuggestions(boySearchInput, setBoyCitySuggestions);
    } else {
      setBoyCitySuggestions([]);
    }
  }, [boySearchInput]);

  useEffect(() => {
    if (girlSearchInput.length > 2) {
      fetchCitySuggestions(girlSearchInput, setGirlCitySuggestions);
    } else {
      setGirlCitySuggestions([]);
    }
  }, [girlSearchInput]);

  const fetchCitySuggestions = async (input, setSuggestions) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?country=IN&types=place&access_token=${mapboxToken}`
      );
      setSuggestions(response.data.features);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };

  const handleBoySearchChange = (e) => {
    setBoySearchInput(e.target.value);
    setFormData({ ...formData, boyCity: e.target.value });
  };

  const handleGirlSearchChange = (e) => {
    setGirlSearchInput(e.target.value);
    setFormData({ ...formData, girlCity: e.target.value });
  };

  const handleBoySearchSelect = (city) => {
    setSelectedBoyCity(city);
    setFormData({ ...formData, boyCity: city.place_name });
    setBoySearchInput(city.place_name);
    setBoyCitySuggestions([]);
  };

  const handleGirlSearchSelect = (city) => {
    setSelectedGirlCity(city);
    setFormData({ ...formData, girlCity: city.place_name });
    setGirlSearchInput(city.place_name);
    setGirlCitySuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedBoyCity || !selectedGirlCity) {
      alert("Please select valid cities for both boy and girl.");
      return;
    }

    const boyLatitude = selectedBoyCity.center[1];
    const boyLongitude = selectedBoyCity.center[0];
    const girlLatitude = selectedGirlCity.center[1];
    const girlLongitude = selectedGirlCity.center[0];

    // Format date as dd/mm/yyyy
    const formatDate = (dateData) => {
      const monthIndex = months.indexOf(dateData.month) + 1;
      return `${dateData.day}/${String(monthIndex).padStart(2, '0')}/${dateData.year}`;
    };

    // Format time as HH:MM
    const formatTime = (hour, minute) => {
      return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
    };

    const apiParams = {
      boy_name: formData.boyName,
      girl_name: formData.girlName,
      boy_city: selectedBoyCity.place_name,
      girl_city: selectedGirlCity.place_name,
      boy_dob: formatDate(boyDateData),
      boy_tob: formatTime(formData.boyHour, formData.boyMinute),
      boy_tz: 5.5,
      boy_lat: boyLatitude,
      boy_lon: boyLongitude,
      girl_dob: formatDate(girlDateData),
      girl_tob: formatTime(formData.girlHour, formData.girlMinute),
      girl_tz: 5.5,
      girl_lat: girlLatitude,
      girl_lon: girlLongitude,
      lang: language,
    };

    navigate("/kundli-matching/details", {
      state: { apiParams },
    });
  }
  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
     // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];
  return (
    <div className="mx-4 md:mx-20 my-4 lg:my-12 lg:mx-40">
       <div  className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>
    <h1 className="font-bold text-sm md:text-xl lg:text-3xl mt-2 text-center text-red-700">
        Kundli-Matching Online
      </h1>
      <h1 className="text-[10px] md:text-lg lg:text-xl text-[#616161] font-semibold my-2 mx-auto">
      Free Kundali Matching - Horoscope Matching | Kundali Matching | Kundli Match for Marriage
      </h1>
      <div className="max-w-8xl mx-auto mt-4">
  <p className="text-justify text-[12px] md:text-lg lg:text-xl leading-relaxed mb-4">
    Looking for a reliable way to assess relationship compatibility? Visit 
    <a href="https://astrologerdaddy.com" className="text-blue-500 underline font-semibold"> AstrologerDaddy.com </a>
    for Free Kundli-Matching Online. Kundli matching, also known as horoscope matching, is a traditional Vedic astrology method used to evaluate the compatibility between two individuals, especially before marriage. This ancient practice examines the alignment of the planets and stars in the birth charts (Kundlis) of both partners to assess how well they are suited for each other.
  </p>
  
  <p className="text-justify text-[12px] md:text-lg lg:text-xl leading-relaxed mb-4">
    At 
    <a href="https://astrologerdaddy.com" className="text-blue-500 underline font-semibold"> AstrologerDaddy.com </a>, 
    the free Kundli-matching service provides a detailed analysis of key factors such as emotional harmony, financial compatibility, and long-term stability in a relationship. The system calculates essential aspects like Guna Milan, which rates compatibility based on 36 points. It also identifies potential Doshas (astrological imbalances) that could affect the relationship and offers remedies to mitigate any negative influences.
  </p>
  
  <p className="text-justify text-[12px] md:text-lg lg:text-xl leading-relaxed">
    Whether you're in a serious relationship or considering marriage, this free online service helps ensure that your union is astrologically favorable. The easy-to-use platform makes it simple to input birth details for both partners and receive instant, accurate results. For peace of mind and astrological insights into your love life, try Free Kundli-Matching Online at 
    <a href="https://astrologerdaddy.com" className="text-blue-500 underline font-semibold"> AstrologerDaddy.com </a> today!
  </p>
</div>


      <div className="container p-3 max-w-full md:max-w-4xl mx-auto rounded-lg shadow-2xl border bg-white">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="max-w-lg w-full mx-auto p-3 bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="grid grid-cols-1 gap-4">
                <div className="mb-3">
                  <div className="text-lg cursor-default border py-1 flex items-center justify-center bg-yellow-500 text-white w-full rounded-lg">
                    Boy's Detail
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Boy's Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.boyName}
                    onChange={handleChangeB}
                    placeholder="Enter Boy's Name"
                    name="boyName"
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    required
                    pattern="[A-Za-z\s]+"
                    title="Name should only contain letters and spaces"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Date of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex gap-2">
                    <select
                      name="day"
                      value={boyDateData.day}
                      onChange={(e) => handleDateUpdate(e, setBoyDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Day</option>
                      {days.map((day, index) => (
                        <option key={index} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    <select
                      name="month"
                      value={boyDateData.month}
                      onChange={(e) => handleDateUpdate(e, setBoyDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Month</option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select
                      name="year"
                      value={boyDateData.year}
                      onChange={(e) => handleDateUpdate(e, setBoyDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Year</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  {boyErrors && (
                    <p className="text-red-500 text-sm mt-2">{boyErrors}</p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Time of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex gap-2">
                    <select
                      name="boyHour"
                      value={formData.boyHour}
                      onChange={handleChangeB}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Hour</option>
                      {hours.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    <select
                      name="boyMinute"
                      value={formData.boyMinute}
                      onChange={handleChangeB}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Minute</option>
                      {minutes.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                    <select
                      name="boySecond"
                      value={formData.boySecond}
                      onChange={handleChangeB}
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Second</option>
                      {seconds.map((second) => (
                        <option key={second} value={second}>
                          {second}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Boy's City<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    value={boySearchInput}
                    onChange={handleBoySearchChange}
                    placeholder="Enter Boy's City"
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    required
                  />
                  {boyCitySuggestions.length > 0 && (
                    <ul className="border border-gray-300 rounded-lg mt-2 max-h-48 overflow-y-auto">
                      {boyCitySuggestions.map((city, index) => (
                        <li
                          key={index}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleBoySearchSelect(city)}
                        >
                          {city.place_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <div className="max-w-lg w-full mx-auto p-3 bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="grid grid-cols-1 gap-4">
                <div className="mb-3">
                  <div className="text-lg cursor-default border py-1 flex items-center justify-center bg-yellow-500 text-white w-full rounded-lg">
                    Girl's Detail
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Girl's Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.girlName}
                    onChange={handleChangeB}
                    placeholder="Enter Girl's Name"
                    name="girlName"
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    required
                    pattern="[A-Za-z\s]+"
                    title="Name should only contain letters and spaces"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Date of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex gap-2">
                    <select
                      name="day"
                      value={girlDateData.day}
                      onChange={(e) => handleDateUpdate(e, setGirlDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Day</option>
                      {days.map((day, index) => (
                        <option key={index} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    <select
                      name="month"
                      value={girlDateData.month}
                      onChange={(e) => handleDateUpdate(e, setGirlDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Month</option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select
                      name="year"
                      value={girlDateData.year}
                      onChange={(e) => handleDateUpdate(e, setGirlDateData)}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Year</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  {girlErrors && (
                    <p className="text-red-500 text-sm mt-2">{girlErrors}</p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Time of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex gap-2">
                    <select
                      name="girlHour"
                      value={formData.girlHour}
                      onChange={handleChangeB}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Hour</option>
                      {hours.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    <select
                      name="girlMinute"
                      value={formData.girlMinute}
                      onChange={handleChangeB}
                      required
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Minute</option>
                      {minutes.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                    <select
                      name="girlSecond"
                      value={formData.girlSecond}
                      onChange={handleChangeB}
                      className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    >
                      <option value="">Second</option>
                      {seconds.map((second) => (
                        <option key={second} value={second}>
                          {second}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mb-2">
                  <label className="block text-gray-800 font-semibold text-lg mb-1">
                    Girl's City<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    value={girlSearchInput}
                    onChange={handleGirlSearchChange}
                    placeholder="Enter Girl's City"
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                    required
                  />
                  {girlCitySuggestions.length > 0 && (
                    <ul className="border border-gray-300 rounded-lg mt-2 max-h-48 overflow-y-auto">
                      {girlCitySuggestions.map((city, index) => (
                        <li
                          key={index}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleGirlSearchSelect(city)}
                        >
                          {city.place_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full p-2">
            <button className="w-full p-2 text-white text-lg bg-orange-500 hover:text-white rounded-lg hover:bg-orange-600">
              Match Horoscope
            </button>
          </div>
        </form>
      </div>
      <div  className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-80 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
        </div>

     
    </div>
  );
}

export default KundliMatching;