

import React, { memo } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const CurrentMahadasha = memo(({ currentMahadashaData }) => {
    if (!currentMahadashaData) return null;

    const dashaPeriods = [
        { name: "Mahadasha", data: currentMahadashaData.mahadasha },
        { name: "Antardasha", data: currentMahadashaData.antardasha },
        { name: "Paryantardasha", data: currentMahadashaData.paryantardasha },
        { name: "Shookshamadasha", data: currentMahadashaData.Shookshamadasha },
        { name: "Pranadasha", data: currentMahadashaData.Pranadasha },
    ];

    const formatDate = (date) => {
        if (!date) return 'N/A';
        const newDate = new Date(date);
        return newDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <div className="bg-gradient-to-br py-8 md:px-8 rounded-lg shadow-lg mx-auto max-w-7xl">
            <h2 className="text-2xl md:text-4xl font-bold mb-8 text-red-700 text-center">Current Dasha Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {dashaPeriods.map((dashaPeriod, index) => (
                    <div key={index} className="bg-white rounded-xl p-6 shadow-lg hover:shadow-2xl">
                        <h3 className="text-xl font-semibold mb-4 text-gray-800 text-center">{dashaPeriod.name}</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="text-gray-600 text-left text-sm uppercase tracking-wide">
                                        <th className="py-3 px-4 border-b">Dasha</th>
                                        <th className="py-3 px-4 border-b">Start Date</th>
                                        <th className="py-3 px-4 border-b">End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="hover:bg-gray-100 transition-colors duration-150">
                                        <td className="py-3 px-4 border-b text-gray-800 text-nowrap">{dashaPeriod.data.name}</td>
                                        <td className="py-3 px-4 border-b text-gray-800 text-nowrap">
                                            <FaCalendarAlt className="mr-2 text-gray-500 inline-block" />
                                            {formatDate(dashaPeriod.data.start)}
                                        </td>
                                        <td className="py-3 px-4 border-b text-gray-800 text-nowrap">
                                            <FaCalendarAlt className="mr-2 text-gray-500 inline-block" />
                                            {formatDate(dashaPeriod.data.end)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default CurrentMahadasha;
