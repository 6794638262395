import {memo} from 'react'

// Memoized Personal Characteristics Section
const PersonalCharacteristicsSection = memo(({ characteristics }) => (


<div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-red-700">Personal Characteristics</h2>
      {characteristics.map((item) => (
        <div
          key={item.current_house}
          className="overflow-x-auto bg-white p-4 mb-6 rounded-lg shadow-md border border-gray-300"
        >
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="border-b-2 border-gray-300 p-4  text-black-600 uppercase text-sm font-semibold">
                  House No
                </th>
                <th className="border-b-2 border-gray-300 p-4  text-black-600 uppercase text-sm font-semibold">
                  Lord of House Sign
                </th>
                <th className="border-b-2 border-gray-300 p-4  text-black-600 uppercase text-sm font-semibold">
                  Current Zodiac
                </th>
                <th className="border-b-2 border-gray-300 p-4  text-black-600 uppercase text-sm font-semibold">
                  Lord Strength
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white hover:bg-gray-50">
                <td className="border-b border-gray-300 p-4 text-center text-gray-700">
                  {item.current_house}
                </td>
                <td className="border-b border-gray-300 p-4 text-center text-gray-700">
                  {item.lord_of_zodiac}
                </td>
                <td className="border-b border-gray-300 p-4 text-center text-gray-700">
                  {item.current_zodiac}
                </td>
                <td className="border-b border-gray-300 p-4 text-center text-gray-700">
                  {item.lord_strength}
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="border-t border-gray-300 p-4 bg-gray-50 text-gray-600 italic">
                  {item.personalised_prediction}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
));

export default PersonalCharacteristicsSection