import React from 'react';
import { CiShare2 } from "react-icons/ci";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaQuora, FaPinterest } from 'react-icons/fa';
import { FaWhatsapp, FaCopy } from "react-icons/fa";
import astrobg from "../assets/astro_logobg.jpg"
function BlogDetails() {
    const categories = [
        "Home", "Tarot", "Vastu", "Vedic", "Kundli", "Sports", "Transits",
        "Festivals", "Business", "Gemstone", "Numerology", "Zodiac Signs",
        "Compatibility", "Entertainment", "Current Affairs", "Daily Horoscope",
        "Mythological Tales", "Web Stories"
    ];
    const trending = [
        "Astrodaddy Birthday: How to get free chat on Astrodaddy",
        "Meet the Founder: Puneet Gupta’s Inspiring Journey Behind Astrodaddy",
        "Avoid Doing And Buying These Things On Tuesday",
        "Astrodaddy Journey: From failed startup to biggest astrology Platform"
    ];
    const language = [
       "English", "Hindi"
    ];

    return (
        <div className="py-12 w-4/5 mx-auto">

            <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between w-full  lg:p-6 md:p-3 p-1 rounded-lg shadow-lg gap-6">
                <div className="lg:w-2/3 w-full  rounded-lg overflow-hidden">
                    <div className="p-3 bg-white rounded-lg shadow-lg">
                        <h1 className="text-3xl font-bold text-gray-800 mb-4">4 Zodiac Signs That Are Destined for Greatness</h1>

                        <div className="flex items-center justify-between mb-6">
                            {/* <div className="flex items-center space-x-2">
                                <img
                                    src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                                    alt="Blog"
                                    className="w-16 h-16 object-cover rounded-full border-2 border-gray-300"
                                />

                                <div>
                                    <p className="text-lg font-semibold text-gray-700">Ajay S</p>
                                    <p className="text-sm text-gray-500">Nov 13, 2023</p>
                                </div>
                            </div>

                            
                            <div className="flex items-center space-x-2 cursor-pointer ml-auto">
                                <p className="text-sm text-gray-700">Share</p>

                                <CiShare2 />
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                            alt="Blog"
                            className="w-full h-64 lg:h-full object-cover rounded-t-lg"
                        />
                    


                    </div>







                    <div className="p-6 text-gray-800 bg-white">
                        <p className="mb-4">
                            <span className="font-semibold">The</span>, founder and CEO of Astrologer Daddy, launched the app with a mission to bring authentic astrology services to everyone. His journey began with a life-changing prediction during a challenging phase in his career. An astrologer’s insight gave Puneet the confidence and guidance he needed, helping him make pivotal decisions that eventually led to his success.
                        </p>
                        <p className="mb-4">
                            This powerful experience made Puneet a believer in astrology’s potential and inspired him to create a platform where others could benefit from similar guidance. By establishing Astrologer Daddy, Puneet aimed to connect people with reliable astrologers who provide accurate and meaningful insights.
                        </p>

 <div>
    <img src={astrobg} alt="" srcset="" className='w-full lg:max-h-56 max-h-36 object-cover'  />
    </div>                       
                        <p>
                            Today, the app is not just a successful venture but a way for Puneet to bring traditional wisdom into the modern world, bridging ancient knowledge with cutting-edge technology.
                        </p>
                    </div>

                    <div>
                        <div className='flex items-center justify-end gap-5'>
                        <p className="text-sm text-gray-500">Nov 13, 2023</p>
                            <p className="text-lg font-semibold text-gray-700">Ajay S</p>
                           
                        </div>

                    </div>
                    {/* Share Section Aligned Right */}
                    <div className="flex items-center justify-end space-x-2 cursor-pointer ml-auto">
                        {/* <p className="text-sm text-gray-700">Share</p>

                                <CiShare2 /> */}
                        <div className="flex lg:space-x-4 space-x-1 mt-6">
                            {[
                                { icon: <FaFacebook size={24} />, link: "https://facebook.com/astrologerdaddy" },
                               
                                { icon: <FaInstagram size={24} />, link: "https://instagram.com/astrologerdaddy/" },
                                { icon: <FaWhatsapp size={24} />, link: "#" },
                                { icon: <FaCopy size={24} />, link: "#" }, // Placeholder link for the copy functionality
                                
                            ].map((social, index) => (
                                <a
                                    key={index}
                                    href={social.link}
                                    className=" hover:text-gray-400 text-slate-600 transition-colors duration-300 w-fit"
                                >
                                    {social.icon}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                <aside className="lg:w-1/3 w-full bg-white p-6 rounded-lg shadow-md">
                    <div className="mb-6">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Categories</h2>
                        <div className="grid grid-cols-2 gap-2">
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    className="border border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors w-full text-center"
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Trending Now</h2>
                        <div className="flex flex-col gap-2">
                            {trending.map((trend, index) => (
                                <button
                                    key={index}
                                    className="bg-white border border-yellow-400 hover:bg-yellow-100 text-gray-700 py-2 px-4 rounded-md shadow text-xs font-medium transition-colors w-full text-left"
                                >
                                    {trend}
                                </button>
                            ))}
                        </div>
                    </div>
                    
 <div>
    <img src={astrobg} alt="" srcset="" className='w-full lg:max-h-56 max-h-36 object-cover'  />
    </div>
                    <div>
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">Language</h2>
                        <div className="flex gap-2">
                            {language.map((lang, index) => (
                                <button
                                    key={index}
                                    className="bg-white hover:bg-yellow-100 text-gray-700 py-2 px-4 border border-yellow-400 rounded-md shadow text-xs font-medium transition-colors"
                                >
                                    {lang}
                                </button>
                            ))}
                        </div>
                    </div>
                </aside>
            </div>
            <div className='my-10'>
                <h1 className='text-center mt-5 text-3xl font-bold text-gray-800 mb-4 '>Related Blogs</h1>
                <div className='grid grid-cols-4 gap-3'>

                <div>
                        <img
                            src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                            alt="Blog"
                            className="w-full h-64 lg:h-full object-cover rounded-t-lg"
                        />
                        <p>meet the founder the inspiring journey</p>
                    


                    </div>
                    <div>
                        <img
                            src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                            alt="Blog"
                            className="w-full h-64 lg:h-full object-cover rounded-t-lg"
                        />
                        <p>meet the founder the inspiring journey</p>
                    


                    </div>
                    <div>
                        <img
                            src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                            alt="Blog"
                            className="w-full h-64 lg:h-full object-cover rounded-t-lg"
                        />
                        <p>meet the founder the inspiring journey</p>
                    


                    </div>
                    <div>
                        <img
                            src="https://d2wkagtnczagqh.cloudfront.net/astrology-blog/wp-content/uploads/2024/04/29151234/beautiful-woman-with-long-brown-hair-smiling-camera-generated-by-artificial-intelligence_188544-124685.jpg"
                            alt="Blog"
                            className="w-full h-64 lg:h-full object-cover rounded-t-lg"
                        />
                        <p>meet the founder the inspiring journey</p>
                    


                    </div>

                </div>
            </div>

        </div>
    );
}

export default BlogDetails;
