import React from "react";
import BannerSection from "../sections/BannerSection";
import OurAstrologersSection from "../sections/OurAstrologersSection";
import AdSection from "../sections/AdSection";
import CustomerStories from "../sections/CustomerStories";
import AboutSection from "../sections/AboutSection";
import SliderCard from "../sections/SliderCard";


function Home() {
  return (
    <>
      <BannerSection />
      <SliderCard />
      <OurAstrologersSection />
      <AdSection />
      <AboutSection/>
      <CustomerStories />
    </>
  );
}

export default Home;
