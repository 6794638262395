import React from 'react';
import { FaClock } from 'react-icons/fa';

const HoraMuhurta = ({ data }) => {
  if (!data?.horas || !Array.isArray(data.horas)) {
    return null;
  }

  const getHoraColor = (hora) => {
    const horaColors = {
      'Sun': 'bg-orange-50 text-orange-700',
      'Moon': 'bg-slate-50 text-slate-700',
      'Mars': 'bg-red-50 text-red-700',
      'Mercury': 'bg-emerald-50 text-emerald-700',
      'Jupiter': 'bg-yellow-50 text-yellow-700',
      'Venus': 'bg-pink-50 text-pink-700',
      'Saturn': 'bg-purple-50 text-purple-700'
    };
    return horaColors[hora] || 'bg-gray-50 text-gray-700';
  };

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-slate-900 mb-2">Hora Muhurta</h2>
        <p className="text-slate-600">Planetary Hours and Their Benefits</p>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-6">
        <div className="flex items-center gap-2 mb-6">
          <FaClock className="w-6 h-6 text-blue-600" />
          <h3 className="text-xl font-semibold text-slate-800">
            Planetary Hours for {data.day_of_week}
          </h3>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-slate-100">
                <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Time</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Hora</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Benefits</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900">Lucky Gems</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-slate-200">
              {data.horas.map((hora, index) => (
                <tr key={index} className="hover:bg-slate-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {hora.start} - {hora.end}
                  </td>
                  <td className="px-6 py-4">
                    <span className={`px-2.5 py-1 rounded-full text-sm ${getHoraColor(hora.hora)}`}>
                      {hora.hora}
                    </span>
                  </td>
                  <td className="px-6 py-4 max-w-md">
                    <p className="text-sm text-slate-600 line-clamp-2">
                      {hora.benefits}
                    </p>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-sm text-slate-600">
                      {hora.lucky_gem}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HoraMuhurta;