import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import { StreamVideo, StreamVideoClient } from "@stream-io/video-react-sdk";

import Home from "./screens/Home";
import Astrologer from "./screens/Astrologer";
import Navbar from "./components/Navbar";

import Footer from "./sections/Footer";
import LoginCustomer from "./screens/LoginCustomer";
import Kundli from "./screens/Kundli";
import Profile from "./screens/Profile";
import AudioAppointment from "./screens/AudioAppointment";

import { useAuth } from "./contexts/AuthContext";
import { useClient } from "./contexts/ClientContext";
import Astrologers from "./screens/Astrologers";
import PaymentResponse from "./screens/PaymentResponse";

import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import Disclaimer from "./screens/Disclaimer";
import PricingPolicy from "./screens/PricingPolicy";
import AboutUs from "./screens/AboutUs";
import ScrollToTop from "./utils/ScrollToTop";
import Appointments from "./screens/Appointments";
import Wallet from "./screens/Wallet";
import ChatPage from "./screens/ChatPage";
import { useEffect } from "react";
import api from "./api";
import KundliMatching from "./screens/KundliMatching";
import Horoscope from "./screens/Horoscope";
import HoroscopePage from "./screens/HoroscopePage";
import CallChat from "./components/CallChat";
import KundliDetails from "./screens/KundliDetails";
import Numerology from "./screens/Numerology";
import Gemstone from "./screens/Gemstone";
import GemstoneImage from "./screens/GemstoneImage";
import Panchang from "./screens/Panchang";
import KundliMatchingDetails from "./screens/KundliMatchingDetails";
import Compatibility from "./screens/Compatibility";
import AstrologerDashboard from "./screens/AstrologerDashboard";
import PoojaBooking from "./screens/PoojaBooking";
import PoojaDetails from "./components/PoojaDetails";
import Blog from "./screens/Blog";
import BlogDetails from "./components/BlogDetails";
import Shop from "./screens/Shop";
import Shopdetails from "./screens/Shopdetails";
import ShopDetailsDescription from "./screens/ShopDetailsDescription";

export default function App() {
  const navigate = useNavigate();
  const { token, userCallToken } = useAuth();
  const { setClient, client } = useClient();
  const user = localStorage.getItem("user");

  useEffect(() => {
    async function fetchData() {
      try {
        await api.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (userCallToken) {
          const apiKey = "gswhjwekwcfc";

          let user = null;
          let userId = null;
          try {
            user = JSON.parse(localStorage.getItem("user"));
            userId = user ? user._id : null;
            user.id = userId;
            user.userToken = userCallToken;

            const client = new StreamVideoClient({
              apiKey,
              user,
              token: userCallToken,
            });

            setClient(client);
          } catch (error) {
            console.error("Error", error);
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        }
      }
    }
    if (token && userCallToken && user) {
      fetchData();
    }
  }, [token, userCallToken]);

  return (
    <StreamVideo client={client}>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" Component={Home} />

        <Route path="/login" Component={LoginCustomer} />

        <Route path="/gemstone" Component={Gemstone} />
        <Route path="/gemstone/:zodiac" Component={GemstoneImage} />
        <Route path="/user">
          <Route
            index
            element={token && user ? <Profile /> : <Navigate to="/login" />}
          />
          <Route path="wallet" Component={Wallet} />
          <Route
            path="payment-response/:transactionId"
            Component={PaymentResponse}
          />
          <Route path="appointments" Component={Appointments}></Route>
          <Route
            path="appointment/call/:call_id"
            Component={AudioAppointment}
          ></Route>
          <Route path="appointment/chat/:chat_id" Component={ChatPage}></Route>
        </Route>

        <Route path="/astrologers" Component={Astrologers} />
        <Route path="/astrologer/:astrologer_id" Component={Astrologer} />

        <Route path="/panchang" Component={Panchang} />

        <Route path="/numerology" Component={Numerology}  />

        <Route path="privacy-policy" Component={PrivacyPolicy} />
        <Route path="refund-policy" Component={RefundPolicy} />
        <Route path="pricing-policy" Component={PricingPolicy} />
        <Route path="terms-and-conditions" Component={TermsAndConditions} />
        <Route path="disclaimer" Component={Disclaimer} />
        <Route path="about-us" Component={AboutUs} />
        <Route path="kundli-making" Component={Kundli} />
        <Route path="kundli/details" Component={KundliDetails} />
        <Route path="kundli-matching" Component={KundliMatching} />
        <Route path="numerology" Component={Numerology} />
        <Route
          path="kundli-matching/details"
          Component={KundliMatchingDetails}
        />
        <Route path="horoscope/:zodiac" Component={Horoscope} />
        <Route path="horoscope-page" Component={HoroscopePage} />
        <Route path="compatibility" Component={Compatibility} />
        <Route path="astrologer-dashboard" Component={AstrologerDashboard} />
       <Route path="pooja-booking" Component={PoojaBooking} />
        <Route path="/poojadetails" Component={PoojaDetails} />
        <Route path="blog" Component={Blog} /> 
       <Route path="/blog-details" Component={BlogDetails} />
        <Route path="shop" Component={Shop} />
        <Route path="shop-details" Component={Shopdetails} />
        <Route path="shop-description" Component={ShopDetailsDescription} />
      </Routes>
      <CallChat />
      <Footer />
    </StreamVideo>
  );
}
