import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import astro from "../assets/astro_img.png";
import AstrologerCard from "../components/AstrologerCard";
import useOnCallChatClickHandler from "../hooks/useOnCallChatClickHandler";

import {
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
  FaChevronLeft,
  FaChevronRight,
  FaPhoneAlt,
  FaComments,
  FaCommentDots,
} from "react-icons/fa";

import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Spinner from "../components/Spinner";
import { useAuth } from "../contexts/AuthContext";

function Astrologer() {
  const { token } = useAuth();
  const params = useParams();

  const [astrologer, setAstrologer] = useState({});
  const [suggestedAstrologers, setSuggestedAstrologers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fallbackReviews = [
    {
      user: { firstName: "Rohit", lastName: "Sharma" },
      rating: 4.5,
      comment: "Very insightful and accurate predictions. Highly recommended!",
    },
    {
      user: { firstName: "Sanya", lastName: "Singh" },
      rating: 4.0,
      comment: "Had a great session, learned a lot about myself and my future.",
    },
    {
      user: { firstName: "Anil", lastName: "Kumar" },
      rating: 5.0,
      comment: "Excellent astrologer! The reading was spot on.",
    },
  ];

  useEffect(() => {
    const fetchAstrologer = async () => {
      try {
        const response = await api.get(`/astrologers/${params.astrologer_id}`);
        const data = await response.data;
        setAstrologer(data);
        // Fetch suggested astrologers based on current astrologer's specializations
        const specializations = response.data.specializations
          .map((sp) => sp._id)
          .join(",");
        const suggestionsResponse = await api.get(`/astrologers`, {
          params: { specializations, suggestions: true },
        });
        setSuggestedAstrologers(suggestionsResponse.data.astrologers);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchAstrologer();
  }, [params.astrologer_id]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-yellow-500 inline" />);
      } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
        stars.push(
          <FaStarHalfAlt key={i} className="text-yellow-500 inline" />
        );
      } else {
        stars.push(<FaRegStar key={i} className="text-yellow-500 inline" />);
      }
    }
    return stars;
  };

  const imageSliderSettings = {
    infinite: astrologer.aboutImages?.length > 1,  // Only enable infinite if more than 1 image
    speed: 500,
    slidesToShow: Math.min(3, astrologer.aboutImages?.length || 1), // Show max 3 or total images
    slidesToScroll: 1,
    autoplay: astrologer.aboutImages?.length > 1,  // Only autoplay if more than 1 image
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, astrologer.aboutImages?.length || 1), // Show max 2 or total images
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dummyImages = [
    "https://via.placeholder.com/300x200?text=Image+1",
    "https://via.placeholder.com/300x200?text=Image+2",
    "https://via.placeholder.com/300x200?text=Image+3",
    "https://via.placeholder.com/300x200?text=Image+4",
    "https://via.placeholder.com/300x200?text=Image+5",
    "https://via.placeholder.com/300x200?text=Image+6",
    "https://via.placeholder.com/300x200?text=Image+7",
    "https://via.placeholder.com/300x200?text=Image+8",
    "https://via.placeholder.com/300x200?text=Image+9",
    "https://via.placeholder.com/300x200?text=Image+10",
  ];

  const onCallChatClickHandler = useOnCallChatClickHandler(
    token,
    setLoading,
    astrologer.minimumCallDuration,
    astrologer.minutePrice,
    astrologer.discountedPrice
  );

  // Custom Arrow Components
  const CustomPrevArrowLeft = ({ onClick }) => (
    <button
      className="absolute -left-10 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300 z-10"
      onClick={onClick}
    >
      <FaChevronLeft />
    </button>
  );

  const CustomNextArrowRight = ({ onClick }) => (
    <button
      className="absolute -right-10 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300 z-10"
      onClick={onClick}
    >
      <FaChevronRight />
    </button>
  );

  const suggestedSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    prevArrow: <CustomPrevArrowLeft />,
    nextArrow: <CustomNextArrowRight />,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const calculateRatingData = (reviews) => {
    const ratingCounts = [0, 0, 0, 0, 0]; // Array to hold the count of each star rating

    reviews.forEach((review) => {
      if (review.rating) {
        const starRating = Math.floor(review.rating);
        ratingCounts[starRating - 1] += 1;
      }
    });

    const totalReviews = reviews.length;
    return ratingCounts.map((count, index) => ({
      stars: index + 1,
      value: (count / totalReviews) * 100, // percentage
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner className="w-8 h-8" />
      </div>
    );
  }

  const ratingData = calculateRatingData(astrologer.reviews || []);

  return (
    <div className="min-h-screen p-4 bg-yellow-500">
      <div
        className="relative max-w-7xl mx-auto bg-white p-4 sm:p-8 rounded-lg shadow-lg"
        style={{
          backgroundImage: `url(${astro})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Semi-transparent overlay */}
        <div className="absolute inset-0 bg-white/90 rounded-lg"></div>

        <div className="relative z-10 grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 items-center">
          {/* First Section */}
          <div className="md:col-span-4">
            <div className="flex items-start space-x-4 md:block">
              {/* Image */}
              <div className="relative flex-shrink-0">
                <img
                  src={`https://astrologerdaddy.com/api/${astrologer.profileImage}`}
                  alt={`${astrologer.name} - Astrologer`}
                  className="w-24 h-24 md:w-64 md:h-64 rounded-full object-cover border-4 border-yellow-500 shadow-xl"
                />
                {/* <div
                  className={`absolute -top-1 -right-1 md:-top-2 md:-right-2 w-4 h-4 md:w-6 md:h-6 rounded-full ${!astrologer.availability
                      ? "bg-red-500"
                      : astrologer.inCall
                        ? "bg-yellow-600"
                        : "bg-green-500"
                    } border-2 border-white`}
                ></div> */}
              </div>

              {/* Mobile Basic Info */}
              <div className="flex-1 md:hidden mt-2">
                <h2 className="text-xl font-bold text-gray-800">
                  {astrologer.displayName || (astrologer.name.split(" ")[0] !== "" && astrologer.name.split(" ")[0])}
                </h2>
                <p className="font-semibold">₹{astrologer.discountedPrice ?? astrologer.minutePrice}/min</p>
                <p className="text-xs mb-2">Min duration: {astrologer.minimumCallDuration} min</p>

                {/* Specializations */}
                <div className="flex flex-wrap gap-1 mb-2">
                  {astrologer.specializations.map((spec, index) => (
                    <span
                      key={index}
                      className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full"
                    >
                      {spec.name}
                    </span>
                  ))}
                </div>

                {/* Languages */}
                <div className="flex flex-wrap gap-1">
                  {astrologer.languages.map((language, index) => (
                    <span
                      key={index}
                      className="bg-purple-100 text-purple-800 text-xs font-medium px-2 py-0.5 rounded-full"
                    >
                      {language}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Middle Section */}
          <div className="md:col-span-4 text-center md:text-left space-y-4 hidden md:block">
            {/* Desktop Name and Price */}
            <div>
              <h2 className="text-4xl font-bold text-gray-800">
                {astrologer.displayName.split(" ")[0] || (astrologer.name.split(" ")[0] !== "" && astrologer.name.split(" ")[0])}
              </h2>
              <div className="text-gray-700">
                <p className="font-semibold text-lg">₹{astrologer.discountedPrice ?? astrologer.minutePrice}/minute</p>
                <p className="text-sm">Minimum duration: {astrologer.minimumCallDuration} minutes</p>
                <p className="text-sm">Experience: {astrologer.experience} Years</p>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                {astrologer.specializations.map((spec, index) => (
                  <span
                    key={index}
                    className="bg-blue-100 text-blue-800 text-xs md:text-sm font-medium px-2 md:px-3 py-1 rounded-full"
                  >
                    {spec.name}
                  </span>
                ))}
              </div>

              <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                {astrologer.languages.map((language, index) => (
                  <span
                    key={index}
                    className="bg-purple-100 text-purple-800 text-xs md:text-sm font-medium px-2 md:px-3 py-1 rounded-full"
                  >
                    {language}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {/* Right Section: Actions */}
          <div className="md:col-span-4 flex md:flex-col gap-2">
            <button
              onClick={() => astrologer.availableForCall && onCallChatClickHandler(astrologer._id, "call")}
              className={`flex-1 flex items-center justify-center px-4 py-2 md:px-6 md:py-3 rounded-lg text-white font-semibold text-sm md:text-base transition
          ${astrologer.availableForCall
                  ? "bg-yellow-500 hover:bg-yellow-600"
                  : "bg-gray-400 cursor-not-allowed"
                }`}
            >
              <FaPhoneAlt className="mr-2" />
              Start Call
              <div
                  className={`w-4 h-4 rounded-full ml-2 ${!astrologer.availability || !astrologer.availableForCall
                      ? "bg-red-500"
                      : astrologer.inCall
                        ? "bg-yellow-600"
                        : "bg-green-500"
                    } border-2 border-white`}
                ></div>
            </button>

            <button
              onClick={() => astrologer.availableForChat && onCallChatClickHandler(astrologer._id, "chat")}
              className={`flex-1 flex items-center justify-center px-4 py-2 md:px-6 md:py-3 rounded-lg text-white font-semibold text-sm md:text-base transition
          ${astrologer.availableForChat
                  ? "bg-yellow-500 hover:bg-yellow-600"
                  : "bg-gray-400 cursor-not-allowed"
                }`}
            >
              <FaComments className="mr-2" />
              Start Chat
              <div
                  className={`w-4 h-4 rounded-full ml-2 ${!astrologer.availability || !astrologer.availableForChat
                      ? "bg-red-500"
                      : astrologer.inChat
                        ? "bg-yellow-600"
                        : "bg-green-500"
                    } border-2 border-white`}
                ></div>
            </button>
          </div>
        </div>
      </div>

      {/* Images Slider */}
      {astrologer.aboutImages?.length > 0 && (
        <div className="max-w-7xl mx-auto bg-white mt-6 p-4 sm:p-6 rounded-lg shadow-lg">
          {astrologer.aboutImages.length === 1 ? (
            // Single image display
            <div className="relative w-1/3 mx-auto">
              <img
                src={`https://astrologerdaddy.com/api/${astrologer.aboutImages[0]}`}
                alt="AstrologerAboutimages"
                className="w-full object-contain rounded-lg"
                loading="lazy"
              />
            </div>
          ) : (
            // Multiple images slider
            <div className="relative">
              <Slider {...imageSliderSettings}>
                {astrologer.aboutImages.map((image, index) => (
                  <div key={index} className="px-2 focus:outline-none">
                    <div className="relative w-full h-0 pb-[100%] sm:pb-[75%] md:pb-[56.25%]">
                      <img
                        src={`https://astrologerdaddy.com/api/${image}`}
                        alt={`Slide ${index + 1}`}
                        className="absolute top-0 left-0 w-full h-full object-contain rounded-lg"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      )}

      <div className="max-w-7xl mx-auto mt-6 p-4 md:p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-lg md:text-xl font-bold mb-4">About Me</h3>
        <p className="text-sm md:text-base">{astrologer.aboutMe}</p>

        {astrologer.promotionalVideo && (
          <div className="video-responsive mt-4">
            <div
              className="relative overflow-hidden"
              style={{ paddingBottom: "56.25%" /* 16:9 aspect ratio */ }}
            >
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src={`https://www.youtube.com/embed/${astrologer.promotionalVideo}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </div>
        )}
      </div>

      <div className="max-w-7xl mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center">
          Ratings and Reviews
        </h3>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/3 bg-gray-100 p-4 mb-8 md:mb-0 rounded-lg shadow">
            {ratingData.map((item, index) => (
              <div key={index} className="mb-4">
                <div className="flex items-center">
                  <div className="w-1/4 text-right pr-2">
                    {item.stars} Stars
                  </div>
                  <div className="w-3/4">
                    <div className="bg-gray-300 h-3 rounded-full overflow-hidden">
                      <div
                        className="bg-yellow-500 h-full"
                        style={{ width: `${item.value}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-6">
              <p className="text-lg font-semibold flex items-center">
                <FaCommentDots className="text-yellow-500 mr-2" />
                Total Reviews:{" "}
                {astrologer.reviews ? astrologer.reviews.length : 0}
              </p>
            </div>
          </div>
          <div className="md:w-2/3 relative mb-8 h-96 overflow-y-auto">
            {(astrologer.reviews && astrologer.reviews.length > 0
              ? astrologer.reviews
              : fallbackReviews
            ).map((review, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow mb-4"
              >
                <p className="text-lg font-bold capitalize">
                  {review.user.firstName + " " + review.user.lastName}
                </p>
                <p className="text-yellow-500 mb-2">
                  {renderStars(review.rating)}
                </p>
                <p>{review.comment}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-7xl mx-auto bg-gray-100 mt-6 px-1 py-2 md:p-6 rounded-lg ">
          <h3 className="text-lg font-semibold text-center mb-4">
            Suggested Astrologers
          </h3>
          <Slider {...suggestedSliderSettings}>
            {suggestedAstrologers.map((astro) => (
              astrologer._id !== astro._id && <div key={astro._id} className="px-1 md:p-4">
                <AstrologerCard
                  avatar={astro.profileImage}
                  name={astro.displayName || astro.name}
                  id={astro._id}
                  ratings={astro.ratings}
                  experience={astro.experience}
                  category={astro.category}
                  languages={astro.languages}
                  specializations={astro.specializations}
                  minutePrice={astro.minutePrice}
                  discountedPrice={astro.discountedPrice}
                  minimumCallDuration={astro.minimumCallDuration}
                  showCallChat={false}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Astrologer;
