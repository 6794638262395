import React, { useState, useEffect } from "react";
import aries from "../assets/zodiacs/aries-01.png";
import aquarius from "../assets/zodiacs/aquarius-01.png";
import cancer from "../assets/zodiacs/cancer-01.png";
import capricorn from "../assets/zodiacs/capricorn-01.png";
import gemini from "../assets/zodiacs/gemini-01.png";
import leo from "../assets/zodiacs/LEO-01.png";
import libra from "../assets/zodiacs/libra-01.png";
import pisces from "../assets/zodiacs/pisces-01.png";
import sagittarius from "../assets/zodiacs/sagittarius-01.png";
import taurus from "../assets/zodiacs/taurus-01.png";
import virgo from "../assets/zodiacs/virgo-01.png";
import scorpio from "../assets/zodiacs/scorpio-01.png";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import axios from "axios";
import { useNavigate } from "react-router-dom";

const zodiacSigns = [
  { name: "aries", img: aries, index: 1 },
  { name: "taurus", img: taurus, index: 2 },
  { name: "gemini", img: gemini, index: 3 },
  { name: "cancer", img: cancer, index: 4 },
  { name: "leo", img: leo, index: 5 },
  { name: "virgo", img: virgo, index: 6 },
  { name: "libra", img: libra, index: 7 },
  { name: "scorpio", img: scorpio, index: 8 },
  { name: "sagittarius", img: sagittarius, index: 9 },
  { name: "capricorn", img: capricorn, index: 10 },
  { name: "aquarius", img: aquarius, index: 11 },
  { name: "pisces", img: pisces, index: 12 },
];

function HoroscopePage() {
  const [horoscopes, setHoroscopes] = useState({});
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  useEffect(() => {
    const fetchHoroscopes = async () => {
      setLoading(true);
      const fetchedHoroscopes = {};

      for (let sign of zodiacSigns) {
        try {
          const response = await axios.get(
            `https://astrologerdaddy.com/api/astrology-services/prediction/daily-sun?zodiac=${sign.index}&date=${new Date().toLocaleDateString(
              "en-GB"
            )}&show_same=true&lang=${language}&split=true&type=big`
          );
          const data = response.data.response;
          fetchedHoroscopes[sign.name] =
            data.bot_response.total_score.split_response;
        } catch (error) {
          console.error(`Error fetching horoscope for ${sign.name}:`, error);
        }
      }

      setHoroscopes(fetchedHoroscopes);
      setLoading(false);
    };

    fetchHoroscopes();
  }, []);

  const alsocheck = [
    { avatar: "jow", title: "Yesterday's Horoscope" },
    { avatar: "jow", title: "Tomorrow's Horoscope" },
    { avatar: "Weekly", title: "Weekly's Horoscope" },
    { avatar: "jow", title: "Monthly's Horoscope" },
    { avatar: "jow", title: "Yearly's Horoscope" },
  ];
const navigate = useNavigate()

const handleKmaking = () =>{
  navigate("/kundli-making")
}
const handleKmatching = () =>{
  navigate("/kundli-matching")
}
  return (
    <div>
      <div className="w-full px-6 lg:px-28">
        <div className="mt-10 text-center">
          <h1 className="font-bold text-2xl lg:text-3xl text-red-700">
          Today’s horoscope for Zodiac Signs
          </h1>
         
        </div>

        <div className="mt-8">
         
        <p className="mt-6 text-justify text-sm md:text-base lg:text-lg mx-auto leading-relaxed text-gray-800">
  For today's horoscope, visit 
  <a href="https://AstrologerDaddy.com" className="text-black-600 px-3 hover:text-iblack-700 font-semibold underline transition-all duration-300 ease-in-out">
        AstrologerDaddy.com
  </a> 
  to get personalized insights into your day based on your zodiac sign. Whether you're seeking guidance on 
  <span className="font-bold text-black"> love, career, or personal growth</span>, Astrologer Daddy offers expert astrological predictions 
  to help you navigate life's challenges. Discover what the stars have in store for you today, and find out how planetary 
  alignments could influence your decisions, emotions, and opportunities. With detailed, daily updates, you'll be empowered 
  to make informed choices and align with the cosmic energy surrounding you. 
  <a href="https://AstrologerDaddy.com" className="text-black-600 hover:text-iblack-700 px-3 font-semibold underline transition-all duration-300 ease-in-out">
    Check your horoscope now at AstrologerDaddy.com!
  </a>
</p>


<p className="mt-6 text-justify text-sm md:text-base lg:text-lg mx-auto leading-relaxed">
  Ultimately, whether you're a seasoned astrology enthusiast or just looking for a bit of guidance, checking your horoscope 
  on <a href="https://AstrologerDaddy.com" className="text-black-600 hover:text-blue-800 px-3 font-semibold underline"> AstrologerDaddy.com</a> 
  is a great way to start your day. You'll gain insights that can help you navigate challenges, embrace opportunities, and 
  better understand your own strengths and weaknesses. The stars may not control your fate, but they can certainly offer 
  valuable clues about how to make the most of each day. 
  <span className="font-bold"> So, why wait?</span> Head over to 
  <a href="https://AstrologerDaddy.com" className="text-black-600 hover:text-blue-800 px-3 font-semibold underline"> AstrologerDaddy.com</a> 
  now and check your daily horoscope to see what the universe has planned for you today.
</p>

        </div>



        <div className="container mx-auto px-4">
          <div
            className={`grid ${loading ? "lg:grid-cols-1" : "lg:grid-cols-3"
              } grid-cols-1  mb-10 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8`}
          >
            {loading ? (
              <div className="flex justify-center items-center w-full h-full">
                <AiOutlineLoading3Quarters className="w-8 h-8 animate-spin mx-auto" />
              </div>
            ) : (
              zodiacSigns.map((sign, index) => (
                <div key={index} className="bg-white shadow-lg p-6 rounded-lg">
                  <img
                    className="w-28 h-24 rounded-full mx-auto"
                    src={sign.img}
                    alt={`${sign.name} image`}
                  />
                  <h3 className="mt-4 text-lg font-bold  text-center text-yellow-500">
                    {sign.name.charAt(0).toUpperCase() + sign.name.slice(1)}{" "}
                    Today's Horoscope
                  </h3>
                  <p className="text-gray-600 mt-2 align-middle">
                    {horoscopes[sign.name] || "Loading..."}
                  </p>
                    
                  <Link
                    to={`/horoscope/${sign.name}`}
                    className="text-iblack-500 mt-4 inline-block hover:underline"
                    style={{ textDecoration: "underline" }} // Test this inline style to see if it works
                  >
                    read more...
                  </Link>

                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* <div className="bg-yellow-500 w-full mb-8">
        <h1 className="font-bold text-xl text-center pt-12">ALSO CHECK</h1>
        <div className="flex flex-nowrap overflow-x-auto justify-center gap-4 lg:gap-8 md:gap-16 items-center px-4">
          {alsocheck.map((val, index) => (
            <div
              key={index}
              className="flex flex-col items-center pb-12 flex-shrink-0"
            >
              <img
                className="w-24 h-20 md:w-32 md:h-28"
                src={leo}
                alt="yesterday"
              />
              <h1 className="text-xs md:text-base">{val.title}</h1>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default HoroscopePage;
