/* eslint-disable default-case */
import React, { useEffect, useState, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import Slider from 'react-slick';
import axios from "axios";
import BasicDetailsSection from "../components/KundliDetails/Kundali/BasicDetailsSection";
import PlanetDetailsSection from "../components/KundliDetails/Kundali/PlanetDetailsSection";
import DivisionalTableSection from "../components/KundliDetails/Kundali/DivisionalTableSection";
import AshtakvargaSection from "../components/KundliDetails/Kundali/AshtakvargaSection";
import BinnashtakvargaSection from "../components/KundliDetails/Kundali/BinnashtakvargaSection";
import WesternPlanets from "../components/KundliDetails/Kundali/WesternPlanets";
import PersonalCharacteristicsSection from "../components/KundliDetails/General/PersonalCharacteristicsSection";
import PlanetReportSection from "../components/KundliDetails/General/PlanetReportSection";
import AscendantReportSection from "../components/KundliDetails/General/AscendantReportSection";
import MahaDashaPredictionsSection from "../components/KundliDetails/Dashas/MahaDashaPredictionsSection";
import MahaDashaSection from "../components/KundliDetails/Dashas/MahaDashaSection";
import AntarDashaSection from "../components/KundliDetails/Dashas/AntarDashaSection";
import CharDashaCurrentSection from "../components/KundliDetails/Dashas/CharDashaCurrentSection";
import CharDashaMainSection from "../components/KundliDetails/Dashas/CharDashaMainSection";
import CharDashaSubSection from "../components/KundliDetails/Dashas/CharDashaSubSection";
import CurrentMahadashaFullSection from "../components/KundliDetails/Dashas/CurrentMahadashaFullSection";
//doshas import
import MangalDoshSection from "../components/KundliDetails/Doshas/MangalDoshSection";
import ManglikDoshSection from "../components/KundliDetails/Doshas/ManglikDoshSection";
import KaalsarpDoshSection from "../components/KundliDetails/Doshas/KaalsarpDoshSectio";
import PitraDoshSection from "../components/KundliDetails/Doshas/PitraDoshSection";
import PapaSamayaSection from "../components/KundliDetails/Doshas/PapaSamayaSection";

import CurrentMahadasha from "../components/KundliDetails/Dashas/CurrentMahadasha";
import ParyantarDasha from "../components/KundliDetails/Dashas/ParyantarDasha";
import YoginiDashaMain from "../components/KundliDetails/Dashas/YoginiDashaMain";
import YoginiDashaSub from "../components/KundliDetails/Dashas/YoginiDashaSub";
// Constants

const BASE_URL = "https://astrologerdaddy.com/api/astrology-services";

const MAIN_TABS = {
  KUNDLI: "kundli",
  GENERAL: "general",
  DASHAS: "dashas", // New main tab
  DOSHAS: "doshas", // New main tab
};

const SUB_TABS = {
  KUNDLI: {
    BASIC_DETAILS: "basicDetails",
    PLANET_DETAILS: "planetDetails",
    DIVISIONAL_TABLE_AND_CHART: "divisionalTable",
    ASHTAKVARGA: "ashtakvarga",
    BINNASHTAKVARGA: "binnashtakvarga",
    WESTERN_PLANETS: "westernPlanets",
  },
  GENERAL: {
    PERSONAL_CHARACTERISTICS: "personalCharacteristics",
    ASCENDANT_REPORT: "ascendantReport",
    PLANET_REPORT: "planetReport",
  },
  DASHAS: {
    // New sub-tabs for Dashas
    MAHADASHA: "mahadasha",
    MAHADASHA_PREDICTIONS: "mahadashaPredictions",
    ANTARDASHA: "antardasha",
    CHAR_DASHA_CURRENT: "charDashaCurrent",
    CHAR_DASHA_MAIN: "charDashaMain",
    CHAR_DASHA_SUB: "charDashaSub",
    CURRENT_MAHADASHA_FULL: "currentMahaDashaFull",
    CURRENT_MAHADASHA: "currentMahadasha",
    // PARYANTAR_DASHA: "paryantarDasha",
    // SPECIFIC_DASHA: "specificDasha",
    YOGINI_DASHA_MAIN: "yoginiDashaMain",
    YOGINI_DASHA_SUB: "yoginiDashaSub",
  },
  DOSHAS: {
    MANGAL_DOSH: "mangalDosh",
    KAALSARP_DOSH: "kaalsarpDosh",
    MANGLIK_DOSH: "manglikDosh",
    PITRA_DOSH: "pitraDosh",
    PAPASAMAYA: "papasamaya",
  },
};

// Memoized Tab Button Component
const TabButton = memo(({ isActive, onClick, children, type }) => (
  <button
    className={`px-4 text-nowrap py-2 rounded-lg transition-colors duration-200 ${isActive
      ? ` ${type === "main" ? "bg-orange-500" : "bg-yellow-500"} text-white`
      : `bg-white text-gray-700  ${type === "main" ? "hover:bg-orange-100" : "hover:bg-yellow-100"
      }`
      }`}
    onClick={onClick}
  >
    {children}
  </button>
));



// Main Component
function KundliDetails() {
  const location = useLocation();
  const apiData = location.state?.apiData;

  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const [state, setState] = useState({
    personalCharacteristics: [],
    ascendantReport: null,
    planetReport: null,
    planetDetails: null,
    divisionalData: null,
    ashtakvargaData: null,
    selectedPlanet: "Jupiter",
    divisionalData: null,
    svgData: null,
    chartStyle: "north",
    year: new Date().getFullYear(),
    transitDate: new Date().toLocaleDateString("en-GB"),
    loading: false,
    activeMainTab: MAIN_TABS.KUNDLI,
    activeSubTab: SUB_TABS.KUNDLI.BASIC_DETAILS,
    error: null,
  });

  // API service
  const apiService = {
    getBaseParams: (apiData) => ({
      name: apiData.name,
      // Ensure day and month are padded to two digits
      dob: `${String(apiData.day).padStart(2, "0")}/${String(
        apiData.month
      ).padStart(2, "0")}/${apiData.year}`,
      tob: `${String(apiData.hour).padStart(2, "0")}:${String(
        apiData.min
      ).padStart(2, "0")}`,
      lat: apiData.lat,
      lon: apiData.lon,
      tz: apiData.tzone,
      lang: language,
    }),

    fetchData: async (endpoint, params) => {
      try {
        // For chart-image endpoint, set responseType to 'text' instead of default JSON
        const options = {
          params,
          headers: {
            'Accept': endpoint.includes('chart-image') ? 'text/plain' : 'application/json'
          },
          // Set responseType to 'text' for chart-image endpoint
          responseType: endpoint.includes('chart-image') ? 'text' : 'json'
        };

        const response = await axios.get(`${BASE_URL}/${endpoint}`, options);

        // For chart-image, return the raw SVG text
        if (endpoint.includes('chart-image')) {
          return response.data; // Return SVG text directly
        }

        // For other endpoints, return JSON response
        return response.data.response || response.data;

      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        throw error;
      }
    }
  };

  const [planet, setPlanet] = useState("Ascendant");
  const [division, setDivision] = useState("D1");

  // Memoized fetch functions
  const fetchData = useCallback(
    async (endpoint, additionalParams = {}) => {
      if (!apiData) return;

      setState((prev) => ({ ...prev, loading: true, error: null }));
      try {
        const params = {
          ...apiService.getBaseParams(apiData),
          ...additionalParams,
        };
        const data = await apiService.fetchData(endpoint, params);
        return data;
      } catch (error) {
        setState((prev) => ({ ...prev, error: error.message }));
        return null;
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [apiData, language]
  );

  const handlePlanetChange = async (planet) => {
    try {
      const binnashtakvargaData = await fetchData(
        `horoscope/binnashtakvarga?planet=${planet}`
      );
      setState((prev) => ({
        ...prev,
        binnashtakvarga: binnashtakvargaData,
      }));
    } catch (error) {
      console.error("Error fetching binnashtakvarga data:", error);
    }
  };

  // Handle division change with all parameters
  const handleDivisionChange = async (division) => {
    try {
      // Base params for both API calls
      const baseParams = {
        name: apiData.name,
        dob: `${String(apiData.day).padStart(2, "0")}/${String(apiData.month).padStart(2, "0")}/${apiData.year}`,
        tob: `${String(apiData.hour).padStart(2, "0")}:${String(apiData.min).padStart(2, "0")}`,
        lat: apiData.lat,
        lon: apiData.lon,
        tz: apiData.tzone,
        lang: language,
      };

      // For divisional charts
      const divisionalData = await fetchData('horoscope/divisional-charts', {
        ...baseParams,
        div: division,
        style: state.chartStyle,
        response_type: 'planet_object',
        ...(division === "varshapal" && { year: state.year }),
        ...(division === "transit" && { transit_date: state.transitDate })
      });

      // For chart image - including all required params from documentation
      const svgParams = {
        ...baseParams,
        div: division,
        style: state.chartStyle,
        color: '%23ff3366', // Default color (encoded '#ff3366')
        font_size: 12,
        font_style: 'roboto',
        colorful_planets: 0,
        size: 300,
        stroke: 2,
        format: 'base64',
        ...(division === "varshapal" && { year: state.year }),
        ...(division === "transit" && { transit_date: state.transitDate })
      };

      const svgData = await fetchData('horoscope/chart-image', svgParams);

      setState(prev => ({
        ...prev,
        divisionalData,
        svgData
      }));
    } catch (error) {
      console.error("Error fetching divisional data:", error);
    }
  };

  // Handle style change
  const handleStyleChange = async (style) => {
    try {
      const params = new URLSearchParams({
        div: state.division,
        style: style,
        ...(state.division === "varshapal" && { year: state.year }),
        ...(state.division === "transit" && {
          transit_date: state.transitDate,
        }),
      });

      const divisionalData = await fetchData(
        `horoscope/divisional-charts?${params}`
      );

      const svgParams = new URLSearchParams({
        style: style,
        ...(state.division === "varshapal" && { year: state.year }),
        ...(state.division === "transit" && {
          transit_date: state.transitDate,
        }),
      });
      const svgData = await fetchData(`horoscope/chart-image?${svgParams}`);

      setState((prev) => ({
        ...prev,
        chartStyle: style,
        divisionalData,
        svgData,
      }));
    } catch (error) {
      console.error("Error updating chart style:", error);
    }
  };

  // Handle year change for varshapal
  const handleYearChange = async (year) => {
    if (state.division !== "varshapal") return;

    try {
      const params = new URLSearchParams({
        div: state.division,
        style: state.chartStyle,
        year: year,
      });

      const divisionalData = await fetchData(
        `horoscope/divisional-charts?${params}`
      );

      const svgParams = new URLSearchParams({
        style: state.chartStyle,
        year: year,
      });
      const svgData = await fetchData(`horoscope/chart-image?${svgParams}`);

      setState((prev) => ({
        ...prev,
        year,
        divisionalData,
        svgData,
      }));
    } catch (error) {
      console.error("Error updating year:", error);
    }
  };

  // Handle transit date change
  const handleTransitDateChange = async (date) => {
    if (state.division !== "transit") return;

    try {
      const params = new URLSearchParams({
        div: state.division,
        style: state.chartStyle,
        transit_date: date,
      });

      const divisionalData = await fetchData(
        `horoscope/divisional-charts?${params}`
      );

      const svgParams = new URLSearchParams({
        style: state.chartStyle,
        transit_date: date,
      });
      const svgData = await fetchData(`horoscope/chart-image?${svgParams}`);

      setState((prev) => ({
        ...prev,
        transitDate: date,
        divisionalData,
        svgData,
      }));
    } catch (error) {
      console.error("Error updating transit date:", error);
    }
  };

  // Fetch planet details
  const fetchPlanetDetails = useCallback(async () => {
    const data = await fetchData("horoscope/planet-details");
    setState((prev) => ({ ...prev, planetDetails: data }));
  }, [fetchData]);

  // Fetch divisional data
  const fetchDivisionalData = useCallback(async () => {
    try {
      const params = new URLSearchParams({
        div: "D1",
        style: state.chartStyle,
        ...(state.division === "varshapal" && { year: state.year }),
        ...(state.division === "transit" && {
          transit_date: state.transitDate,
        }),
      });

      const data = await fetchData(`horoscope/divisional-charts?${params}`);
      const svgParams = new URLSearchParams({
        style: state.chartStyle,
        ...(state.division === "varshapal" && { year: state.year }),
        ...(state.division === "transit" && {
          transit_date: state.transitDate,
        }),
      });
      const svgData = await fetchData(`horoscope/chart-image?${svgParams}`);

      setState((prev) => ({ ...prev, divisionalData: data, svgData }));
    } catch (error) {
      console.error("Error fetching initial divisional data:", error);
    }
  }, [
    fetchData,
    state.chartStyle,
    state.division,
    state.year,
    state.transitDate,
  ]);

  // Fetch ashtakvarga data
  const fetchAshtakvargaData = useCallback(async () => {
    const data = await fetchData("horoscope/ashtakvarga");
    setState((prev) => ({ ...prev, ashtakvargaData: data }));
  }, [fetchData]);

  // Data fetching effects
  useEffect(() => {
    const fetchActiveTabData = async () => {
      switch (state.activeMainTab) {
        case MAIN_TABS.KUNDLI:
          switch (state.activeSubTab) {
            case SUB_TABS.KUNDLI.BASIC_DETAILS:
              const extendedKundliDetails = await fetchData(
                "extended-horoscope/extended-kundli-details"
              );
              const basicPlanetDetails = await fetchData(
                "horoscope/planet-details"
              );

              setState((prev) => ({
                ...prev,
                extendedKundliDetailsData: extendedKundliDetails,
                basicPlanetDetailsData: basicPlanetDetails,
              }));
              break;
            case SUB_TABS.KUNDLI.PLANET_DETAILS:
              await fetchPlanetDetails();
              break;
            case SUB_TABS.KUNDLI.DIVISIONAL_TABLE_AND_CHART:
              await fetchDivisionalData();
              break;
            case SUB_TABS.KUNDLI.ASHTAKVARGA:
              await fetchAshtakvargaData();
              break;
            case SUB_TABS.KUNDLI.BINNASHTAKVARGA:
              const binnashtakvargaData = await fetchData(
                `horoscope/binnashtakvarga?planet=${planet}`
              );
              setState((prev) => ({
                ...prev,
                binnashtakvarga: binnashtakvargaData,
              }));
              break;
            case SUB_TABS.KUNDLI.WESTERN_PLANETS:
              const western_planetsData = await fetchData(
                "horoscope/western-planets"
              );

              setState((prev) => ({
                ...prev,
                westernPlanets: western_planetsData,
              }));
              break;
          }
          break;
        case MAIN_TABS.GENERAL:
          switch (state.activeSubTab) {
            case SUB_TABS.GENERAL.PERSONAL_CHARACTERISTICS:
              const characteristics = await fetchData(
                "horoscope/personal-characteristics"
              );
              setState((prev) => ({
                ...prev,
                personalCharacteristics: characteristics,
              }));
              break;
            case SUB_TABS.GENERAL.ASCENDANT_REPORT:
              const ascendant = await fetchData("horoscope/ascendant-report");
              setState((prev) => ({
                ...prev,
                ascendantReport: ascendant?.[0],
              }));
              break;
            case SUB_TABS.GENERAL.PLANET_REPORT:
              const planet = await fetchData("horoscope/planet-report", {
                planet: state.selectedPlanet,
              });
              setState((prev) => ({ ...prev, planetReport: planet?.[0] }));
              break;
          }
          break;
        case MAIN_TABS.DASHAS:
          switch (state.activeSubTab) {
            case SUB_TABS.DASHAS.MAHADASHA:
              const mahaDasha = await fetchData("dashas/maha-dasha");
              setState((prev) => ({ ...prev, mahaDashaData: mahaDasha }));
              break;
            case SUB_TABS.DASHAS.MAHADASHA_PREDICTIONS:
              const predictions = await fetchData(
                "dashas/maha-dasha-predictions"
              );
              setState((prev) => ({
                ...prev,
                mahaDashaPredictions: predictions,
              }));
              break;
            case SUB_TABS.DASHAS.ANTARDASHA:
              const antarDasha = await fetchData("dashas/antar-dasha");
              setState((prev) => ({ ...prev, antarDashaData: antarDasha }));
              break;
            case SUB_TABS.DASHAS.CHAR_DASHA_CURRENT:
              const charDashaCurrent = await fetchData(
                "dashas/char-dasha-current"
              );
              setState((prev) => ({
                ...prev,
                charDashaCurrentData: charDashaCurrent,
              }));
              break;
            case SUB_TABS.DASHAS.CHAR_DASHA_MAIN:
              const charDashaMain = await fetchData("dashas/char-dasha-main");
              setState((prev) => ({
                ...prev,
                charDashaMainData: charDashaMain,
              }));
              break;
            case SUB_TABS.DASHAS.CHAR_DASHA_SUB:
              const charDashaSub = await fetchData("dashas/char-dasha-sub");
              setState((prev) => ({ ...prev, charDashaSubData: charDashaSub }));
              break;
            case SUB_TABS.DASHAS.CURRENT_MAHADASHA_FULL:
              const currentMahadashaFull = await fetchData(
                "dashas/current-mahadasha-full"
              );
              setState((prev) => ({
                ...prev,
                currentMahadashaFullData: currentMahadashaFull,
              }));
              break;
            case SUB_TABS.DASHAS.CURRENT_MAHADASHA:
              const currentMahadasha = await fetchData(
                "dashas/current-mahadasha"
              );
              setState((prev) => ({
                ...prev,
                currentMahadashaData: currentMahadasha,
              }));
              break;
            case SUB_TABS.DASHAS.PARYANTAR_DASHA:
              const paryantarDasha = await fetchData("dashas/paryantar-dasha");
              setState((prev) => ({
                ...prev,
                paryantarDashaData: paryantarDasha,
              }));
              break;
            case SUB_TABS.DASHAS.YOGINI_DASHA_MAIN:
              const yoginiDashaMain = await fetchData(
                "dashas/yogini-dasha-main"
              );
              setState((prev) => ({
                ...prev,
                yoginiDashaMainData: yoginiDashaMain,
              }));
              break;
            case SUB_TABS.DASHAS.YOGINI_DASHA_SUB:
              const yoginiDashaSub = await fetchData("dashas/yogini-dasha-sub");
              setState((prev) => ({
                ...prev,
                yoginiDashaSubData: yoginiDashaSub,
              }));
              break;
          }

          break;
        case MAIN_TABS.DOSHAS:
          switch (state.activeSubTab) {
            case SUB_TABS.DOSHAS.MANGAL_DOSH:
              const mangalDosh = await fetchData("dosha/mangal-dosh");
              setState((prev) => ({ ...prev, mangalDoshData: mangalDosh }));
              break;
            case SUB_TABS.DOSHAS.KAALSARP_DOSH:
              const kaalsarpDosh = await fetchData("dosha/kaalsarp-dosh");
              setState((prev) => ({ ...prev, kaalsarpDoshData: kaalsarpDosh }));
              break;
            case SUB_TABS.DOSHAS.MANGLIK_DOSH:
              const manglikDosh = await fetchData("dosha/manglik-dosh");
              setState((prev) => ({ ...prev, manglikDoshData: manglikDosh }));
              break;
            case SUB_TABS.DOSHAS.PITRA_DOSH:
              const pitraDosh = await fetchData("dosha/pitra-dosh");
              setState((prev) => ({ ...prev, pitraDoshData: pitraDosh }));
              break;
            case SUB_TABS.DOSHAS.PAPASAMAYA:
              const papasamaya = await fetchData("dosha/papasamaya");
              setState((prev) => ({ ...prev, papaSamayaData: papasamaya }));
              break;
          }
          break;
      }
    };

    fetchActiveTabData();
  }, [
    state.activeMainTab,
    state.activeSubTab,
    state.selectedPlanet,
    fetchData,
    fetchPlanetDetails,
    fetchDivisionalData,
    fetchAshtakvargaData,
  ]);

  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
    // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];

  const handleMainTabChange = useCallback((tab) => {
    let defaultSubTab;
    switch (tab) {
      case MAIN_TABS.KUNDLI:
        defaultSubTab = SUB_TABS.KUNDLI.BASIC_DETAILS;
        break;
      case MAIN_TABS.GENERAL:
        defaultSubTab = SUB_TABS.GENERAL.PERSONAL_CHARACTERISTICS;
        break;
      case MAIN_TABS.DASHAS:
        defaultSubTab = SUB_TABS.DASHAS.MAHADASHA;
        break;
      case MAIN_TABS.DOSHAS:
        defaultSubTab = SUB_TABS.DOSHAS.MANGAL_DOSH;
        break;
      // Add additional cases for other MAIN_TABS as needed
      default:
        defaultSubTab = null; // or some default value
    }

    setState((prev) => ({
      ...prev,
      activeMainTab: tab,
      activeSubTab: defaultSubTab,
    }));
  }, []);

  const handleSubTabChange = useCallback((tab) => {
    setState((prev) => ({ ...prev, activeSubTab: tab }));
  }, []);

  if (!apiData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center text-red-500 p-4 bg-red-50 rounded-lg">
          <p className="font-semibold">No data available</p>
          <p className="text-sm mt-2">
            Please provide birth details to generate the kundli
          </p>
        </div>
      </div>
    );
  }

  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang); // Store in localStorage

    // Trigger API call when language changes
    callVedicAPI(selectedLang);
  };

  const callVedicAPI = (lang) => {

    const url = `https://astrologerdaddy.com/api/astrology-services/dosha/kaalsarp-dosh?dob=23/02/1985&tob=05:40&lat=11&lon=77&tz=5.5&&lang=${lang}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };



  return (
    <div className="px-6 py-4 bg-gray-50 min-h-screen">

      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      {/* Error Display */}
      {state.error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg">
          <p className="font-semibold">Error occurred:</p>
          <p>{state.error}</p>
        </div>
      )}



      <h1 className="text-3xl font-bold text-center my-6 text-red-700">
        Kundli Making Details
      </h1>
      <button
        className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 text-white mb-5 font-semibold py-2 px-2 sm:py-3 sm:px-6 rounded-full shadow-lg mt-4 mx-auto block transform transition duration-300 hover:scale-105 hover:shadow-2xl animate-bounce text-sm sm:text-base"
      >
        Get Your Complete Kundli in Just 99!
      </button>


      {/* Main Tabs */}
      <div className="flex flex-wrap gap-2 mb-4">
        {Object.entries(MAIN_TABS).map(([key, value]) => (
          <TabButton
            key={key}
            type={"main"}
            isActive={state.activeMainTab === value}
            onClick={() => handleMainTabChange(value)}
          >
            {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
          </TabButton>
        ))}
      </div>

      {/* Horizontal Line */}
      <div className="border-b border-orange-300 mb-4"></div>

      {/* Sub Tabs */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        {/* Tabs Section */}
        <div className="flex overflow-x-auto flex-wrap justify-start gap-2 mb-4 py-2 sm:py-0 pl-4 sm:pl-8 w-full">
          {(() => {
            let subTabs;
            switch (state.activeMainTab) {
              case MAIN_TABS.KUNDLI:
                subTabs = SUB_TABS.KUNDLI;
                break;
              case MAIN_TABS.GENERAL:
                subTabs = SUB_TABS.GENERAL;
                break;
              case MAIN_TABS.DASHAS:
                subTabs = SUB_TABS.DASHAS;
                break;
              case MAIN_TABS.DOSHAS:
                subTabs = SUB_TABS.DOSHAS;
                break;
              default:
                subTabs = {};
            }
            return Object.entries(subTabs).map(([key, value]) => (
              <TabButton
                key={key}
                isActive={state.activeSubTab === value}
                onClick={() => handleSubTabChange(value)}
              >
                {key
                  .split("_")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}
              </TabButton>
            ));
          })()}
        </div>

        {/* Language Selector */}
        <div className="flex flex-col sm:flex-row flex-wrap gap-2 px-2 mt-2 items-center w-full sm:w-auto">
          <label
            htmlFor="language-select"
            className="text-sm font-medium sm:mr-2 whitespace-nowrap"
          >
            Select Language:
          </label>
          <select
            id="language-select"
            className="border border-gray-300 rounded-md p-2 w-full sm:w-auto text-sm"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="ta">Tamil</option>
            <option value="ka">Kannada</option>
            <option value="te">Telugu</option>
            <option value="hi">Hindi</option>
            <option value="ml">Malayalam</option>
            <option value="mr">Marathi</option>

            <option value="sp">Spanish</option>
            <option value="fr">French</option>
          </select>
        </div>
      </div>

      {/* Content */}

      <div className="mt-4">
        {state.loading ? (
          <div className="flex items-center justify-center p-8">
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500 mb-4"></div>
              <p className="text-gray-600">Loading...</p>
            </div>
          </div>
        ) : (
          <>
            <div className="lg:flex md:flex items-stretch justify-between w-full min-h-screen">
              <div className="lg:w-[65%] md:[60%] w-full px-4 ">
                {state.activeMainTab === MAIN_TABS.KUNDLI && (
                  <>
                    {state.activeMainTab === MAIN_TABS.KUNDLI && (
                      <>
                        {state.activeSubTab === SUB_TABS.KUNDLI.BASIC_DETAILS &&
                          state.extendedKundliDetailsData &&
                          state.basicPlanetDetailsData && (
                            <BasicDetailsSection
                              apiData={apiData}
                              extendedKundliDetailsData={
                                state.extendedKundliDetailsData
                              }
                              basicPlanetDetailsData={state.basicPlanetDetailsData}
                            />
                          )}
                        {state.activeSubTab === SUB_TABS.KUNDLI.PLANET_DETAILS &&
                          state.planetDetails && (
                            <PlanetDetailsSection
                              planetDetails={state.planetDetails}
                            />
                          )}

                        {state.activeSubTab ===
                          SUB_TABS.KUNDLI.DIVISIONAL_TABLE_AND_CHART &&
                          state.divisionalData && (
                            <DivisionalTableSection
                              divisionalData={state.divisionalData}
                              svgData={state.svgData}
                              onDivisionChange={handleDivisionChange}
                              onStyleChange={handleStyleChange}
                              onYearChange={handleYearChange}
                              onTransitDateChange={handleTransitDateChange}
                              division={division}
                              style={state.chartStyle}
                              year={state.year}
                              transitDate={state.transitDate}
                              setDivision={setDivision}
                            />
                          )}
                        {state.activeSubTab === SUB_TABS.KUNDLI.ASHTAKVARGA &&
                          state.ashtakvargaData && (
                            <AshtakvargaSection
                              ashtakvargaData={state.ashtakvargaData}
                            />
                          )}
                        {state.activeSubTab === SUB_TABS.KUNDLI.BINNASHTAKVARGA &&
                          state.binnashtakvarga && (
                            <BinnashtakvargaSection
                              binnashtakvarga={state.binnashtakvarga}
                              onPlanetChange={handlePlanetChange}
                              planet={planet}
                              setPlanet={setPlanet}
                            />
                          )}
                        {state.activeSubTab === SUB_TABS.KUNDLI.WESTERN_PLANETS &&
                          state.westernPlanets && (
                            <WesternPlanets westernPlanets={state.westernPlanets} />
                          )}
                      </>
                    )}
                  </>
                )}

                {state.activeMainTab === MAIN_TABS.GENERAL && (
                  <>
                    {state.activeSubTab ===
                      SUB_TABS.GENERAL.PERSONAL_CHARACTERISTICS && (
                        <PersonalCharacteristicsSection
                          characteristics={state.personalCharacteristics}
                        />
                      )}
                    {state.activeSubTab === SUB_TABS.GENERAL.ASCENDANT_REPORT && (
                      <AscendantReportSection report={state.ascendantReport} />
                    )}
                    {state.activeSubTab === SUB_TABS.GENERAL.PLANET_REPORT && (
                      <PlanetReportSection
                        report={state.planetReport}
                        selectedPlanet={state.selectedPlanet}
                        onPlanetChange={(planet) =>
                          setState((prev) => ({ ...prev, selectedPlanet: planet }))
                        }
                      />
                    )}
                  </>
                )}
                {state.activeMainTab === MAIN_TABS.DASHAS && (
                  <>
                    {state.activeSubTab === SUB_TABS.DASHAS.MAHADASHA && (
                      <MahaDashaSection mahaDashaData={state.mahaDashaData} />
                    )}
                    {state.activeSubTab ===
                      SUB_TABS.DASHAS.MAHADASHA_PREDICTIONS && (
                        <MahaDashaPredictionsSection
                          predictions={state.mahaDashaPredictions}
                        />
                      )}
                    {state.activeSubTab === SUB_TABS.DASHAS.ANTARDASHA && (
                      <AntarDashaSection antarDashaData={state.antarDashaData} />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.CHAR_DASHA_CURRENT && (
                      <CharDashaCurrentSection
                        charDashaCurrentData={state.charDashaCurrentData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.CHAR_DASHA_MAIN && (
                      <CharDashaMainSection
                        charDashaMainData={state.charDashaMainData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.CHAR_DASHA_SUB && (
                      <CharDashaSubSection
                        charDashaSubData={state.charDashaSubData}
                      />
                    )}
                    {state.activeSubTab ===
                      SUB_TABS.DASHAS.CURRENT_MAHADASHA_FULL && (
                        <CurrentMahadashaFullSection
                          currentMahadashaFullData={state.currentMahadashaFullData}
                        />
                      )}
                    {state.activeSubTab === SUB_TABS.DASHAS.CURRENT_MAHADASHA && (
                      <CurrentMahadasha
                        currentMahadashaData={state.currentMahadashaData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.PARYANTAR_DASHA && (
                      <ParyantarDasha
                        paryantarDashaData={state.paryantarDashaData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.YOGINI_DASHA_MAIN && (
                      <YoginiDashaMain
                        yoginiDashaMainData={state.yoginiDashaMainData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DASHAS.YOGINI_DASHA_SUB && (
                      <YoginiDashaSub
                        yoginiDashaSubData={state.yoginiDashaSubData}
                      />
                    )}
                  </>
                )}
                {state.activeMainTab === MAIN_TABS.DOSHAS && (
                  <>
                    {state.activeSubTab === SUB_TABS.DOSHAS.MANGAL_DOSH && (
                      <MangalDoshSection mangalDoshData={state.mangalDoshData} />
                    )}
                    {state.activeSubTab === SUB_TABS.DOSHAS.KAALSARP_DOSH && (
                      <KaalsarpDoshSection
                        kaalsarpDoshData={state.kaalsarpDoshData}
                      />
                    )}
                    {state.activeSubTab === SUB_TABS.DOSHAS.MANGLIK_DOSH && (
                      <ManglikDoshSection manglikDoshData={state.manglikDoshData} />
                    )}
                    {state.activeSubTab === SUB_TABS.DOSHAS.PITRA_DOSH && (
                      <PitraDoshSection pitraDoshData={state.pitraDoshData} />
                    )}
                    {state.activeSubTab === SUB_TABS.DOSHAS.PAPASAMAYA && (
                      <PapaSamayaSection papaSamayaData={state.papaSamayaData} />
                    )}
                  </>
                )}

              </div>
              <div className="lg:w-[30%] md:[25%] w-full  px-4 h-screen ">
                <div className="max-w-full">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>

                    ))}
                  </Slider>
                </div>
                <div className="max-w-full mt-5">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>

                    ))}
                  </Slider>
                </div>

                <div className="max-w-full mt-5">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                      </div>

                    ))}
                  </Slider>
                </div>

              </div>

            </div>
          </>
        )}
      </div>


      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
}

export default KundliDetails;
