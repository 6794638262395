// import React, { useState, useEffect } from "react";
// import AstrologerCard from "../components/AstrologerCard";
// import api from "../api";
// import TOP_INDIAN_LANGUAGES from "../constants/languages";
// import useSpecializations from "../hooks/useSpecializations";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import customSelectStyles from "../styles/react-select";
// import { FaSearch } from "react-icons/fa";
// import { FaSort } from "react-icons/fa";
// import { FaFilter } from "react-icons/fa";

// const animatedComponents = makeAnimated();

// const Astrologers = () => {
//   const [astrologers, setAstrologers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [search, setSearch] = useState("");
//   const [selectedSpecializations, setSelectedSpecializations] = useState([]);
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   const [languages, setLanguages] = useState([]);
//   const [sort, setSort] = useState("");
//   const [order, setOrder] = useState("asc");

//   const [showSearch, setShowSearch] = useState(false);
//   const [showFilter, setShowFilter] = useState(false);
//   const [showSort, setShowSort] = useState(false);

//   const { specializations, error: specializationsError } = useSpecializations();

//   useEffect(() => {
//     const fetchAstrologers = async () => {
//       setLoading(true);
//       try {
//         const response = await api.get("/astrologers", {
//           params: {
//             page,
//             limit: 12,
//             search,
//             specializations: selectedSpecializations
//               .map((s) => s.value)
//               .join(","),
//             languages: languages.map((l) => l.value).join(","),
//             sort,
//             order,
//           },
//         });


//         if (response.data) {
//           setAstrologers(response.data.astrologers || []);
//           setTotalPages(response.data.totalPages || 1);
//         }
//         setLoading(false);
//       } catch (error) {
//         console.log("Error fetching astrologers:", error);
//         setLoading(false);
//       }
//     };

//     fetchAstrologers();
//   }, [page, search, selectedSpecializations, languages, sort, order]);

//   const handleSearchChange = (e) => setSearch(e.target.value);
//   const handlePageChange = (newPage) => setPage(newPage);
//   const handleSpecializationChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedSpecializations(selectedOptions || []);
//     }
//   };
//   const handleLanguageChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedLanguages(selectedOptions || []);
//       setLanguages(selectedOptions || []);
//     }
//   };
//   const handleSortChange = (selectedOption) => {
//     const [sortField, sortOrder] = selectedOption.value.split(":");
//     setSort(sortField);
//     setOrder(sortOrder);
//   };

//   const specializationOptions = specializations.map((sp) => ({
//     value: sp._id,
//     label: sp.name,
//   }));

//   const languageOptions = TOP_INDIAN_LANGUAGES.map((lang) => ({
//     value: lang,
//     label: lang,
//   }));
//   const sortOptions = [
//     { value: "rating:asc", label: "Rating (Low to High)" },
//     { value: "rating:desc", label: "Rating (High to Low)" },
//     { value: "minutePrice:asc", label: "Price (Low to High)" },
//     { value: "minutePrice:desc", label: "Price (High to Low)" },
//     { value: "experience:asc", label: "Experience (Low to High)" },
//     { value: "experience:desc", label: "Experience (High to Low)" },
//     { value: "minimumCallDuration:asc", label: "Min Duration (Low to High)" },
//     { value: "minimumCallDuration:desc", label: "Min Duration (High to Low)" },
//   ];

//   return (
//     <div className="md:w-5/6 w-11/12 mx-auto py-8 md:px-2">
//       <div className="fixed bottom-0 left-0 right-0 bg-yellow-500 p-4 md:hidden z-50">

//         {showSearch && (
//           <div
//             className=" p-0 shadow-lg md:hidden"

//           >
//             <div className="mb-4 relative">
//               <h1 className="font-medium text-white">Search</h1>
//               <input
//                 type="text"
//                 placeholder="Search by name"
//                 value={search}
//                 onChange={handleSearchChange}
//                 className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//               />

//             </div>
//           </div>
//         )}
//         {showFilter && (
//           <div
//             className="p-0 shadow-lg md:hidden"

//           >
//             <div className="mb-4 relative">
//               <h1 className="font-medium text-white">Filter</h1>
//               <label className="filter-label font-medium text-white mb-2">
//                 Specializations {selectedSpecializations.length > 0
//                   ? `${selectedSpecializations.length} / 3`
//                   : ""}
//               </label>
//               <Select
//                 isMulti
//                 className="mb-2"
//                 value={selectedSpecializations}
//                 onChange={handleSpecializationChange}
//                 options={specializationOptions}
//                 components={animatedComponents}
//                 styles={customSelectStyles}
//               />

//               <label className="filter-label text-white font-medium mt-4">
//                 Languages {selectedLanguages.length > 0
//                   ? `${selectedLanguages.length} / 3`
//                   : ""}
//               </label>
//               <Select
//                 isMulti
//                 value={languages}
//                 onChange={handleLanguageChange}
//                 options={languageOptions}
//                 components={animatedComponents}
//                 styles={customSelectStyles}
//               />

//             </div>
//           </div>
//         )}

//         {/* Sort section */}
//         {showSort && (
//           <div
//             className="  p-0 shadow-lg md:hidden"

//           >
//             <div className="mb-4 relative">

//               <label className="filter-label text-white font-medium">Sort by:</label>
//               <Select
//                 value={sortOptions.find((option) => option.value === `${sort}:${order}`)}
//                 onChange={handleSortChange}
//                 options={sortOptions}
//                 components={animatedComponents}
//                 styles={customSelectStyles}
//               />

//             </div>
//           </div>
//         )}



//         <div className="flex justify-between space-x-4">
//           <button className={`w-1/3 text-yellow-500  bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSearch && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//             setShowFilter(false)
//             setShowSort(false)
//             setShowSearch(!showSearch)
//           }}>
//             Search <FaSearch />
//           </button>
//           <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showFilter && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//             setShowSearch(false)
//             setShowSort(false)
//             setShowFilter(!showFilter)
//           }}>
//             Filter <FaFilter />
//           </button>

//           <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSort && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//             setShowSearch(false)
//             setShowFilter(false)
//             setShowSort(!showSort)
//           }}>
//             Sort <FaSort />
//           </button>
//         </div>
//       </div>

//       <div className="mb-8 text-center">
//         <h1 className="text-3xl font-semibold mb-2 text-gray-800">
//           Find Your Astrologer
//         </h1>
//         <p className="text-gray-600">
//           Search through our expert astrologers to find the right one for you.
//         </p>
//       </div>





//       <div className="mb-4 space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-4 md:items-center hidden md:block">

//         {/* <div className="filter-container">

//           <label className="filter-label font-medium">Search:</label>
//           <input
//             type="text"
//             placeholder="Search by name"
//             value={search}
//             onChange={handleSearchChange}
//             className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//             style={{ height: "54px" }}
//           />
//         </div> */}

//         <div className="filter-container">
//           <label className="filter-label font-medium">
//             Specializations{" "}
//             {selectedSpecializations.length > 0
//               ? `${selectedSpecializations.length} / 3`
//               : ""}
//           </label>
//           <Select
//             isMulti
//             value={selectedSpecializations}
//             onChange={handleSpecializationChange}
//             options={specializationOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             isOptionDisabled={(option) =>
//               selectedSpecializations.length >= 3 &&
//               !selectedSpecializations.includes(option)
//             }
//             styles={customSelectStyles}
//           />

//           {specializationsError && (
//             <p className="text-red-500 mt-2 text-sm">
//               Error loading specialization
//             </p>
//           )}
//         </div>

//         <div className="filter-container">

//           <label>Language{ }</label>
//           <Select
//             isMulti
//             value={languages}
//             onChange={handleLanguageChange}
//             options={languageOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             isOptionDisabled={(option) =>
//               selectedLanguages.length >= 3 &&
//               !selectedLanguages.includes(option)
//             }
//             styles={customSelectStyles}
//           />
//         </div>

//         <div className="filter-container">
//           <label className="filter-label font-medium">Sort by:</label>
//           <Select
//             value={sortOptions.find(
//               (option) => option.value === `${sort}:${order}`
//             )}
//             onChange={handleSortChange}
//             options={sortOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             styles={customSelectStyles}
//           />
//         </div>
//       </div>
//       {/* Astrologers Listing */}
//       {loading ? (
//         <div className="flex justify-center items-center h-64">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-yellow-500"></div>
//         </div>
//       ) : (
//         <>
//           {astrologers.length > 0 ? (
//             <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
//               {astrologers.map((astrologer) => (
//                 <AstrologerCard
//                   key={astrologer._id}
//                   avatar={astrologer.profileImage}
//                   name={astrologer.displayName || astrologer.name}
//                   id={astrologer._id}
//                   ratings={astrologer.ratings}
//                   experience={astrologer.experience}
//                   category={astrologer.category}
//                   languages={astrologer.languages}
//                   specializations={astrologer.specializations}
//                   minutePrice={astrologer.minutePrice}
//                   discountedPrice={astrologer.discountedPrice}
//                   minimumCallDuration={astrologer.minimumCallDuration}
//                   availability={astrologer.availability}
//                   availableForCall={astrologer.availableForCall}
//                   availableForChat={astrologer.availableForChat}
//                   inCall={astrologer.inCall}
//                   inChat={astrologer.inChat}
//                 />
//               ))}
//             </div>
//           ) : (
//             <p className="text-center text-gray-500 mt-12">
//               No astrologers found. Try adjusting your search.
//             </p>
//           )}
//         </>
//       )}


//       <div className="mt-8 flex justify-center gap-2">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}

//             onClick={() => handlePageChange(index + 1)}
//             className={`px-4 py-2 border border-gray-300 rounded-lg transition-colors duration-300 ${page === index + 1
//                 ? "bg-yellow-500 text-white"
//                 : "bg-white text-gray-600 hover:bg-yellow-100"
//               }`}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Astrologers;


// import React, { useState, useEffect, useCallback } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
// import AstrologerCard from "../components/AstrologerCard";
// import api from "../api";
// import TOP_INDIAN_LANGUAGES from "../constants/languages";
// import useSpecializations from "../hooks/useSpecializations";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import customSelectStyles from "../styles/react-select";
// import { FaSearch } from "react-icons/fa";
// import { FaSort } from "react-icons/fa";
// import { FaFilter } from "react-icons/fa";

// const animatedComponents = makeAnimated();

// const Astrologers = () => {
//   const [astrologers, setAstrologers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const [search, setSearch] = useState("");
//   const [selectedSpecializations, setSelectedSpecializations] = useState([]);
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   const [languages, setLanguages] = useState([]);
//   const [sort, setSort] = useState("");
//   const [order, setOrder] = useState("asc");

//   const [showSearch, setShowSearch] = useState(false);
//   const [showFilter, setShowFilter] = useState(false);
//   const [showSort, setShowSort] = useState(false);

//   const { specializations, error: specializationsError } = useSpecializations();

//   const fetchAstrologers = useCallback(async (resetResults = false) => {
//     const currentPage = resetResults ? 1 : page;
//     setLoading(resetResults);
//     try {
//       const response = await api.get("/astrologers", {
//         params: {
//           page: currentPage,
//           limit: 12,
//           search,
//           specializations: selectedSpecializations
//             .map((s) => s.value)
//             .join(","),
//           languages: languages.map((l) => l.value).join(","),
//           sort,
//           order,
//         },
//       });

//       if (response.data?.astrologers) {
//         const newAstrologers = resetResults 
//           ? response.data.astrologers 
//           : [...astrologers, ...response.data.astrologers];

//         setAstrologers(newAstrologers);
//         setHasMore(response.data.astrologers.length === 12);

//         if (resetResults) {
//           setPage(1);
//         } else {
//           setPage(currentPage + 1);
//         }
//       }
//       setLoading(false);
//     } catch (error) {
//       console.log("Error fetching astrologers:", error);
//       setLoading(false);
//       setHasMore(false);
//     }
//   }, [page, search, selectedSpecializations, languages, sort, order, astrologers]);

//   // Initial load and reset when filters change
//   useEffect(() => {
//     fetchAstrologers(true);
//   }, [search, selectedSpecializations, languages, sort, order]);

//   const handleSearchChange = (e) => setSearch(e.target.value);

//   const handleSpecializationChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedSpecializations(selectedOptions || []);
//     }
//   };

//   const handleLanguageChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedLanguages(selectedOptions || []);
//       setLanguages(selectedOptions || []);
//     }
//   };

//   const handleSortChange = (selectedOption) => {
//     const [sortField, sortOrder] = selectedOption.value.split(":");
//     setSort(sortField);
//     setOrder(sortOrder);
//   };

//   const specializationOptions = specializations.map((sp) => ({
//     value: sp._id,
//     label: sp.name,
//   }));

//   const languageOptions = TOP_INDIAN_LANGUAGES.map((lang) => ({
//     value: lang,
//     label: lang,
//   }));

//   const sortOptions = [
//     { value: "rating:asc", label: "Rating (Low to High)" },
//     { value: "rating:desc", label: "Rating (High to Low)" },
//     { value: "minutePrice:asc", label: "Price (Low to High)" },
//     { value: "minutePrice:desc", label: "Price (High to Low)" },
//     { value: "experience:asc", label: "Experience (Low to High)" },
//     { value: "experience:desc", label: "Experience (High to Low)" },
//     { value: "minimumCallDuration:asc", label: "Min Duration (Low to High)" },
//     { value: "minimumCallDuration:desc", label: "Min Duration (High to Low)" },
//   ];

//   return (
//     <div className="md:w-5/6 w-11/12 mx-auto py-8 md:px-2">
//       {/* Mobile Bottom Navigation - Same as previous implementation */}
//       <div className="fixed bottom-0 left-0 right-0 bg-yellow-500 p-4 md:hidden z-50">

// {showSearch && (
//   <div
//     className=" p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">
//       <h1 className="font-medium text-white">Search</h1>
//       <input
//         type="text"
//         placeholder="Search by name"
//         value={search}
//         onChange={handleSearchChange}
//         className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//       />

//     </div>
//   </div>
// )}
// {showFilter && (
//   <div
//     className="p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">
//       <h1 className="font-medium text-white">Filter</h1>
//       <label className="filter-label font-medium text-white mb-2">
//         Specializations {selectedSpecializations.length > 0
//           ? `${selectedSpecializations.length} / 3`
//           : ""}
//       </label>
//       <Select
//         isMulti
//         className="mb-2"
//         value={selectedSpecializations}
//         onChange={handleSpecializationChange}
//         options={specializationOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//       <label className="filter-label text-white font-medium mt-4">
//         Languages {selectedLanguages.length > 0
//           ? `${selectedLanguages.length} / 3`
//           : ""}
//       </label>
//       <Select
//         isMulti
//         value={languages}
//         onChange={handleLanguageChange}
//         options={languageOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//     </div>
//   </div>
// )}

// {/* Sort section */}
// {showSort && (
//   <div
//     className="  p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">

//       <label className="filter-label text-white font-medium">Sort by:</label>
//       <Select
//         value={sortOptions.find((option) => option.value === `${sort}:${order}`)}
//         onChange={handleSortChange}
//         options={sortOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//     </div>
//   </div>
// )}



// <div className="flex justify-between space-x-4">
//   <button className={`w-1/3 text-yellow-500  bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSearch && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowFilter(false)
//     setShowSort(false)
//     setShowSearch(!showSearch)
//   }}>
//     Search <FaSearch />
//   </button>
//   <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showFilter && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowSearch(false)
//     setShowSort(false)
//     setShowFilter(!showFilter)
//   }}>
//     Filter <FaFilter />
//   </button>

//   <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSort && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowSearch(false)
//     setShowFilter(false)
//     setShowSort(!showSort)
//   }}>
//     Sort <FaSort />
//   </button>
// </div>
// </div>

//       <div className="mb-8 text-center">
//         <h1 className="text-3xl font-semibold mb-2 text-gray-800">
//           Find Your Astrologer
//         </h1>
//         <p className="text-gray-600">
//           Search through our expert astrologers to find the right one for you.
//         </p>
//       </div>

//       {/* Desktop Filters - Same as previous implementation */}
//       <div className="mb-4 space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-4 md:items-center hidden md:block">

//         {/* <div className="filter-container">

//           <label className="filter-label font-medium">Search:</label>
//           <input
//             type="text"
//             placeholder="Search by name"
//             value={search}
//             onChange={handleSearchChange}
//             className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//             style={{ height: "54px" }}
//           />
//         </div> */}

//         <div className="filter-container">
//           <label className="filter-label font-medium">
//             Specializations{" "}
//             {selectedSpecializations.length > 0
//               ? `${selectedSpecializations.length} / 3`
//               : ""}
//           </label>
//           <Select
//             isMulti
//             value={selectedSpecializations}
//             onChange={handleSpecializationChange}
//             options={specializationOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             isOptionDisabled={(option) =>
//               selectedSpecializations.length >= 3 &&
//               !selectedSpecializations.includes(option)
//             }
//             styles={customSelectStyles}
//           />

//           {specializationsError && (
//             <p className="text-red-500 mt-2 text-sm">
//               Error loading specialization
//             </p>
//           )}
//         </div>

//         <div className="filter-container">

//           <label>Language{ }</label>
//           <Select
//             isMulti
//             value={languages}
//             onChange={handleLanguageChange}
//             options={languageOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             isOptionDisabled={(option) =>
//               selectedLanguages.length >= 3 &&
//               !selectedLanguages.includes(option)
//             }
//             styles={customSelectStyles}
//           />
//         </div>

//         <div className="filter-container">
//           <label className="filter-label font-medium">Sort by:</label>
//           <Select
//             value={sortOptions.find(
//               (option) => option.value === `${sort}:${order}`
//             )}
//             onChange={handleSortChange}
//             options={sortOptions}
//             components={animatedComponents}
//             className="filter-select"
//             classNamePrefix="select"
//             styles={customSelectStyles}
//           />
//         </div>
//       </div>

//       {/* Infinite Scroll Implementation */}
//       {loading && page === 1 ? (
//         <div className="flex justify-center items-center h-64">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-yellow-500"></div>
//         </div>
//       ) : (
//         <InfiniteScroll
//           dataLength={astrologers.length}
//           next={fetchAstrologers}
//           hasMore={hasMore}
//           loader={
//             <div className="flex justify-center items-center my-4">
//               <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-yellow-500"></div>
//             </div>
//           }
//           endMessage={
//             astrologers.length > 0 && (
//               <p className="text-center text-gray-500 my-4">
//                 No more astrologers to load
//               </p>
//             )
//           }
//         >
//           {astrologers.length > 0 ? (
//             <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
//               {astrologers.map((astrologer) => (
//                 <AstrologerCard
//                   key={astrologer._id}
//                   avatar={astrologer.profileImage}
//                   name={astrologer.displayName || astrologer.name}
//                   id={astrologer._id}
//                   ratings={astrologer.ratings}
//                   experience={astrologer.experience}
//                   category={astrologer.category}
//                   languages={astrologer.languages}
//                   specializations={astrologer.specializations}
//                   minutePrice={astrologer.minutePrice}
//                   discountedPrice={astrologer.discountedPrice}
//                   minimumCallDuration={astrologer.minimumCallDuration}
//                   availability={astrologer.availability}
//                   availableForCall={astrologer.availableForCall}
//                   availableForChat={astrologer.availableForChat}
//                   inCall={astrologer.inCall}
//                   inChat={astrologer.inChat}
//                 />
//               ))}
//             </div>
//           ) : (
//             <p className="text-center text-gray-500 mt-12">
//               No astrologers found. Try adjusting your search.
//             </p>
//           )}
//         </InfiniteScroll>
//       )}
//     </div>
//   );
// };

// export default Astrologers;



// import React, { useState, useEffect, useCallback } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
// import AstrologerCard from "../components/AstrologerCard";
// import api from "../api";
// import TOP_INDIAN_LANGUAGES from "../constants/languages";
// import useSpecializations from "../hooks/useSpecializations";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import customSelectStyles from "../styles/react-select";
// import { FaSearch } from "react-icons/fa";
// import { FaSort } from "react-icons/fa";
// import { FaFilter } from "react-icons/fa";

// const animatedComponents = makeAnimated();

// const Astrologers = () => {
//   const [astrologers, setAstrologers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const [search, setSearch] = useState("");
//   const [selectedSpecializations, setSelectedSpecializations] = useState([]);
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   const [languages, setLanguages] = useState([]);
//   const [sort, setSort] = useState("");
//   const [order, setOrder] = useState("asc");

//   const [showSearch, setShowSearch] = useState(false);
//   const [showFilter, setShowFilter] = useState(false);
//   const [showSort, setShowSort] = useState(false);

//   const { specializations, error: specializationsError } = useSpecializations();

//   const fetchAstrologers = useCallback(async (resetResults = false) => {
//     const currentPage = resetResults ? 1 : page;
//     setLoading(resetResults);
//     try {
//       const response = await api.get("/astrologers", {
//         params: {
//           page: currentPage,
//           limit: 12,
//           search,
//           specializations: selectedSpecializations
//             .map((s) => s.value)
//             .join(","),
//           languages: languages.map((l) => l.value).join(","),
//           sort,
//           order,
//         },
//       });

//       if (response.data?.astrologers) {
//         const newAstrologers = resetResults 
//           ? response.data.astrologers 
//           : [...astrologers, ...response.data.astrologers];

//         setAstrologers(newAstrologers);

//         // Check if we've received fewer than 12 items (indicating last page)
//         setHasMore(response.data.astrologers.length === 12);

//         if (resetResults) {
//           setPage(1);
//         } else {
//           setPage(currentPage + 1);
//         }
//       }
//       setLoading(false);
//     } catch (error) {
//       console.log("Error fetching astrologers:", error);
//       setLoading(false);
//       setHasMore(false);
//     }
//   }, [page, search, selectedSpecializations, languages, sort, order, astrologers]);

//   // Initial load and reset when filters change
//   useEffect(() => {
//     fetchAstrologers(true);
//   }, [search, selectedSpecializations, languages, sort, order]);

//   const handleSearchChange = (e) => setSearch(e.target.value);

//   const handleSpecializationChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedSpecializations(selectedOptions || []);
//     }
//   };

//   const handleLanguageChange = (selectedOptions) => {
//     if (selectedOptions.length <= 3) {
//       setSelectedLanguages(selectedOptions || []);
//       setLanguages(selectedOptions || []);
//     }
//   };

//   const handleSortChange = (selectedOption) => {
//     const [sortField, sortOrder] = selectedOption.value.split(":");
//     setSort(sortField);
//     setOrder(sortOrder);
//   };
//   // Add this section after the initial state declarations and before the fetchAstrologers function

// const specializationOptions = specializations.map((sp) => ({
//   value: sp._id,
//   label: sp.name,
// }));

// const languageOptions = TOP_INDIAN_LANGUAGES.map((lang) => ({
//   value: lang,
//   label: lang,
// }));

// const sortOptions = [
//   { value: "rating:asc", label: "Rating (Low to High)" },
//   { value: "rating:desc", label: "Rating (High to Low)" },
//   { value: "minutePrice:asc", label: "Price (Low to High)" },
//   { value: "minutePrice:desc", label: "Price (High to Low)" },
//   { value: "experience:asc", label: "Experience (Low to High)" },
//   { value: "experience:desc", label: "Experience (High to Low)" },
//   { value: "minimumCallDuration:asc", label: "Min Duration (Low to High)" },
//   { value: "minimumCallDuration:desc", label: "Min Duration (High to Low)" },
// ];

//   // ... (rest of the code remains the same as in the previous implementation)

//   return (
//     <div className="md:w-5/6 w-11/12 mx-auto py-8 md:px-2">
//       {/* Mobile Bottom Navigation - Same as previous implementation */}
//       <div className="fixed bottom-0 left-0 right-0 bg-yellow-500 p-4 md:hidden z-50">

// {showSearch && (
//   <div
//     className=" p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">
//       <h1 className="font-medium text-white">Search</h1>
//       <input
//         type="text"
//         placeholder="Search by name"
//         value={search}
//         onChange={handleSearchChange}
//         className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//       />

//     </div>
//   </div>
// )}
// {showFilter && (
//   <div
//     className="p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">
//       <h1 className="font-medium text-white">Filter</h1>
//       <label className="filter-label font-medium text-white mb-2">
//         Specializations {selectedSpecializations.length > 0
//           ? `${selectedSpecializations.length} / 3`
//           : ""}
//       </label>
//       <Select
//         isMulti
//         className="mb-2"
//         value={selectedSpecializations}
//         onChange={handleSpecializationChange}
//         options={specializationOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//       <label className="filter-label text-white font-medium mt-4">
//         Languages {selectedLanguages.length > 0
//           ? `${selectedLanguages.length} / 3`
//           : ""}
//       </label>
//       <Select
//         isMulti
//         value={languages}
//         onChange={handleLanguageChange}
//         options={languageOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//     </div>
//   </div>
// )}

// {/* Sort section */}
// {showSort && (
//   <div
//     className="  p-0 shadow-lg md:hidden"

//   >
//     <div className="mb-4 relative">

//       <label className="filter-label text-white font-medium">Sort by:</label>
//       <Select
//         value={sortOptions.find((option) => option.value === `${sort}:${order}`)}
//         onChange={handleSortChange}
//         options={sortOptions}
//         components={animatedComponents}
//         styles={customSelectStyles}
//       />

//     </div>
//   </div>
// )}



// <div className="flex justify-between space-x-4">
//   <button className={`w-1/3 text-yellow-500  bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSearch && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowFilter(false)
//     setShowSort(false)
//     setShowSearch(!showSearch)
//   }}>
//     Search <FaSearch />
//   </button>
//   <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showFilter && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowSearch(false)
//     setShowSort(false)
//     setShowFilter(!showFilter)
//   }}>
//     Filter <FaFilter />
//   </button>

//   <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSort && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
//     setShowSearch(false)
//     setShowFilter(false)
//     setShowSort(!showSort)
//   }}>
//     Sort <FaSort />
//   </button>
// </div>
// </div>

//       <div className="mb-8 text-center">
//         <h1 className="text-3xl font-semibold mb-2 text-gray-800">
//           Find Your Astrologer
//         </h1>
//         <p className="text-gray-600">
//           Search through our expert astrologers to find the right one for you.
//         </p>
//       </div>

//       {/* Desktop Filters - Same as previous implementation */}
//       <div className="mb-4 space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-4 md:items-center hidden md:block">

// {/* <div className="filter-container">

//   <label className="filter-label font-medium">Search:</label>
//   <input
//     type="text"
//     placeholder="Search by name"
//     value={search}
//     onChange={handleSearchChange}
//     className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
//     style={{ height: "54px" }}
//   />
// </div> */}

// <div className="filter-container">
//   <label className="filter-label font-medium">
//     Specializations{" "}
//     {selectedSpecializations.length > 0
//       ? `${selectedSpecializations.length} / 3`
//       : ""}
//   </label>
//   <Select
//     isMulti
//     value={selectedSpecializations}
//     onChange={handleSpecializationChange}
//     options={specializationOptions}
//     components={animatedComponents}
//     className="filter-select"
//     classNamePrefix="select"
//     isOptionDisabled={(option) =>
//       selectedSpecializations.length >= 3 &&
//       !selectedSpecializations.includes(option)
//     }
//     styles={customSelectStyles}
//   />

//   {specializationsError && (
//     <p className="text-red-500 mt-2 text-sm">
//       Error loading specialization
//     </p>
//   )}
// </div>

// <div className="filter-container">

//   <label>Language{ }</label>
//   <Select
//     isMulti
//     value={languages}
//     onChange={handleLanguageChange}
//     options={languageOptions}
//     components={animatedComponents}
//     className="filter-select"
//     classNamePrefix="select"
//     isOptionDisabled={(option) =>
//       selectedLanguages.length >= 3 &&
//       !selectedLanguages.includes(option)
//     }
//     styles={customSelectStyles}
//   />
// </div>

// <div className="filter-container">
//   <label className="filter-label font-medium">Sort by:</label>
//   <Select
//     value={sortOptions.find(
//       (option) => option.value === `${sort}:${order}`
//     )}
//     onChange={handleSortChange}
//     options={sortOptions}
//     components={animatedComponents}
//     className="filter-select"
//     classNamePrefix="select"
//     styles={customSelectStyles}
//   />
// </div>
// </div>

//       {/* Infinite Scroll Implementation */}
//       {loading && page === 1 ? (
//         <div className="flex justify-center items-center h-64">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-yellow-500"></div>
//         </div>
//       ) : (
//         <InfiniteScroll
//           dataLength={astrologers.length}
//           next={fetchAstrologers}
//           hasMore={hasMore}
//           loader={
//             <div className="flex justify-center items-center my-4">
//               <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-yellow-500"></div>
//             </div>
//           }
//         >
//           {astrologers.length > 0 ? (
//             <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
//               {astrologers.map((astrologer) => (
//                 <AstrologerCard
//                   key={astrologer._id}
//                   avatar={astrologer.profileImage}
//                   name={astrologer.displayName || astrologer.name}
//                   id={astrologer._id}
//                   ratings={astrologer.ratings}
//                   experience={astrologer.experience}
//                   category={astrologer.category}
//                   languages={astrologer.languages}
//                   specializations={astrologer.specializations}
//                   minutePrice={astrologer.minutePrice}
//                   discountedPrice={astrologer.discountedPrice}
//                   minimumCallDuration={astrologer.minimumCallDuration}
//                   availability={astrologer.availability}
//                   availableForCall={astrologer.availableForCall}
//                   availableForChat={astrologer.availableForChat}
//                   inCall={astrologer.inCall}
//                   inChat={astrologer.inChat}
//                 />
//               ))}
//             </div>
//           ) : (
//             <p className="text-center text-gray-500 mt-12">
//               No astrologers found. Try adjusting your search.
//             </p>
//           )}
//         </InfiniteScroll>
//       )}
//     </div>
//   );
// };

// export default Astrologers;



import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import AstrologerCard from "../components/AstrologerCard";
import api from "../api";
import TOP_INDIAN_LANGUAGES from "../constants/languages";
import useSpecializations from "../hooks/useSpecializations";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import customSelectStyles from "../styles/react-select";
import { FaSearch } from "react-icons/fa";
import { FaSort } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";

const animatedComponents = makeAnimated();

const Astrologers = () => {
  const [astrologers, setAstrologers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("asc");

  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const { specializations, error: specializationsError } = useSpecializations();

  const fetchAstrologers = useCallback(async (resetResults = false) => {
    // If resetResults is true, we want page 1, otherwise use the current page
    const currentPage = resetResults ? 1 : page;
    setLoading(resetResults);

    try {
      const response = await api.get("/astrologers", {
        params: {
          page: currentPage,
          limit: 12,
          search,
          specializations: selectedSpecializations
            .map((s) => s.value)
            .join(","),
          languages: languages.map((l) => l.value).join(","),
          sort,
          order,
        },
      });

      if (response.data?.astrologers) {
        // If resetting results, just set the new data
        // If not resetting, append the new data to existing data
        const newAstrologers = resetResults
          ? response.data.astrologers
          : [...astrologers, ...response.data.astrologers];

        setAstrologers(newAstrologers);
        setHasMore(response.data.astrologers.length === 12);

        // Only update the page if we're not resetting
        if (!resetResults) {
          setPage(prev => prev + 1);
        } else {
          setPage(2); // Set to 2 since next fetch should get page 2
        }
      } else {
        setHasMore(false);
      }

      setLoading(false);
    } catch (error) {
      console.log("Error fetching astrologers:", error);
      setLoading(false);
      setHasMore(false);
    }
  }, [page, search, selectedSpecializations, languages, sort, order, astrologers]);

  // Modify the useEffect to handle filter changes
  useEffect(() => {
    // Reset everything when filters change
    setPage(1);
    setAstrologers([]);
    setHasMore(true);
    fetchAstrologers(true);
  }, [search, selectedSpecializations, languages, sort, order]);

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSpecializationChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setSelectedSpecializations(selectedOptions || []);
    }
  };

  const handleLanguageChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setSelectedLanguages(selectedOptions || []);
      setLanguages(selectedOptions || []);
    }
  };

  const handleSortChange = (selectedOption) => {
    const [sortField, sortOrder] = selectedOption.value.split(":");
    setSort(sortField);
    setOrder(sortOrder);
  };

  const specializationOptions = specializations.map((sp) => ({
    value: sp._id,
    label: sp.name,
  }));

  const languageOptions = TOP_INDIAN_LANGUAGES.map((lang) => ({
    value: lang,
    label: lang,
  }));

  const sortOptions = [
    { value: "rating:asc", label: "Rating (Low to High)" },
    { value: "rating:desc", label: "Rating (High to Low)" },
    { value: "minutePrice:asc", label: "Price (Low to High)" },
    { value: "minutePrice:desc", label: "Price (High to Low)" },
    { value: "experience:asc", label: "Experience (Low to High)" },
    { value: "experience:desc", label: "Experience (High to Low)" },
    { value: "minimumCallDuration:asc", label: "Min Duration (Low to High)" },
    { value: "minimumCallDuration:desc", label: "Min Duration (High to Low)" },
  ];

  

  return (
    <div className="md:w-5/6 w-11/12 mx-auto py-8 md:px-2">
      {/* Mobile Bottom Navigation - Same as previous implementation */}
      <div className="fixed bottom-0 left-0 right-0 bg-yellow-500 p-4 md:hidden z-50">

        {showSearch && (
          <div
            className=" p-0 shadow-lg md:hidden"

          >
            <div className="mb-4 relative">
              <h1 className="font-medium text-white">Search</h1>
              <input
                type="text"
                placeholder="Search by name"
                value={search}
                onChange={handleSearchChange}
                className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
              />

            </div>
          </div>
        )}
        {showFilter && (
          <div
            className="p-0 shadow-lg md:hidden"

          >
            <div className="mb-4 relative">
              <h1 className="font-medium text-white">Filter</h1>
              <label className="filter-label font-medium text-white mb-2">
                Specializations {selectedSpecializations.length > 0
                  ? `${selectedSpecializations.length} / 3`
                  : ""}
              </label>
              <Select
                isMulti
                className="mb-2"
                value={selectedSpecializations}
                onChange={handleSpecializationChange}
                options={specializationOptions}
                components={animatedComponents}
                styles={customSelectStyles}
              />

              <label className="filter-label text-white font-medium mt-4">
                Languages {selectedLanguages.length > 0
                  ? `${selectedLanguages.length} / 3`
                  : ""}
              </label>
              <Select
                isMulti
                value={languages}
                onChange={handleLanguageChange}
                options={languageOptions}
                components={animatedComponents}
                styles={customSelectStyles}
              />

            </div>
          </div>
        )}

        {/* Sort section */}
        {showSort && (
          <div
            className="  p-0 shadow-lg md:hidden"

          >
            <div className="mb-4 relative">

              <label className="filter-label text-white font-medium">Sort by:</label>
              <Select
                value={sortOptions.find((option) => option.value === `${sort}:${order}`)}
                onChange={handleSortChange}
                options={sortOptions}
                components={animatedComponents}
                styles={customSelectStyles}
              />

            </div>
          </div>
        )}



        <div className="flex justify-between space-x-4">
          <button className={`w-1/3 text-yellow-500  bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSearch && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
            setShowFilter(false)
            setShowSort(false)
            setShowSearch(!showSearch)
          }}>
            Search <FaSearch />
          </button>
          <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showFilter && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
            setShowSearch(false)
            setShowSort(false)
            setShowFilter(!showFilter)
          }}>
            Filter <FaFilter />
          </button>

          <button className={`w-1/3 text-yellow-500 bg-gray-50 py-2 text-sm font-[14px] rounded-lg gap-2 flex items-center justify-center hover:bg-yellow-600 ${showSort && "bg-yellow-600 text-white"} hover:text-white transition duration-300`} onClick={() => {
            setShowSearch(false)
            setShowFilter(false)
            setShowSort(!showSort)
          }}>
            Sort <FaSort />
          </button>
        </div>
      </div>


      <div className="mb-8 text-center">
        <h1 className="text-3xl font-semibold mb-2 text-gray-800">
          Find Your Astrologer
        </h1>
        <p className="text-gray-600">
          Search through our expert astrologers to find the right one for you.
        </p>
      </div>

      {/* Desktop Filters - Same as previous implementation */}
      <div className="mb-4 space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-4 md:items-center hidden md:block">

        {/* <div className="filter-container">

  <label className="filter-label font-medium">Search:</label>
  <input
    type="text"
    placeholder="Search by name"
    value={search}
    onChange={handleSearchChange}
    className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
    style={{ height: "54px" }}
  />
</div> */}

        <div className="filter-container">
          <label className="filter-label font-medium">
            Specializations{" "}
            {selectedSpecializations.length > 0
              ? `${selectedSpecializations.length} / 3`
              : ""}
          </label>
          <Select
            isMulti
            value={selectedSpecializations}
            onChange={handleSpecializationChange}
            options={specializationOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            isOptionDisabled={(option) =>
              selectedSpecializations.length >= 3 &&
              !selectedSpecializations.includes(option)
            }
            styles={customSelectStyles}
          />

          {specializationsError && (
            <p className="text-red-500 mt-2 text-sm">
              Error loading specialization
            </p>
          )}
        </div>

        <div className="filter-container">

          <label>Language{ }</label>
          <Select
            isMulti
            value={languages}
            onChange={handleLanguageChange}
            options={languageOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            isOptionDisabled={(option) =>
              selectedLanguages.length >= 3 &&
              !selectedLanguages.includes(option)
            }
            styles={customSelectStyles}
          />
        </div>

        <div className="filter-container">
          <label className="filter-label font-medium">Sort by:</label>
          <Select
            value={sortOptions.find(
              (option) => option.value === `${sort}:${order}`
            )}
            onChange={handleSortChange}
            options={sortOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            styles={customSelectStyles}
          />
        </div>
      </div>

      {/* Infinite Scroll Implementation */}
      {loading && page === 1 ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-yellow-500"></div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={astrologers.length}
          next={fetchAstrologers}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center items-center my-4">
              <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-yellow-500"></div>
            </div>
          }
          scrollableTarget="scrollableDiv" // Add this if you have a specific scrollable container
          endMessage={
            <p className="text-center text-gray-500 my-4">
              {astrologers.length > 0 ? "No more astrologers to load" : ""}
            </p>
          }
        >
          {astrologers.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {astrologers.map((astrologer) => (
                <AstrologerCard
                  key={astrologer._id}
                  avatar={astrologer.profileImage}
                  name={astrologer.displayName || astrologer.name}
                  id={astrologer._id}
                  ratings={astrologer.ratings}
                  experience={astrologer.experience}
                  category={astrologer.category}
                  languages={astrologer.languages}
                  specializations={astrologer.specializations}
                  minutePrice={astrologer.minutePrice}
                  discountedPrice={astrologer.discountedPrice}
                  minimumCallDuration={astrologer.minimumCallDuration}
                  availability={astrologer.availability}
                  availableForCall={astrologer.availableForCall}
                  availableForChat={astrologer.availableForChat}
                  inCall={astrologer.inCall}
                  inChat={astrologer.inChat}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500 mt-12">
              No astrologers found. Try adjusting your search.
            </p>
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Astrologers;