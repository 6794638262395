import React, { useState, useEffect } from "react";
import { FaMars, FaVenus } from 'react-icons/fa';
import { useLocation } from "react-router-dom";
import Dashakoot from "../components/KundliMatchingDetails/Dashakoot";
import Ashtakoot from "../components/KundliMatchingDetails/Ashtakoot";
import AggregateMatch from "../components/KundliMatchingDetails/AggregateMatch";
import RajjuVedha from "../components/KundliMatchingDetails/RajjuVedha";
import PapasamayaMatch from "../components/KundliMatchingDetails/PapasamayaMatch";
import { FaFilePdf } from 'react-icons/fa';
import Slider from 'react-slick';
export const NavigationButton = ({ active, onClick, children }) => (
  <button
    className={`px-4 py-2 mx-2 text-nowrap font-semibold rounded-md focus:outline-none ${active ? "bg-yellow-500 text-white" : "bg-gray-200 hover:bg-yellow-100"
      }`}
    onClick={onClick}
  >
    {children}
  </button>
);

const ProfileCard = ({ name, city, gender, dob, tob, tz, lat, lon }) => (
  <div className="bg-gray-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className={`w-12 h-12 rounded-full flex items-center justify-center 
        ${gender === "Boy"
          ? "bg-blue-100 text-blue-600"
          : "bg-pink-100 text-pink-600"}`}
      >

        {gender === "Boy"
          ? <FaMars className="text-2xl" />
          : <FaVenus className="text-2xl" />
        }


      </div>
      <h2 className={`text-2xl font-bold ml-3 
        ${gender === "Boy" ? "text-blue-600" : "text-pink-600"}`}
      >
        {gender} Details
      </h2>
    </div>

    <div className="space-y-3">
      <div className="bg-white p-3 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300">
        <p className="text-gray-600 text-sm">Name</p>
        <p className="font-semibold">{name}</p>
      </div>

      <div className="bg-white p-3 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300">
        <p className="text-gray-600 text-sm">Birth Place</p>
        <p className="font-semibold">{city}</p>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div className="bg-white p-3 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300">
          <p className="text-gray-600 text-sm">Date of Birth</p>
          <p className="font-semibold">{dob}</p>
        </div>
        <div className="bg-white p-3 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300">
          <p className="text-gray-600 text-sm">Time of Birth</p>
          <p className="font-semibold">{tob}</p>
        </div>
      </div>

    </div>
  </div>
);

const KundliMatchingDetails = () => {
  const location = useLocation();
  const { apiParams } = location.state;

  const { boy_name, girl_name, boy_city, girl_city } = apiParams;

  const [kundliData, setKundliData] = useState({});
  const [activeSection, setActiveSection] = useState("aggregateMatch");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");


  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang); // Store in localStorage

    // Trigger API call when language changes
    callVedicAPI(selectedLang);
  };

  const callVedicAPI = (lang) => {

    const url = `https://astrologerdaddy.com/api/astrology-services/dosha/kaalsarp-dosh?dob=23/02/1985&tob=05:40&lat=11&lon=77&tz=5.5&&lang=${lang}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };


  const BASE_URL = 'https://astrologerdaddy.com/api/astrology-services/matching';

  const fetchKundliData = async (apiParams) => {
    const endpoints = [
      { key: 'aggregateMatch', url: 'aggregate-match' },
      { key: 'rajjuVedha', url: 'rajju-vedha-details' },
      { key: 'papasamaya', url: 'papasamaya-match' },
      { key: 'ashtakoot', url: 'ashtakoot-with-astro-details' },
      { key: 'dashakoot', url: 'dashakoot-with-astro-details' },
    ];

    const queryString = new URLSearchParams({
      boy_dob: apiParams.boy_dob,
      boy_tob: apiParams.boy_tob,
      boy_tz: apiParams.boy_tz,
      boy_lat: apiParams.boy_lat,
      boy_lon: apiParams.boy_lon,
      girl_dob: apiParams.girl_dob,
      girl_tob: apiParams.girl_tob,
      girl_tz: apiParams.girl_tz,
      girl_lat: apiParams.girl_lat,
      girl_lon: apiParams.girl_lon,
      lang: language,
    }).toString();

    try {
      const responses = await Promise.all(
        endpoints.map(({ url }) =>
          fetch(`${BASE_URL}/${url}?${queryString}`)
        )
      );

      const results = await Promise.all(
        responses.map(response => response.json())
      );

      return results.reduce((acc, result, index) => {
        if (result.status === 200) {
          acc[endpoints[index].key] = result.response;
        }
        return acc;
      }, {});
    } catch (error) {
      throw new Error('Failed to fetch Kundli data');
    }
  };

  const settings = {
    dots: false,          // Show navigation dots
    infinite: true,      // Loop through images
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Enable auto-play
    autoplaySpeed: 3000,
    // Delay between transitions
  };

  // Array of images to display in the slider
  const images = [
    'https://via.placeholder.com/600x300', // Replace with your image URLs
    'https://via.placeholder.com/600x300',
    'https://via.placeholder.com/600x300',
  ];


  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const data = await fetchKundliData(apiParams);
        setKundliData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [apiParams, language]);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div className="min-w-7xl mx-auto p-4">
      {/* <h1 className="text-3xl font-bold text-center my-6">
        Kundli Matching Details
      </h1> */}
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>

      <h1 className="text-3xl font-bold text-center text-red-700 my-6">
        Kundli Matching Details
      </h1>
      <button
        className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 text-white mb-5 font-semibold py-2 px-2 sm:py-3 sm:px-6 rounded-full shadow-lg mt-4 mx-auto block transform transition duration-300 hover:scale-105 hover:shadow-2xl animate-bounce text-sm sm:text-base flex items-center justify-center"
      >
        Get Your Complete Matching in PDF Just 499/-
        <FaFilePdf className="ml-2 text-lg sm:text-xl" />
      </button>

      <div className="max-w-7xl mx-auto p-4">
        {/* Profiles Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <ProfileCard
            name={boy_name}
            city={boy_city}
            gender="Boy"
            dob={apiParams.boy_dob}
            tob={apiParams.boy_tob}
            tz={apiParams.boy_tz}
            lat={apiParams.boy_lat}
            lon={apiParams.boy_lon}
          />
          <ProfileCard
            name={girl_name}
            city={girl_city}
            gender="Girl"
            dob={apiParams.girl_dob}
            tob={apiParams.girl_tob}
            tz={apiParams.girl_tz}
            lat={apiParams.girl_lat}
            lon={apiParams.girl_lon}
          />
        </div>

        {/* Navigation Tabs */}
        {/* <div className="flex items-center justify-between">
        <div className="bg-white rounded-lg shadow-md mb-6 ">
          <div className="flex overflow-x-auto space-x-2 p-2">
            {['aggregateMatch', 'rajjuVedha', 'papasamaya', 'ashtakoot', 'dashakoot'].map((section) => (
              <button
                key={section}
                onClick={() => setActiveSection(section)}
                className={`px-4 py-2 rounded-md whitespace-nowrap transition-colors duration-200
              ${activeSection === section
                    ? 'bg-orange-600 text-white'
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-2">
    <label
      htmlFor="language-select"
      className="text-sm font-medium whitespace-nowrap"
    >
      Select Language:
    </label>
    <select
      id="language-select"
      className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
      value={language}
      onChange={handleLanguageChange}
    >
      <option value="en">English</option>
      <option value="ta">Tamil</option>
      <option value="ka">Kannada</option>
      <option value="te">Telugu</option>
      <option value="hi">Hindi</option>
      <option value="ml">Malayalam</option>
      <option value="mr">Marathi</option>
      
      <option value="sp">Spanish</option>
      <option value="fr">French</option>
    </select>
  </div>
        </div> */}
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-4 py-4">
          {/* Tab Buttons */}
          <div className="bg-white rounded-lg shadow-md w-full sm:w-auto overflow-hidden">
            <div className="flex flex-wrap sm:flex-nowrap overflow-x-auto space-x-2 p-2">
              {['aggregateMatch', 'rajjuVedha', 'papasamaya', 'ashtakoot', 'dashakoot'].map((section) => (
                <button
                  key={section}
                  onClick={() => setActiveSection(section)}
                  className={`px-4 py-2 rounded-md whitespace-nowrap transition-colors duration-200
          ${activeSection === section
                      ? 'bg-orange-600 text-white'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                    }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* Language Selector */}
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
            <label htmlFor="language-select" className="text-sm font-medium whitespace-nowrap">
              Select Language:
            </label>
            <select
              id="language-select"
              className="border border-gray-300 rounded-md p-2 text-sm w-full sm:w-auto"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="ta">Tamil</option>
              <option value="ka">Kannada</option>
              <option value="te">Telugu</option>
              <option value="hi">Hindi</option>
              <option value="ml">Malayalam</option>
              <option value="mr">Marathi</option>
              
              <option value="sp">Spanish</option>
              <option value="fr">French</option>
            </select>
          </div>
        </div>

        <div>

        </div>

        <div className="lg:flex md:flex items-stretch justify-between w-full">

          <div className="lg:w-[65%] md:[60%] w-full px-4 ">
            {activeSection === "aggregateMatch" && (
              <AggregateMatch data={kundliData.aggregateMatch} />
            )}
            {activeSection === "rajjuVedha" && (
              <RajjuVedha data={kundliData.rajjuVedha} />
            )}
            {activeSection === "papasamaya" && (
              <PapasamayaMatch data={kundliData.papasamaya} />
            )}
            {activeSection === "ashtakoot" && (
              <Ashtakoot data={kundliData.ashtakoot} title="AshtaKoot Details" />
            )}
            {activeSection === "dashakoot" && (
              <Dashakoot data={kundliData.dashakoot} title="DashKoot Details" />
            )}
          </div>
          <div className="lg:w-[30%] md:[25%] justify-between h-full w-full  px-4 grow flex flex-col gap-2 ">
            <div className="max-w-full">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="px-2">
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="max-w-full">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="px-2">
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="max-w-full">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="px-2">
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="grow ">
              <img src='https://via.placeholder.com/600x300' className="h-full" alt="" srcset="" />
            </div>
          </div>
        </div >
        {/* <div className="flex flex-col sm:flex-row flex-wrap gap-2 px-2 mt-2 items-center w-full sm:w-auto">
    <label
      htmlFor="language-select"
      className="text-sm font-medium sm:mr-2 whitespace-nowrap"
    >
      Select Language:
    </label>
    <select
      id="language-select"
      className="border border-gray-300 rounded-md p-2 w-full sm:w-auto text-sm"
      // value={language}
      // onChange={handleLanguageChange}
    >
      <option value="en">English</option>
      <option value="ta">Tamil</option>
      <option value="ka">Kannada</option>
      <option value="te">Telugu</option>
      <option value="hi">Hindi</option>
      <option value="ml">Malayalam</option>
      <option value="mr">Marathi</option>
      
      <option value="sp">Spanish</option>
      <option value="fr">French</option>
    </select>
  </div> */}
      </div>
      {/* Content Section */}



      <button
        className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 text-white  mb-5 font-semibold py-2 px-2 sm:py-3 sm:px-6 rounded-full shadow-lg mt-4 mx-auto block transform transition duration-300 hover:scale-105 hover:shadow-2xl animate-bounce text-sm sm:text-base flex items-center justify-center"
      >
        Get Your Complete Matching in PDF Just 499/-
        <FaFilePdf className="ml-2 text-lg sm:text-xl" />
      </button>
      <div className="max-w-7xl mx-auto px-4 my-6" >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>


  );
};

export default KundliMatchingDetails;