import React, { useState } from 'react';
import { FaSearch, FaFilter } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

function Shopdetails() {
    const [sortBy, setSortBy] = useState('default');
    const [priceRange, setPriceRange] = useState('all');
    const [showFilters, setShowFilters] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    // Sample data - in real app, this would be more extensive
    const initialCardData = Array.from({ length: 20 }).map((_, index) => ({
        id: index,
        url: "https://d1gcna0o0ldu5v.cloudfront.net/images/03f91606-6de8-4f8b-b68c-19987b12822e.jpg",
        name: "Hanuman Chalisa and Aarti",
        description: "Rose Quartz Bracelet with Buddha",
        date: { day: "12", month: "Nov" },
        day: "Wednesday",
        shop: "shop",
        price: 600 - (index % 3) * 100, // Varying prices for demonstration
    }));

    const [products, setProducts] = useState(initialCardData);

    const handleClick = () => {
        navigate("/shop-description");
    };

    const handleSort = (value) => {
        setSortBy(value);
        let sortedProducts = [...products];
        
        switch (value) {
            case 'price-low-high':
                sortedProducts.sort((a, b) => a.price - b.price);
                break;
            case 'price-high-low':
                sortedProducts.sort((a, b) => b.price - a.price);
                break;
            case 'name-a-z':
                sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
                break;
            default:
                sortedProducts = [...initialCardData];
        }
        
        setProducts(sortedProducts);
    };

    const handlePriceFilter = (value) => {
        setPriceRange(value);
        let filteredProducts = [...initialCardData];
        
        switch (value) {
            case 'under-300':
                filteredProducts = filteredProducts.filter(product => product.price < 300);
                break;
            case '300-500':
                filteredProducts = filteredProducts.filter(product => product.price >= 300 && product.price <= 500);
                break;
            case 'above-500':
                filteredProducts = filteredProducts.filter(product => product.price > 500);
                break;
            default:
                filteredProducts = [...initialCardData];
        }
        
        setProducts(filteredProducts);
    };

    return (
        <div className="py-8 w-4/5 mx-auto">
            <div className="text-center py-6 bg-gradient-to-r from-red-400 via-yellow-400 to-orange-400 text-white">
                <h2 className="text-2xl font-bold tracking-wide">
                    Malas, Japmala, and Beads Products
                </h2>
                <p className="text-lg font-medium mt-2">
                    Shop the Best Online Astrology Products and Services
                </p>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between gap-4 p-6 bg-white rounded-lg shadow-lg">
                <div className="flex items-center w-full md:w-2/3 bg-white border border-yellow-500 rounded-full overflow-hidden shadow-md">
                    <span className="bg-yellow-500 p-4 flex items-center justify-center">
                        <FaSearch className="text-white text-lg" />
                    </span>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for anything..."
                        className="p-3 w-full focus:outline-none text-gray-700 text-sm"
                    />
                </div>

                <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="flex items-center gap-2 px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition-colors"
                >
                    <FaFilter />
                    Filters
                </button>
            </div>

            {showFilters && (
                <div className="mt-4 p-6 bg-white rounded-lg shadow-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Sort By</label>
                            <select
                                value={sortBy}
                                onChange={(e) => handleSort(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-yellow-500"
                            >
                                <option value="default">Default</option>
                                <option value="price-low-high">Price: Low to High</option>
                                <option value="price-high-low">Price: High to Low</option>
                                <option value="name-a-z">Name: A to Z</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Price Range</label>
                            <select
                                value={priceRange}
                                onChange={(e) => handlePriceFilter(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-yellow-500"
                            >
                                <option value="all">All Prices</option>
                                <option value="under-300">Under ₹300</option>
                                <option value="300-500">₹300 - ₹500</option>
                                <option value="above-500">Above ₹500</option>
                            </select>
                        </div>
                    </div>
                </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
                {products.map((card, index) => (
                    <div key={index} className="relative bg-white shadow-md hover:shadow-xl cursor-pointer rounded-lg" onClick={handleClick}>
                        <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-lg">
                            {card.shop}
                        </div>

                        <img src={card.url} alt={card.name} className="w-full h-48 object-cover rounded-t-lg" />
                        <div className="p-4">
                            <p className="text-gray-600">{card.description}</p>
                            <div className="flex items-center justify-start mt-2 gap-2">
                                <p className="text-gray-500">₹{card.price}</p>
                                <p className="text-red-500 hover:text-yellow-700 font-semibold line-through">
                                    ₹{card.price + 50}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Shopdetails;